import React, { memo } from 'react';
import PropTypes from 'prop-types';

function DropdownButtonItem({ href, icon, imageName, text, clickHandler, className }) {
  return (
    <a
      className={`dropdown-item pr-2 ${className}`}
      href={href}
      onClick={(e) => {
        if (clickHandler != null) {
          e.preventDefault();
          clickHandler();
        }
      }}>
      {icon && <i className={icon} />}
      {imageName && <img src={require(`../../assets/images/${imageName}`)} />}
      {` ${text}`}
    </a>
  );
}

DropdownButtonItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  imageName: PropTypes.string,
  text: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  className: PropTypes.string,
};

export default memo(DropdownButtonItem);
