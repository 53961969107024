import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../common/Card';
import { useHistory } from 'react-router-dom';
import Loading from '../../common/Loading';
import { stopLoadingSelector } from '../../../redux/helperSelectors';

function CurrentCreditsCard() {
  const history = useHistory();
  const { isFetchingCredits, triedFetchingCredits } = useSelector((state) => state.system);
  const { credits } = useSelector((state) => state.transactions);
  const stopLoading = useSelector((state) => stopLoadingSelector(state));

  const transactionsAvailable = useMemo(() => {
    if (credits && (credits.awardedCredits || credits.spentCredits)) {
      return credits.awardedCredits > 0 || credits.spentCredits > 0;
    }
  }, [credits]);

  const handleCreditsClick = useCallback(() => {
    transactionsAvailable && history.push('/credits');
  }, [history, transactionsAvailable]);

  const maxCreditsReached = credits && credits.remainingCredits >= credits.maxCredits;

  return (
    <Card
      title="Credits"
      contentClass={transactionsAvailable ? 'credit-balance-container d-flex' : 'no-current-card'}
      headerClass={transactionsAvailable && 'z-index-2'}
      onClick={handleCreditsClick}
      icon="pe-7s-credit"
      iconGradient="bg-green-blue-top"
      tooltip={maxCreditsReached ? 'Maximum credits reached' : ''}>
      {(isFetchingCredits || !triedFetchingCredits) && !stopLoading ? (
        <Loading message="Hang on, getting credits.." />
      ) : transactionsAvailable ? (
        <>
          <p className={maxCreditsReached ? 'max-credits-home' : 'text'}>
            {credits.remainingCredits}
            {maxCreditsReached && <i className="pe-7s-attention pl-2" />}
          </p>
          <span>Details →</span>
        </>
      ) : (
        <div>No transactions found.</div>
      )}
    </Card>
  );
}

export default CurrentCreditsCard;
