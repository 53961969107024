import TimesheetState from '../enums/timesheetState';

class TimesheetStateBadgeService {
  getStateBadge(timesheet) {
    let stateBadgeBg = '';
    let stateBadgeText = '';

    switch (timesheet.state) {
      case TimesheetState.DRAFT:
        stateBadgeBg = 'bg-yellow-fade';
        stateBadgeText = 'draft';
        break;
      case TimesheetState.PENDINGAPPROVAL:
        stateBadgeBg = 'bg-blue-purple';
        stateBadgeText = 'pending';
        break;
      case TimesheetState.APPROVED:
      case TimesheetState.EXPORTED:
        stateBadgeBg = 'bg-green-blue';
        stateBadgeText = 'approved';
        break;
    }

    return { stateBadgeBg, stateBadgeText };
  }
}

export default new TimesheetStateBadgeService();
