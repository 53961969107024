import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TablePagination as MuiTablePagination } from '@mui/material';

const font = 'Campton-Book, Helvetica Neue, Helvetica, Arial, sans-serif !important';

const CustomTablePagination = styled(MuiTablePagination)(() => ({
  '.MuiTablePagination-displayedRows': {
    fontFamily: font,
    marginBottom: '0 !important',
    color: 'var(--text-color)',
    background: 'var(--background-color-shade)',
  },
  '.MuiTablePagination-selectLabel': {
    fontFamily: font,
    marginBottom: '0 !important',
    color: 'var(--text-color)',
    background: 'var(--background-color-shade)',
  },
  '.MuiSelect-select': {
    fontFamily: font,
    color: 'var(--text-color)',
    background: 'var(--background-color-shade)',
  },
}));

export default function TablePagination(props) {
  return <CustomTablePagination {...props} />;
}
