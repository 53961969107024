import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Card({
  children,
  title,
  titleExtension,
  titleExtensionClass,
  titleExtensionTooltip,
  icon,
  iconGradient,
  contentClass,
  headerClass,
  cardClass,
  button,
  onMouseEnter,
  onMouseLeave,
  onClick,
  tooltip
}) {
  return (
    <div className={`t4t-card ${cardClass || ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} title={tooltip || ''}>
      {(title || icon) && (
        <div className={`t4t-card-header ${headerClass || ''}`}>
          {button && <div className="t4t-card-header--buttons d-flex">{button}</div>}
          <div className="t4t-card-header--title d-flex">
          <div className="d-flex align-self-baseline">
            {icon && <i className={`${icon} ${iconGradient ? 'icon-gradient ' + iconGradient : ''}`} />}
            <span>{title}</span> 
            </div>
            { titleExtension && <span className={`btn-sm credits ${titleExtensionClass && titleExtensionClass}`} title={titleExtensionTooltip}>{titleExtension}</span>} 
          </div>
        </div>
      )}
      <div className={`t4t-card-content ${contentClass || ''}`} onClick={onClick}>
        {children}
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  titleExtension: PropTypes.string,
  titleExtensionClass: PropTypes.string,
  titleExtensionTooltip: PropTypes.string,
  icon: PropTypes.string,
  iconGradient: PropTypes.string,
  contentClass: PropTypes.string,
  headerClass: PropTypes.string,
  cardClass: PropTypes.string,
  button: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};

export default memo(Card);
