import { getContractsByUserId } from '../../api/contractsApi';
import { showRequestFailedToast } from '../../service/toastService';

export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILED = 'FETCH_CONTRACTS_FAILED';

export function fetchContractsByUserId(myportalUserId, managingOtherUser, skip, take) {
  return async function (dispatch) {
    dispatch({ type: FETCH_CONTRACTS });
    try {
      const response = await getContractsByUserId(myportalUserId, { managingOtherUser, skip, take });
      return dispatch({
        type: FETCH_CONTRACTS_SUCCESS,
        contracts: response.data || [],
        totalCount: response.data.totalCount || 0,
        hasActiveContracts: response.data.HasActiveContracts || false,
        skip,
        take,
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_CONTRACTS_FAILED, error });
    }
  };
}
