// SkeletonLoading.jsx
import React from 'react';

const SkeletonLoading = () => {
  return (
    <div className="loading-container">
      <div className="loading-border">
        <div className="loading-content">
          <div className="loading-row">
            <div className="loading-item loading-item--wide loading-item--pulse"></div>
            <div className="loading-item loading-item--pulse"></div>
          </div>
          <div className="loading-item loading-item--tall loading-item--pulse"></div>
          <div className="loading-row loading-row--end">
            <div className="loading-item loading-item--end loading-item--pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoading;
