import { useDispatch, useSelector } from 'react-redux';
import { resetTimesheetProps } from '../redux/system/actions';
import moment from 'moment';
import { fetchTimesheetByDate } from '../redux/timesheets/actions';
import { useHistory } from 'react-router-dom';

const TimesheetService = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timesheets = useSelector((state) => state.timesheets.timesheetList);

  const handleCreateTimesheet = (contract, selectedDate) => {
    const month = selectedDate ? moment(selectedDate).month() : moment().month();
    const year = selectedDate ? moment(selectedDate).year() : moment().year();
    const timesheet = timesheets.find(
      (ts) =>
        moment(ts.start).month() == month &&
        moment(ts.start).year() == year &&
        ts.contractId == contract.contractId &&
        ts.company == contract.company
    );

    // If timesheet already exists, go immediately to timesheet detail page
    if (timesheet) {
      history.push(`/timesheets/edit/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}`);
    }
    // If timesheet does not exist yet in state, create new timesheet in backend and then go to timesheet detail page
    else {
      dispatch(resetTimesheetProps());
      dispatch(fetchTimesheetByDate(year, month + 1, contract.contractId, contract.company));
      history.push(`/timesheets/new/${contract.contractId}/${contract.company}/${year}/${month + 1}`);
    }
  };

  return { handleCreateTimesheet };
};

export default TimesheetService;
