import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

function ContractListRow({ i, contract }) {
  return (
    <tr className="clickable" data-toggle="collapse" data-target={`#collapse-${i}`}>
      <td>{i + 1}</td>
      {/* <td>{contract.contractId}</td> */}
      <td>{contract.company}</td>
      <td>{contract.client.name}</td>
      <td>{contract.client.address.city}</td>
      <td>
        <span className={`badge badge-pill t4t-badge ${contract.isActive ? 'bg-green-blue' : 'bg-purple-red'}`}>
          {contract.isActive ? 'active' : 'finished'}
        </span>
      </td>
      <td>{moment(contract.startDate).format('D MMMM YYYY')}</td>
      <td>{contract.endDate && moment(contract.endDate).format('D MMMM YYYY')}</td>
    </tr>
  );
}

ContractListRow.propTypes = {
  contract: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
};

export default memo(ContractListRow);
