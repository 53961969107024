import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UsersListCard from '../components/users/UsersListCard';
import { fetchUsers } from '../redux/users/actions';

function Users() {
  const dispatch = useDispatch();
  const { isFetchingUsers, triedFetchingUsers } = useSelector((state) => state.system);
  const { filterValue } = useSelector((state) => state.users);

  useEffect(() => {
    if (!isFetchingUsers && !triedFetchingUsers) {
      dispatch(fetchUsers(0, 20, filterValue));
    }
  }, [dispatch, isFetchingUsers, triedFetchingUsers, filterValue]);

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <UsersListCard />
        </div>
      </div>
    </div>
  );
}

export default Users;
