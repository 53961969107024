import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

function AvatarWithBranding({ name, size }) {

  const styles = useMemo(
    () => ({
      width: size,
      height: size,
    }),
    [size]
  );

  const initials = useMemo(
    () =>
      name
        .split(' ')
        .map((str) => str[0])
        .slice(0, 3) // Only take up to 3 initials
        .join(''),
    [name]
  );

  return (
    <div data-toggle="dropdown" className="avatar-container" style={styles}>
      <span className="avatar-text">{initials}</span>
    </div>
  );
}

AvatarWithBranding.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default memo(AvatarWithBranding);
