import React, { memo } from 'react';
import PropTypes from 'prop-types';

function LoadingIcon({ message, ...props }) {
  return (
    <div>
      <div className={`loadingIcon ${props.class}`}>
        <svg width="16px" height="12px">
          <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
          <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
        </svg>
        {message && <span className="loadingIcon-message">{message}</span>}
      </div>
    </div>
  );
}

LoadingIcon.propTypes = {
  message: PropTypes.string,
  class: PropTypes.string,
};

export default memo(LoadingIcon);
