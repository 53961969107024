import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Select({ options, selected, changeHandler, clickHandler, defaultEmpty, disabled, placeholder, className }) {
    return (
        <select
            className={`form-control ${className}`}
            value={selected || ''}
            disabled={disabled}
            onChange={(e) => {
                if (changeHandler) changeHandler(e.target.value);
            }}
            onClick={(e) => {
                if (clickHandler) clickHandler(e);
            }}>
            {defaultEmpty && <option value={null}>{placeholder}</option>}
            {options.map((option, i) => (
                <option key={`${option.value}-${i}`} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    );
}

Select.propTypes = {
    options: PropTypes.array.isRequired,
    selected: PropTypes.any,
    changeHandler: PropTypes.func,
    clickHandler: PropTypes.func,
    defaultEmpty: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

Select.defaultProps = {
    defaultEmpty: true,
};

export default memo(Select);
