import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import Avatar from '../common/Avatar';
import { useDispatch } from 'react-redux';
import TimesheetStateBadgeService from '../../service/timesheetStateBadgeService';
import DropdownButton from '../common/DropdownButton';
import DropdownButtonItem from '../common/DropdownButtonItem';
import VerticalTimeline from '../timeline/VerticalTimeline';
import VerticalTimelineItem from '../timeline/VerticalTimelineItem';
import ExpensesPerDescription from './ExpensesPerDescription';
import RateLegend from './RateLegend';
import { getFormattedDate } from '../../service/timeService';
import { hideModal, showModal } from '../../redux/system/actions';
import Modal from '../common/Modal';
import ChangeTimesheetSignerModal from '../common/modals/ChangeTimesheetSignerModal';
import useHasAdminClaim from '../common/customHooks/useHasAdminClaim';
import { setTemporaryTimesheetSigner } from '../../redux/timesheets/actions';
import TimePerDescription from './TimePerDescription';

const MORE_INFO_MODAL_ID = 'moreInfoModalId';
const CHANGE_TIMESHEET_SIGNER_MODAL_ID = 'changeTimesheetSignerModalId';
const EVENT_ICONS_MAP = [
  { title: 'Timesheet Approved', icon: 'pe-7s-check', iconClass: 'bg-green-blue' },
  { title: 'Timesheet Submitted', icon: 'pe-7s-cloud-upload', iconClass: 'bg-blue-purple' },
  { title: 'Timesheet Saved', icon: 'pe-7s-diskette', iconClass: 'bg-light-blue-fade' },
  { title: 'Timesheet Created', icon: 'pe-7s-tools', iconClass: 'bg-purple-fade' },
  { title: 'Timesheet Rejected', icon: 'pe-7s-delete-user', iconClass: 'bg-love' },
];

function TimesheetEditDetailsCard({ timesheet, contract }) {
  const dispatch = useDispatch();
  const hasAdminClaim = useHasAdminClaim();
  const { stateBadgeBg, stateBadgeText } = TimesheetStateBadgeService.getStateBadge(timesheet);

  const handleShowModal = useCallback((modalId) => dispatch(showModal(modalId)), [dispatch]);
  const handleHideModal = useCallback(() => dispatch(hideModal()), [dispatch]);
  const NeedHelpLink = useCallback(
    () => (
      <a href="#" onClick={() => handleShowModal(MORE_INFO_MODAL_ID)}>
        → Need Help?
      </a>
    ),
    [handleShowModal]
  );
  const deleteTempSigner = useCallback(() => {
    dispatch(setTemporaryTimesheetSigner(timesheet, null));
  }, [dispatch, timesheet]);

  const mappedTimelineEvents = useMemo(
    () =>
      timesheet.timelineEvents.map((event, i) => {
        const { icon, iconClass } = EVENT_ICONS_MAP.find((c) => c.title === event.title);
        const title =
          event.title === 'Timesheet Submitted' && event.version !== undefined
            ? `${event.title} - v.${event.version}`
            : event.title;
        const date = getFormattedDate(event.date);

        return (
          <VerticalTimelineItem
            icon={icon}
            iconClass={iconClass}
            title={title}
            date={date}
            by={event.username}
            key={i}
          />
        );
      }),
    [timesheet.timelineEvents]
  );

  const showLegend = useMemo(
    () => !contract.isFreeTextAllowedInEntries && contract.rates.some((r) => r.description && r.description !== ''),
    [contract.isFreeTextAllowedInEntries, contract.rates]
  );

  const adminEmail = useMemo(
    () => (contract.company === 'Team4Talent' ? process.env.T4T_ADMIN_MAIL : process.env.TALENT_IT_ADMIN_MAIL),
    [contract.company]
  );

  return (
    <div className="t4t-profile-card">
      <div className="t4t-profile-card--header bg-light-blue-fade" />
      <div className="t4t-profile-card--header-inner">
        {contract.accountManager.name && (
          <>
            <Avatar name={contract.accountManager.name} className="t4t-profile-card--avatar" size="55px" />
            <div className="d-flex" style={{ flexDirection: 'column', marginLeft: '15px', flex: 1 }}>
              <div>
                <strong> {contract.accountManager.name}</strong>
              </div>
              <div>is your Account Manager</div>
            </div>
          </>
        )}
        <DropdownButton text="Contact">
          {contract.accountManager.phone && (
            <DropdownButtonItem
              href={`tel:${contract.accountManager.phone}`}
              icon="pe-7s-phone"
              text={contract.accountManager.phone}
            />
          )}
          <DropdownButtonItem
            href={`mailto:${contract.accountManager.email}`}
            icon="pe-7s-mail"
            text={contract.accountManager.email}
          />
        </DropdownButton>
      </div>
      <div className="t4t-profile-card--content">
        <label className="dark-shade text-uppercase" style={{ marginBottom: '10px' }}>
          Details about your timesheet
        </label>

        <div className="row">
          <div className="col-sm-6">
            State:{' '}
            <div className={`badge badge-pill t4t-badge ${stateBadgeBg}`} style={{ marginLeft: '5px' }}>
              {stateBadgeText}
            </div>
          </div>
          <div className="col-sm-6">
            Version: <label className="dark-shade">{timesheet.version}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            Start: <label className="dark-shade">{moment(timesheet.start).format('DD/MM/YYYY')}</label>
          </div>
          <div className="col-sm-6">
            End: <label className="dark-shade">{moment(timesheet.end).format('DD/MM/YYYY')}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            Client: <label className="dark-shade">{contract.client.name}</label>
          </div>
          <div className="col-sm-6">
            Signer: <label className="dark-shade">{contract.client.timesheetSigner}</label>
          </div>
        </div>
        {contract.timesheetAuthorisation === 1 && (
          <div className="row">
            <div className="col">
              <span>Signer E-mail: </span>
              <label className="dark-shade">
                <a href={`mailto:${contract.client.timesheetSignerEmail}`}>{contract.client.timesheetSignerEmail}</a>
              </label>
              {timesheet.tempTimesheetSignerEmail && (
                <div className="d-block temp-signer">
                  <span>Temporary Signer E-mail: </span>
                  <label className="dark-shade">
                    <a href={`mailto:${timesheet.tempTimesheetSignerEmail}`}>{timesheet.tempTimesheetSignerEmail}</a>
                    {hasAdminClaim && timesheet.state == 0 && (
                      <i
                        className="pe-7s-close clickable"
                        onClick={deleteTempSigner}
                        title="Delete temporary signer"></i>
                    )}
                  </label>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            Contract: <label className="dark-shade">{contract.contractId}</label>
          </div>
        </div>
        {
          <div className="row">
            <div className="col pb-2">
              Signing Procedure:
              {contract.timesheetAuthorisation === 1 && (
                <div className="dark-shade pt-2">
                  <p>
                    By submitting your timesheet <i className="pe-7s-paper-plane"></i> an automated email will be sent
                    to both internal administration and your client.
                  </p>
                </div>
              )}
              {(contract.timesheetAuthorisation === 0 || contract.timesheetAuthorisation === 2) && (
                <div className="dark-shade pt-2">
                  <ul>
                    <li>
                      - Download and print your timesheet <i className='pe-7s-download position-absolute pl-2'></i>
                    </li>
                    <li>- Have your client sign</li>
                    <li>- Scan your signed timesheet</li>
                    <li>
                      - Email it to <a href={`mailto:${adminEmail}`}>administration</a>
                    </li>
                    <li>- Approve your own timesheet <i className='pe-7s-check position-absolute pl-2'></i>
                    </li>
                  </ul>
                </div>
              )}
              {contract.timesheetAuthorisation === 3 && (
                <div className="dark-shade">
                  <p>Please verify with your client.</p>
                </div>
              )}
            </div>
          </div>
        }
        <NeedHelpLink />
        {hasAdminClaim && timesheet.state == 0 && contract.timesheetAuthorisation === 1 && (
          <a href="#" onClick={() => handleShowModal(CHANGE_TIMESHEET_SIGNER_MODAL_ID)} className="d-block">
            → Change Timesheet Signer
          </a>
        )}
        <hr />

        <TimePerDescription rates={contract.rates} days={timesheet.days} standardHoursPerDay={contract.job.standardHoursPerDay} />
        <ExpensesPerDescription days={timesheet.days} />
        <hr />
        <label className="dark-shade text-uppercase" style={{ marginBottom: '10px' }}>
          Timeline
        </label>
        <VerticalTimeline>{mappedTimelineEvents}</VerticalTimeline>

        <hr />
        {showLegend && <RateLegend rates={contract.rates}></RateLegend>}
        <Modal
          id="moreInfoModalId"
          icon="pe-7s-help1"
          iconGradient="bg-green-blue-top"
          title="Before You Start!"
          className="moreInfo"
          confirmHandler={handleHideModal}
          confirmText="I Get It!"
          isLarge>
          <div className="row">
            <div className="col-sm-12">
              <p>
                <strong>
                  The actions you are able to perform on the current timesheet can be found at the top right corner of
                  said timesheet.
                </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              <p>
                <strong>All the possible actions you can encounter:</strong>
              </p>
              <div className="p-2 row t4t-help-buttons align-items-center">
                <i className="btn btn-sm pe-7s-paint-bucket col-sm-1" title="Autocomplete Action" />
                <p className="col-sm-11">
                  Easy way to autocomplete your whole timesheet with the preferred parameters.
                </p>
              </div>
              <div className="p-2 row t4t-help-buttons align-items-center">
                <i className="btn btn-sm pe-7s-upload col-sm-1" title="Upload Timesheet Action" />
                <p className="col-sm-11">Here you can upload a scanned version of your signed timesheet.</p>
              </div>
              <div className="p-2 row t4t-help-buttons align-items-center">
                <i className="btn btn-sm pe-7s-download col-sm-1" title="Download Timesheet Action" />
                <p className="col-sm-11">
                  Clicking this exports your last submitted timesheet. This way you can have it signed by your
                  supervisor and by yourself.
                </p>
              </div>
              <div className="p-2 row t4t-help-buttons align-items-center">
                <i className="btn btn-sm pe-7s-paper-plane col-sm-1" title="Submit Timesheet Action" />
                <p className="col-sm-11">
                  The submit button should be used when your timesheet is complete. When submitted you can export the
                  timesheet to a PDF file.
                </p>
              </div>
              <div className="p-2 row align-items-center">
                <span className="btn btn-sm btn-primary col-sm-1" title="Save Timesheet action">
                  Save
                </span>
                <p className="col-sm-11">
                  The save button will save the current state of your timesheet so you can still make changes when
                  needed.
                </p>
              </div>
              <div className="p-2 row align-items-center">
                <span className="btn btn-sm btn-primary col-sm-1" title="Edit Timesheet action">
                  Edit
                </span>
                <p className="col-sm-11">
                  After submitting your timesheet you will see that the Save button becomes an Edit button. The edit
                  button can be used if you want to make changes to a submitted timesheet. You will have to submit the
                  timesheet again if you want to export that newest version of the timesheet.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col mt-3">
              <p>
                <strong>Following actions can be made when hovering over an entry row.</strong>
              </p>
              <div className="p-2 row align-items-center t4t-help-buttons-no-color">
                <i className="btn pe-7s-plus icon-gradient bg-yellow-fade col-sm-1" title="New Entry" />
                <p className="col-sm-11">Adds a new entry to the selected day.</p>
              </div>
              <div className="p-2 row align-items-center t4t-help-buttons-no-color">
                <i className="btn pe-7s-home icon-gradient bg-purple-fade col-sm-1" title="Homework" />
                <p className="col-sm-11">Marks the current entry as homework.</p>
              </div>
              <div className="p-2 row align-items-center t4t-help-buttons-no-color">
                <i className="btn pe-7s-cash icon-gradient bg-red-fade col-sm-1" title="Expenses" />
                <p className="col-sm-11">
                  Shows expenses for the entry. If the icon is red, there are no expenses configured and clicking the
                  button will create a new expense and open the dropdown.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col mt-3">
              <p>
                <strong>The following information is given:</strong>
              </p>
              <div className="p-2 row align-items-center t4t-help-buttons-no-color">
                <i className="btn pe-7s-home icon-gradient bg-purple-fade col-sm-1" title="Homework" />
                <p className="col-sm-11">The current entry is set as homework.</p>
              </div>
              <div className="p-2 row align-items-center t4t-help-buttons-no-color">
                <i className="btn pe-7s-cash icon-gradient bg-red-fade col-sm-1" title="Expenses" />
                <p className="col-sm-11">The current entry has expenses.</p>
              </div>
            </div>
          </div>
        </Modal>
        <ChangeTimesheetSignerModal id={CHANGE_TIMESHEET_SIGNER_MODAL_ID} timesheet={timesheet} />
      </div>
    </div>
  );
}

TimesheetEditDetailsCard.propTypes = {
  timesheet: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
};

export default memo(TimesheetEditDetailsCard);
