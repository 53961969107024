import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UsertracksListCard from '../components/usertracks/UsertracksListCard';
import { fetchUserTracks } from '../redux/usertracks/actions';

function Usertracks() {
  const dispatch = useDispatch();
  const { isFetchingUserTracks, triedFetchingUserTracks } = useSelector((state) => state.system);
  const { selectedUser } = useSelector((state) => state.users);

  useEffect(() => {
    if (!isFetchingUserTracks && !triedFetchingUserTracks && selectedUser.myportalUserId) {
      dispatch(fetchUserTracks(selectedUser.myportalUserId, 0, 20));
    }
  }, [dispatch, isFetchingUserTracks, triedFetchingUserTracks, selectedUser.myportalUserId]);

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <UsertracksListCard />
        </div>
      </div>
    </div>
  );
}

export default Usertracks;
