import { getAssetHistoryByUserId } from '../../api/assetsApi';
import { showRequestFailedToast } from './../../service/toastService';

export const LOANED = 'LOANED';
export const RETURNED = 'RETURNED';
export const FETCH_ASSET_HISTORY = 'FETCH_ASSET_HISTORY';
export const FETCH_ASSET_HISTORY_SUCCESS = 'FETCH_ASSET_HISTORY_SUCCESS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

export function fetchAssetHistory(userId, skip, take, historyType) {
  return async function (dispatch) {
    dispatch({ type: FETCH_ASSET_HISTORY });
    try {
      const response = await getAssetHistoryByUserId(userId, { skip, take, historyType });
      return dispatch({
        type: FETCH_ASSET_HISTORY_SUCCESS,
        assetHistory: response.data.data,
        historyType,
        skip,
        take,
        totalCount: response.data.totalCount,
      });
    } catch (error) {
      showRequestFailedToast();
    }
  };
}

export function setSelectedTab(selectedTab) {
  return { type: SET_SELECTED_TAB, selectedTab };
}
