import React, { useCallback, useEffect, useState } from 'react';
import Avatar from '../common/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../redux/system/actions';
import DropdownButtonItem from '../common/DropdownButtonItem';
import { selectUser } from '../../redux/users/actions';
import Toggle from '../common/toggle';
import { fetchNewsCount } from '../../redux/news/actions';
import { toast } from 'react-toastify';
import { getToastPropsBy } from '../../service/toastService';
import GetCookie from '../../Hooks/getCookie';
import UpdateCookie from '../../Hooks/updateCookie';
import { useAuth } from 'react-oidc-context';
import NotificationWrapper from '../notifications/NotificationWrapper';

function Header() {
  const dispatch = useDispatch();
  const auth = useAuth();
  const [mode, setMode] = useState('light');
  const [toggle, setToggle] = useState(false);
  const showLogo = useSelector((state) => !state.system.hideMenu);
  const user = useSelector((state) => state.auth.user);
  const selectedUser = useSelector((state) => state.users.selectedUser);
  const { newsCount } = useSelector((state) => state.news);
  const { triedFetchingNewsCount } = useSelector((state) => state.system);
  const { tenant_id: tenantIdLoggedInUser, candidate_type: UserTypeLoggedInUser } = useSelector((state) => state.auth.user.profile);
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  const onSelectMode = useCallback((selectedMode) => {
    UpdateCookie('bgMode', selectedMode);
    setMode(selectedMode);
    if (selectedMode === 'dark' || selectedMode == true) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, []);

  const handleToggleChange = useCallback(() => {
    setToggle(!toggle);
    onSelectMode(!toggle ? 'dark' : 'light');
  }, [onSelectMode, toggle]);

  const handleToggleMenu = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(toggleMenu());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchNewsCount(tenantIdLoggedInUser, UserTypeLoggedInUser));
  }, [triedFetchingNewsCount, UserTypeLoggedInUser, dispatch, tenantIdLoggedInUser]);

  useEffect(() => {
    var prefersDarkMode;
    if (GetCookie('bgMode') != undefined) {
      prefersDarkMode = GetCookie('bgMode') == 'dark' ? true : false;
    } else {
      prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    onSelectMode(prefersDarkMode ? 'dark' : 'light');
    if (prefersDarkMode) {
      setToggle(true);
      onSelectMode('dark');
    }
  }, [onSelectMode]);

  useEffect(() => {
    if (triedFetchingNewsCount && newsCount > 0) {
      const { toastBody, toastOptions } = getToastPropsBy({
        icon: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f4e9.png',
        title: 'New News',
        text: `There are ${newsCount} new news items available for you!\nCheck them out in the news tab!`,
      });
      toast.success(toastBody, toastOptions);
    }
  }, [newsCount, triedFetchingNewsCount]);

  const handleSignOut = () => {
    localStorage.clear();
    auth.signoutRedirect();
  };

  return (
    <nav className="navbar navbar-t4t navbar-expand-md shadow fixed-top">
      <i className={'pe-7s-menu navbar-t4t--hamburger' + (showLogo ? '' : ' small')} onClick={handleToggleMenu} />
      {showLogo && <a className="navbar-brand navbar-brand--logo" href="/" />}
      {selectedUser.managingOtherUser && (
        <span
          onClick={() => dispatch(selectUser(selectedUser))}
          className={'nav navbar-nav navbar-center badge badge-pill t4t-badge bg-yellow-fade clickable'}
          style={{ fontSize: '14px' }}
          title="Click to stop managing">
          Managing for {selectedUser.firstName} {selectedUser.lastName}
        </span>
      )}
      <div className="collapse navbar-collapse" />
      <div className="nav navbar-nav navbar-right">
        <NotificationWrapper tenantIdLoggedInUser={tenantIdLoggedInUser} currentLoggedUserId={currentLoggedUserId} />
        <Avatar name={`${user.profile.name} ${user.profile.family_name}`} />
        <div className="navbar navbar-name">
          <li className="dropdown">
            <a className="dropdown-toggle" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
              Hi, {user.profile.name}
            </a>
            <ul className="dropdown-menu user-access">
              {user.profile.candidate_type === 'Employee' && (
                <>
                  <li>
                    <Toggle
                      toggle={toggle}
                      handleToggleChange={handleToggleChange}
                      icon={
                        mode !== 'dark' ? (
                          <svg className={'toggle-icon'} xmlns="http://www.w3.org/2000/svg" width={33} viewBox="0 0 24 24" fill="#f1e408">
                            <rect fill="none" />
                            <path d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
                          </svg>
                        ) : (
                          <svg className={'toggle-icon'} xmlns="http://www.w3.org/2000/svg" width={33} viewBox="0 0 24 24" fill="#000000">
                            <rect fill="none" height="24" width="24" />
                            <path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
                          </svg>
                        )
                      }
                    />
                  </li>

                  {user.profile.company === 'Talent-IT' ||
                    user.profile.company === 'Talent2Test' ||
                    (user.profile.company === 'Team4Talent' && (
                      <li>
                        <DropdownButtonItem
                          text="Speakap"
                          className="font-weight-bold"
                          imageName="speakap.png"
                          clickHandler={() => {
                            window.open('https://talent-group.speakap.com/', '_blank');
                          }}
                        />
                      </li>
                    ))}
                  <li>
                    <DropdownButtonItem
                      text="SD Worx"
                      className="font-weight-bold"
                      imageName="sd-worx.png"
                      clickHandler={() => {
                        window.open('https://www.myworkandme.com/portal/', '_blank');
                      }}
                    />
                  </li>
                </>
              )}
              <li>
                <DropdownButtonItem text="Sign out" icon="pe-7s-power" clickHandler={handleSignOut} />
              </li>
            </ul>
          </li>
        </div>
      </div>
    </nav>
  );
}

export default Header;
