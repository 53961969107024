import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../redux/system/actions';
import Card from '../../common/Card';
import SelectContractModal from '../../common/modals/SelectContractModal';
import TimesheetService from '../../../service/timesheetService';

// Outside of component function to prevent reallocation every render, still accessible by component though
const modalId = 'selectContract';

function NoCurrentTimesheetCard({ title, message }) {
  const dispatch = useDispatch();
  const { handleCreateTimesheet } = TimesheetService();
  const { contractsList: contracts } = useSelector((state) => state.contracts);

  const handleShowModal = useCallback(() => dispatch(showModal(modalId)), [dispatch]);

  const createLinkComponent = useMemo(() => {
    if (contracts) {
      return <a onClick={handleShowModal}>Create one now →</a>;
    }
  }, [contracts, handleShowModal]);

  return (
    <>
      <Card
        title={title || 'Your Current Timesheet'}
        icon="pe-7s-copy-file"
        iconGradient="bg-green-blue-top"
        contentClass="no-current-card">
        <div className="row-fluid">
          <div className="col-lg-12">
            <div>{message}</div>
            <div>{createLinkComponent}</div>
          </div>
        </div>
      </Card>
      <SelectContractModal
        id={modalId}
        showCurrentMonthInMessage={true}
        confirmHandler={(contract) => handleCreateTimesheet(contract)}
      />
    </>
  );
}

NoCurrentTimesheetCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default memo(NoCurrentTimesheetCard);
