import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function SetCompletionDateMilestonesModal({ id, confirmHandler, selectedUserTrack, milestone, title, confirmText }) {
  const [selectedDate, setSelectedDate] = useState(milestone ? new Date(milestone.completionDate) : new Date());

  useMemo(() => {
    milestone ? setSelectedDate(new Date(milestone.completionDate)) : setSelectedDate(new Date())
  }, [milestone])

  const handleChangeMonth = useCallback(
    (date) => {
      if (date < new Date(selectedUserTrack.creationDate)) {
        setSelectedDate(new Date(selectedUserTrack.creationDate));
      } else if (date > new Date()) {
        setSelectedDate(new Date());
      } else {
        setSelectedDate(date);
      }
    },
    [selectedUserTrack.creationDate]
  );

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title={title != null ? title : 'Please confirm the completion date'}
      contentClass="modal-body-center-h"
      confirmText={confirmText}
      confirmHandler={() => confirmHandler({ selectedDate: moment(selectedDate).format('LL') })}>
      <DatePicker
        inline
        selected={selectedDate}
        minDate={new Date(selectedUserTrack.creationDate)}
        maxDate={new Date()}
        onChange={setSelectedDate}
        dateFormat="dd/MM/yyyy"
        onMonthChange={handleChangeMonth}
      />
    </Modal>
  );
}

SetCompletionDateMilestonesModal.propTypes = {
  id: PropTypes.string.isRequired,
  confirmHandler: PropTypes.func,
  selectedUserTrack: PropTypes.object.isRequired,
  milestone: PropTypes.object,
  title: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
};

export default memo(SetCompletionDateMilestonesModal);
