import React, { useState, useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Modal from '../Modal';
import Select from '../Select';
import { sortContractsByDateAndStatus } from '../SortingService';

function SelectContractModal({ id, confirmHandler, showCurrentMonthInMessage }) {
  const history = useHistory();
  const contracts = useSelector((state) => state.contracts.contractsList);
  const sortedContracts = sortContractsByDateAndStatus(contracts);
  const isShowCurrentMonthInMessage = useState(showCurrentMonthInMessage);

  const contractsMapped = useMemo(() => {
    return sortedContracts.map((c) => {
      const value = c.contractId;
      const text = `${moment(c.startDate).format('MMM YY')} ${
        c.endDate ? `- ${moment(c.endDate).format('MMM YY')}` : ''
            } | ${c.client.name} ${c.isActive ? '' : '| inactive'}`;
        const endDate = c.endDate;
      return { key: text, value, text, endDate };
    });
  }, [sortedContracts]);

  const [selectedContract, setSelectedContract] = useState(sortedContracts[0]);
  const handleContractSelected = useCallback(
    (value) => {
      const newlySelectedContract = sortedContracts.find((c) => c.contractId === value);
      setSelectedContract(newlySelectedContract);
    },
    [sortedContracts]
  );

  const handleConfirmModal = useCallback(
    () =>
      confirmHandler != null
        ? confirmHandler(selectedContract)
        : history.push(
            `/timesheets/new/${selectedContract.contractId}/${selectedContract.company}/${moment().year()}/${
              moment().month() + 1
            }`
          ),
    [confirmHandler, history, selectedContract]
  );

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title="Choose a contract"
      draggable
      confirmHandler={handleConfirmModal}>
      <p>
        You have multiple contracts. Select the contract you want to create a new timesheet for
        {isShowCurrentMonthInMessage ? ` ${moment().format('MMMM YYYY')}` : ''}.
      </p>
      <Select
        options={contractsMapped}
        changeHandler={handleContractSelected}
        selected={selectedContract.contractId}
        defaultEmpty={false}
      />
    </Modal>
  );
}

SelectContractModal.propTypes = {
  id: PropTypes.any.isRequired,
  confirmHandler: PropTypes.func,
  showCurrentMonthInMessage: PropTypes.bool,
};

export default memo(SelectContractModal);
