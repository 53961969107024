import { getDocuments, downloadDocument as handleDownloadDocument, getSasUriForBlob } from '../../api/documentsApi';
import fileService from '../../service/fileService';
import { showRequestFailedToast, DOWNLOAD_DOCUMENT_TOAST_MESSAGES } from '../../service/toastService';
import { toast } from 'react-toastify';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILED = 'FETCH_DOCUMENTS_FAILED';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILED = 'DOWNLOAD_DOCUMENT_FAILED';

let currentToast = null;

export function fetchDocuments(skip, take, tenantIds, userTypeIds, published) {
  return async function (dispatch) {
    dispatch({ type: FETCH_DOCUMENTS });
    try {
      const response = await getDocuments({ skip, take, tenantIds, userTypeIds, published });
      return dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        documents: response.data.data,
        totalCount: response.data.totalCount,
        skip,
        take,
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_DOCUMENTS_FAILED, error });
    }
  };
}

export function downloadDocument(documentId, documentName) {
  return async function (dispatch) {
    dispatch({ type: DOWNLOAD_DOCUMENT, documentId });
    const { toastBody, toastOptions } = DOWNLOAD_DOCUMENT_TOAST_MESSAGES.pending;
    currentToast = toast.loading(toastBody, toastOptions);

    try {
      const response = await getSasUriForBlob(documentId);
      const document = await handleDownloadDocument(response.data);
      fileService.handleFileDownload(document, documentName);
      toast.update(currentToast, DOWNLOAD_DOCUMENT_TOAST_MESSAGES.success);
      return dispatch({ type: DOWNLOAD_DOCUMENT_SUCCESS, documentId });
    } catch {
      toast.update(currentToast, DOWNLOAD_DOCUMENT_TOAST_MESSAGES.error);
      return dispatch({ type: DOWNLOAD_DOCUMENT_FAILED, documentId });
    }
  };
}
