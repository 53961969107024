import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useHasAdminClaim() {
  const { role: userRoles } = useSelector((state) => state.auth.user.profile);
  const hasAdminClaim = useMemo(() => {
    return userRoles.includes('t4t-myportal-admin') || userRoles.includes('global_administrator');
  }, [userRoles]);

  return hasAdminClaim;
}

export default useHasAdminClaim;
