import { SET_USER } from './actions';
import baseApi from '../../api/baseApi';

export default function authReducer(state = { ...AUTH_BASE_STATE }, action) {
  switch (action.type) {
    case SET_USER:
      if (action.user) {
        baseApi.setBearerToken(action.user.access_token);
      }
      return { ...state, user: action.user };
    default:
      return state;
  }
}

export const AUTH_BASE_STATE = {
  user: null
};
