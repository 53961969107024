import React, { useMemo, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../redux/system/actions';
import Draggable from 'react-draggable';
import useEscapeKey from './customHooks/useEscape';

function Modal({
  id,
  title,
  icon,
  iconGradient,
  children,
  confirmText,
  confirmHandler,
  closeHandler,
  backButtonHandler,
  contentClass,
  isLarge,
  showBackButton,
  confirmButtonDisabled,
  draggable = false,
  disabledText,
  backdrop,
  keepOpen,
}) {
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.system.isShowingModal);
  const showModal = useMemo(() => currentModal === id, [currentModal, id]);
  const handleHideModal = useCallback(() => {
    if (closeHandler) {
      closeHandler();
    }
    dispatch(hideModal());
  }, [closeHandler, dispatch]);
  useEscapeKey(handleHideModal);

  const nodeRef = useRef(null);

  return (
    <Draggable handle=".handle" disabled={!draggable} nodeRef={nodeRef}>
      <div
        ref={nodeRef}
        className={`modal ${showModal || keepOpen ? 'show' : ''} fade ${backdrop && 'backdrop-modal'}`}
        id={id}
        style={{ display: showModal || keepOpen ? 'block' : 'none' }}>
        <div className={'modal-dialog modal-dialog-centered ' + (isLarge ? 'modal-lg' : '')}>
          <div className="modal-content">
            {title && (
              <div className="modal-header t4t-modal-header handle">
                <button type="button" className="close" onClick={handleHideModal}>
                  <span>&times;</span>
                </button>
                <span style={{ flex: 1 }}>{title}</span>
                {icon && <i className={`${icon} ${iconGradient ? 'icon-gradient ' + iconGradient : ''}`} />}
              </div>
            )}

            <div className={`modal-body ${contentClass && contentClass}`} style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {children}
            </div>
            <div className="modal-footer">
              {showBackButton && <i className="pe-7s-angle-left-circle angle-left-button clickable mr-auto" onClick={backButtonHandler}></i>}
              <button type="button" className="btn btn-secondary" onClick={handleHideModal}>
                Close
              </button>
              <button
                disabled={confirmButtonDisabled}
                title={confirmButtonDisabled ? disabledText : ''}
                type="button"
                className="btn btn-primary"
                data-test="SAVE"
                onClick={() => {
                  confirmHandler();
                  handleHideModal();
                }}>
                {confirmText || 'Ok'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconGradient: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  confirmText: PropTypes.string,
  confirmHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func,
  backButtonHandler: PropTypes.func,
  contentClass: PropTypes.string,
  isLarge: PropTypes.bool,
  showBackButton: PropTypes.bool,
  confirmButtonDisabled: PropTypes.bool,
  draggable: PropTypes.bool,
  disabledText: PropTypes.string,
  backdrop: PropTypes.bool,
  keepOpen: PropTypes.bool,
};

export default memo(Modal);
