import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { showModal } from '../../redux/system/actions';
import TimesheetStateBadgeService from '../../service/timesheetStateBadgeService';
import { fetchTimesheetsByUserId } from '../../redux/timesheets/actions';
import moment from 'moment';
import Table from '../common/Table';

const MODAL_ID = 'selectContractsList';
const NOW = moment();

function TimesheetsList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const contracts = useSelector((state) => state.contracts.contractsList);
  const timesheets = useSelector((state) => state.timesheets.timesheetList);
  const { selectedUser } = useSelector((state) => state.users);
  const { skip, take, totalCount, contractsHaveTimesheetForCurrentMonth } = useSelector((state) => state.timesheets);

  const handleChangePage = (pageIndex) => {
    dispatch(fetchTimesheetsByUserId(selectedUser.myportalUserId, selectedUser.managingOtherUser, pageIndex, take));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchTimesheetsByUserId(selectedUser.myportalUserId, selectedUser.managingOtherUser, 0, event.target.value));
  };

  const handleShowModal = useCallback((modalId) => dispatch(showModal(modalId)), [dispatch]);

  const hasMultipleContracts = useMemo(() => contracts && contracts.length > 1, [contracts]);
  const createLinkComponent = useMemo(
    () =>
      hasMultipleContracts ? (
        <a
          onClick={(e) => {
            e.preventDefault();
            handleShowModal(MODAL_ID);
          }}>
          Create one now →
        </a>
      ) : (
        <NavLink to={`timesheets/new/${contracts[0].contractId}/${contracts[0].company}/${NOW.year()}/${NOW.month() + 1}`}>Create one now →</NavLink>
      ),

    [contracts, handleShowModal, hasMultipleContracts]
  );

  const columns = (
    <>
      <th>Client</th>
      <th>Contract</th>
      <th style={{ textAlign: 'right' }}>Hours</th>
      <th style={{ textAlign: 'right' }}>Expenses</th>
      <th style={{ textAlign: 'right' }}>Km</th>
      <th>Month</th>
      <th>State</th>
      <th>Version</th>
    </>
  );

  const mappedTimesheets = useMemo(
    () =>
      timesheets.map((timesheet) => {
        const { stateBadgeBg, stateBadgeText } = TimesheetStateBadgeService.getStateBadge(timesheet);
        const timesheetStartDate = moment(timesheet.start);

        return (
          <tr
            key={timesheet.timesheetId}
            className="clickable"
            onClick={() => {
              history.push(`${url}/edit/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}`);
            }}>
            <td>{timesheet.clientCompany}</td>
            <td>{timesheet.contractId}</td>
            <td style={{ textAlign: 'right' }}>{Number(timesheet.totalHours) ? Number(timesheet.totalHours).toFixed(2) : '0'} h</td>
            <td style={{ textAlign: 'right' }}>{timesheet.totalExpenses.toFixed(2)} €</td>
            <td style={{ textAlign: 'right' }}>{timesheet.totalKm} km</td>
            <td>{timesheetStartDate.format('MMMM YYYY')}</td>
            <td>
              <div className={`badge badge-pill t4t-badge ${stateBadgeBg}`}>{stateBadgeText}</div>
            </td>
            <td>{timesheet.version}</td>
          </tr>
        );
      }),
    [history, timesheets, url]
  );

  const noCurrentMonthTimesheetText = useMemo(() => {
    return (
      contracts &&
      timesheets &&
      !contractsHaveTimesheetForCurrentMonth &&
      contracts.length > 0 && (
        <div className="row-fluid m-b-3">
          <div className="col-lg-12">
            {contracts.length === 1 ? (
              <div>{`It looks like you haven't created a timesheet yet for ${NOW.format('MMMM YYYY')} for the contract:`}</div>
            ) : (
              <div>{`It looks like not all contracts have a timesheet yet for ${NOW.format('MMMM YYYY')}.`}</div>
            )}
            <div>{createLinkComponent}</div>
          </div>
        </div>
      )
    );
  }, [contracts, timesheets, contractsHaveTimesheetForCurrentMonth, createLinkComponent]);

  return (
    <>
      {noCurrentMonthTimesheetText}
      <Table
        columns={columns}
        data={mappedTimesheets}
        totalCount={totalCount}
        skip={skip}
        changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
        take={take}
        changeRowsPerPageHandler={handleChangeRowsPerPage}
      />
    </>
  );
}

export default TimesheetsList;
