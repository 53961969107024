import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

function RateLegend({ rates }) {
  const ratesFiltered = useMemo(() => rates.filter((r) => r.description && r.description !== ''), [rates]);
  const ratesSorted = ratesFiltered.sort((a, b) => (a.isBillable > b.isBillable ? -1 : a.isBillable < b.isBillable ? 1 : 0)).reduce((acc, element) => {
    if (element.key == 'DLST') {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);
  const ratesMapped = useMemo(
    () =>
    ratesSorted.map((rate, i) => {
        return (
          <div
            className="row collapse show"
            id="collapseLegend"
            style={{ marginBottom: '5px' }}
            key={`${i}-${rate.name}`}>
            <div className="col-sm-4">{rate.name}</div>
            <div className="col-sm-8 dark-shade">{rate.description}</div>
          </div>
        );
      }),
    [ratesSorted]
  );

  return (
    <div>
      <label
        className="dark-shade text-uppercase"
        style={{ marginBottom: '10px' }}
        data-toggle="collapse"
        data-target="#collapseLegend">
        Description Legend
      </label>
      {ratesMapped}
      <hr />
    </div>
  );
}

RateLegend.propTypes = {
  rates: PropTypes.array.isRequired,
};

export default memo(RateLegend);
