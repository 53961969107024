import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { addCourseEnrollemnt, getCourseEnrollmentsFromCourse } from '../../../api/coursesApi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';

function CreateCourseEnrollmentModal({ id, course, onCourseEnrollmentAdded }) {
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);
  const [motivationLetter, setMotivationLetter] = useState('');

  const isConfirmButtonDisabled = () => {
    return motivationLetter.trim() === '';
  };

  const handleCreateCourseEnrollment = () => {
    const result = getCourseEnrollmentsFromCourse(course.id);

    result
      .then((enrollment) => {
        for (const enroll of enrollment.data) {
          if (currentLoggedUserId === enroll.user.id) {
            toast.error('You already enrolled for this course', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
            return true;
          }
        }
        // User is not enrolled
        return false;
      })
      .then((isEnrolled) => {
        if (!isEnrolled) {
          // Proceed with the enrollment process
          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${currentDate.getFullYear()}`;

          const [day, month, year] = formattedDate.split('/');
          const dateOfEnrollment = new Date(`${year}-${month}-${day}`);

          const courseEnrollment = {
            dateOfEnrollment: dateOfEnrollment,
            motivationLetter: motivationLetter,
            reviewReminderEmail: '',
            status: 'Requested',
            courseId: course.id,
            userId: currentLoggedUserId,
            tenant: '',
          };

          addCourseEnrollemnt(courseEnrollment)
            .then(() => {
              toast.success('You have successfully send a request for this course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
              if (onCourseEnrollmentAdded) {
                onCourseEnrollmentAdded();
              }
            })
            .catch(() => {
              toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
            });
        }
      })
      .catch(() => {
        toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      });
  };

  const closeModal = () => {
    setMotivationLetter('');
  };

  return (
    <Modal
      id={id}
      icon="pe-7s-chat"
      iconGradient="bg-green-blue-top"
      title={`Motivation request (${course.name})`}
      contentClass="modal-body-center-h"
      confirmText="SEND REQUEST"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleCreateCourseEnrollment}>
      <div className="width100">
        <label>Request</label>
        <textarea
          placeholder="Tell us why you want to participate in this course..."
          className="form-control custom-input"
          rows="5"
          value={motivationLetter}
          onChange={(e) => setMotivationLetter(e.target.value)}
        />
      </div>
    </Modal>
  );
}

CreateCourseEnrollmentModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(CreateCourseEnrollmentModal);
