import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/css/index.css';
import './assets/icons/css/icons.css';
import App from './components/App';
import ValidateTimesheet from './pages/ValidateTimesheet';
import initialState from './redux/baseState';
import configureStore from './redux/configureStore';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import AuthWrapper from './components/AuthWrapper';

initAppInsights();

const reduxStore = configureStore({ ...initialState });
const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <ReduxProvider store={reduxStore.store}>
    <AuthWrapper>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <BrowserRouter>
          <Switch>
            <Route path="/validate" component={ValidateTimesheet} />
            <React.StrictMode>
              {window.google === undefined ? (
                <LoadScript googleMapsApiKey={process.env.GOOGLE_API_KEY}>
                  <GoogleMap />
                </LoadScript>
              ) : (
                <GoogleMap />
              )}
              <Route component={App} />
            </React.StrictMode>
          </Switch>
          <ToastContainer theme="colored" autoClose={4000} />
        </BrowserRouter>
      </PersistGate>
    </AuthWrapper>
  </ReduxProvider>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

function initAppInsights() {
  const browserHistory = createBrowserHistory({ basename: '' });
  const reactPlugin = new ReactPlugin();
  new ApplicationInsights({
    config: {
      connectionString: process.env.AI_Connection,
      // If you're adding the Click Analytics plug-in, delete the next line.
      extensions: [reactPlugin],
      // Add the Click Analytics plug-in.
      // extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        // Add the Click Analytics plug-in.
        // [clickPluginInstance.identifier]: clickPluginConfig
      },
    },
  }).loadAppInsights();
}
