import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import TimesheetsListCard from '../components/timesheets/TimesheetsListCard';
import EditTimesheet from '../components/timesheets/EditTimesheet';
import CreateTimesheet from '../components/timesheets/CreateTimesheet';
import { useSelector } from 'react-redux';
import { shouldBeAbleToSeeTimesheets } from '../redux/helperSelectors';

function TimesheetsPage() {
  const { path } = useRouteMatch();
  const shouldBeAbleToSeeTimesheetsTab = useSelector((state) => shouldBeAbleToSeeTimesheets(state));

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        {shouldBeAbleToSeeTimesheetsTab &&
          <>
            <Route
              path={path}
              exact
              render={() => (
                <div className="col-lg-12">
                  <TimesheetsListCard />
                </div>
              )}
            />
            <Route path={`${path}/new/:contractId/:company/:year/:month`} exact component={CreateTimesheet} />
            <Route path={`${path}/edit/:company/:contractId/:timesheetId`} component={EditTimesheet} />
          </>
        }
      </div>
    </div>
  );
}

export default TimesheetsPage;
