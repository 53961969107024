import { saveAs } from 'file-saver';
class FileService {
  handleFileDownload(response, documentName) {
    if (response) {
      var fileName = documentName ? documentName : getFileNameFromHeaders(response.headers);
      saveAs(response.data, fileName);
    }
  }
}
export default new FileService();

function getFileNameFromHeaders(headers) {
  let fileName = 'timesheet.pdf';
  const contentDisposition = headers['content-disposition'];

  if (contentDisposition) {
    const idx = contentDisposition.indexOf('filename=');
    if (idx >= 0) {
      const endIdx = contentDisposition.indexOf(';', idx);
      fileName = contentDisposition.substring(idx + 9, endIdx !== -1 ? endIdx : contentDisposition.length);
      fileName = fileName.replace(/"/g, '');
    }
  } else {
    console.error('Content-Disposition header is missing or undefined');
  }

  return fileName;
}
