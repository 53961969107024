import {
  getUserTracks,
  updateSelectedMilestonesForUsertrack,
  deleteUserTrack,
  updateMilestoneStatus,
  completeUserTrack,
  updateCompletionDate,
} from '../../api/tracksApi';
import { toast } from 'react-toastify';
import {
  showRequestFailedToast,
  UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES,
  DELETE_USERTRACK_TOAST_MESSAGES,
  COMPLETE_USERTRACK_TOAST_MESSAGES,
} from '../../service/toastService';

export const FETCH_USER_TRACKS = 'FETCH_USER_TRACKS';
export const FETCH_USER_TRACKS_SUCCESS = 'FETCH_USER_TRACKS_SUCCESS';
export const FETCH_USER_TRACKS_FAILED = 'FETCH_USER_TRACKS_FAILED';
export const UPDATE_MILESTONE_STATUS = 'UPDATE_MILESTONE_STATUS';
export const UPDATE_MILESTONE_STATUS_SUCCESS = 'UPDATE_MILESTONE_STATUS_SUCCESS';
export const UPDATE_MILESTONE_STATUS_FAILED = 'UPDATE_MILESTONE_STATUS_FAILED';
export const COMPLETE_USERTRACK = 'COMPLETE_USERTRACK';
export const COMPLETE_USERTRACK_SUCCESS = 'COMPLETE_USERTRACK_SUCCESS';
export const COMPLETE_USERTRACK_FAILED = 'COMPLETE_USERTRACK_FAILED';
export const UPDATE_SELECTED_MILESTONES = 'UPDATE_SELECTED_MILESTONES';
export const UPDATE_SELECTED_MILESTONES_SUCCESS = 'UPDATE_SELECTED_MILESTONES_SUCCESS';
export const UPDATE_SELECTED_MILESTONES_FAILED = 'UPDATE_SELECTED_MILESTONES_FAILED';
export const DELETE_USERTRACK = 'DELETE_USERTRACK';
export const DELETE_USERTRACK_SUCCESS = 'DELETE_USERTRACK_SUCCESS';
export const DELETE_USERTRACK_FAILED = 'DELETE_USERTRACK_FAILED';
export const UPDATE_COMPLETION_DATE = 'UPDATE_COMPLETION_DATE';
export const UPDATE_COMPLETION_DATE_SUCCESS = 'UPDATE_COMPLETION_DATE_SUCCESS';
export const UPDATE_COMPLETION_DATE_FAILED = 'UPDATE_COMPLETION_DATE_FAILED';

export const SHOW_TRACKS_MODAL = 'SHOW_TRACKS_MODAL';

let currentToast = null;

export function fetchUserTracks(userId, skip, take) {
  return async function (dispatch) {
    dispatch({ type: FETCH_USER_TRACKS });
    try {
      const response = await getUserTracks(userId, skip, take);
      return dispatch({ type: FETCH_USER_TRACKS_SUCCESS, usertracks: response.data.data, totalCount: response.data.totalCount, skip, take });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_USER_TRACKS_FAILED, error });
    }
  };
}

export function setMilestoneStatus(userTrack, milestone) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_MILESTONE_STATUS, userTrack, milestone });
    try {
      const response = await updateMilestoneStatus(userTrack, milestone);
      return dispatch({ type: UPDATE_MILESTONE_STATUS_SUCCESS, userTrack: response.data });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: UPDATE_MILESTONE_STATUS_FAILED, error });
    }
  };
}

export function completeUserTrackAndMilestones(userTrack, milestones) {
  return async function (dispatch) {
    dispatch({ type: COMPLETE_USERTRACK });
    currentToast = toast.loading(
      COMPLETE_USERTRACK_TOAST_MESSAGES.pending.toastBody,
      COMPLETE_USERTRACK_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      const response = await completeUserTrack(userTrack, milestones);
      toast.update(currentToast, COMPLETE_USERTRACK_TOAST_MESSAGES.success);
      return dispatch({
        type: COMPLETE_USERTRACK_SUCCESS,
        userTrack: response.data,
        milestones: response.data.selectedMilestones,
        completionDate: response.data.completionDate,
      });
    } catch (error) {
      toast.update(currentToast, COMPLETE_USERTRACK_TOAST_MESSAGES.error);
      return dispatch({ type: COMPLETE_USERTRACK_FAILED, error });
    }
  };
}

export function updateSelectedMilestones(userTrackId, selectedMilestones) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_SELECTED_MILESTONES });
    currentToast = toast.loading(
      UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES.pending.toastBody,
      UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      const selectedMilestonesIds = { milestoneIds: selectedMilestones.map((milestone) => milestone.milestoneId) };
      const response = await updateSelectedMilestonesForUsertrack(userTrackId, selectedMilestonesIds);
      toast.update(currentToast, UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES.success);
      return dispatch({
        type: UPDATE_SELECTED_MILESTONES_SUCCESS,
        userTrackId,
        selectedMilestones,
        userTrack: response.data,
      });
    } catch (error) {
      toast.update(currentToast, UPDATE_SELECTED_MILESTONES_TOAST_MESSAGES.error);
      return dispatch({ type: UPDATE_SELECTED_MILESTONES_FAILED, error });
    }
  };
}

export function setCompletionDate(userTrackId, milestone) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_COMPLETION_DATE, milestone });
    try {
      const response = await updateCompletionDate(userTrackId, milestone);
      return dispatch({
        type: UPDATE_COMPLETION_DATE_SUCCESS,
        userTrackId,
        milestone,
        completionDateUserTrack: response.data,
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: UPDATE_COMPLETION_DATE_FAILED, error });
    }
  };
}

export function deleteSelectedUserTrack(userTrack) {
  return async function (dispatch) {
    dispatch({ type: DELETE_USERTRACK, userTrack });
    currentToast = toast.loading(
      DELETE_USERTRACK_TOAST_MESSAGES.pending.toastBody,
      DELETE_USERTRACK_TOAST_MESSAGES.pending.toastOptions
    );
    try {
      await deleteUserTrack(userTrack);
      toast.update(currentToast, DELETE_USERTRACK_TOAST_MESSAGES.success);
      return dispatch({ type: DELETE_USERTRACK_TOAST_MESSAGES, userTrack });
    } catch (error) {
      toast.update(currentToast, DELETE_USERTRACK_TOAST_MESSAGES.error);
      return dispatch({ type: DELETE_USERTRACK_TOAST_MESSAGES, error });
    }
  };
}

export function showTracksModal() {
  return function (dispatch) {
    return dispatch({ type: SHOW_TRACKS_MODAL });
  };
}
