import api from './baseApi';

export function getTimesheetsByUserId(userId, params) {
  return api.get(`/timesheets/timesheetsByUser/${userId}`, params);
}

export function getTimesheetsByContractId(contractId) {
  return api.get(`/timesheets/timesheetsByContract/${contractId}`);
}

export function getTimesheet(timesheetId, contractId, company) {
  return api.get(`/timesheets/${contractId}/${timesheetId}?company=${company}`);
}

export function getTimesheetByDate(year, month, contractId, company) {
  return api.get(`/timesheets/${contractId}/${year}/${month}?company=${company}`);
}

export function saveTimesheet(timesheet) {
  return api.put(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}?company=${timesheet.company}`,
    timesheet
  );
}

export function submitTimesheet(timesheet) {
  return api.put(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/submit?company=${timesheet.company}`,
    timesheet
  );
}

export function approveTimesheet(timesheet) {
  return api.put(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/approve?company=${timesheet.company}`,
    timesheet
  );
}

export function downloadTimesheet(timesheet) {
  return api.getFile(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/download?company=${timesheet.company}`
  );
}

export function downloadValidatedTimesheet(timesheet, publicLink) {
  return api.getFile(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/${publicLink}/download?company=${timesheet.company}`
  );
}

export function uploadTimesheet(file, timesheet) {
  return api.postFile(
    file,
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/upload?company=${timesheet.company}`
  );
}

export function updateTemporaryTimesheetSigner(timesheet, email) {
  return api.put(
    `/timesheets/${timesheet.contractId}/${timesheet.timesheetId}/tempSigner?company=${timesheet.company}`,
    null,
    { email }
  );
}
