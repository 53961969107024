import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AssetsHistoryCard from '../components/assets/AssetHistoryCard';
import { fetchAssetHistory, LOANED, RETURNED } from '../redux/assets/actions';

function Assets() {
  const dispatch = useDispatch();
  const { isFetchingAssetHistory, triedFetchingAssetHistory } = useSelector((state) => state.system);
  const { selectedUser } = useSelector((state) => state.users);

  useEffect(() => {
    if (!isFetchingAssetHistory && !triedFetchingAssetHistory && selectedUser.myportalUserId != null) {
      dispatch(fetchAssetHistory(selectedUser.myportalUserId, 0, 20, LOANED));
      dispatch(fetchAssetHistory(selectedUser.myportalUserId, 0, 20, RETURNED));
    }
  }, [dispatch, isFetchingAssetHistory, triedFetchingAssetHistory, selectedUser.myportalUserId]);

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <AssetsHistoryCard />
        </div>
      </div>
    </div>
  );
}

export default Assets;
