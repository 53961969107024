import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentsListCard from '../components/documents/DocumentsListCard';
import { fetchDocuments } from '../redux/documents/actions';

function DocumentsPage() {
  const dispatch = useDispatch();
  const { isFetchingDocuments, triedFetchingDocuments } = useSelector((state) => state.system);
  const { tenant_id, candidate_type } = useSelector((state) => state.auth.user.profile);
  const { tenantId, userTypeId, managingOtherUser } = useSelector((state) => state.users.selectedUser);
  const { userTypes } = useSelector((state) => state.users);

  useEffect(() => {
    if (!isFetchingDocuments && !triedFetchingDocuments) {
      let userTypeIdMapped;
      // candidate_type is the string representation of a usertype (e.g. employee, freelancer, ..), so it needs to be converted to the corresponding id
      if (!managingOtherUser && userTypes) {
        userTypeIdMapped = userTypes.filter((userType) => candidate_type.includes(userType.name)).map((item) => item.id)[0];
      } else {
        // this.userTypeId from the selectedUser is already the id (no conversion needed)
        userTypeIdMapped = userTypeId;
      }
      dispatch(fetchDocuments(0, 20, managingOtherUser ? tenantId : tenant_id, userTypeIdMapped, true));
    }
  }, [dispatch, isFetchingDocuments, triedFetchingDocuments, tenantId, tenant_id, managingOtherUser, candidate_type, userTypes, userTypeId]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <DocumentsListCard />
        </div>
      </div>
    </div>
  );
}

export default DocumentsPage;
