import React, { memo } from 'react';
import Card from '../../common/Card';
import PropTypes from 'prop-types';

function NoContractsCard({ title, onlyActive }) {
  return (
    <Card title={title} icon="pe-7s-note2" iconGradient="bg-green-blue-top" contentClass="no-current-card">
      <div className="row-fluid">
        <div className="col-lg-12">
          <div>{`It looks like you don't have ${onlyActive ? 'an active contract' : 'a contract yet'}.`}</div>
          <div>
            If you think this is wrong,{' '}
            <a href="mailto:info@t4t.rocks?subject=No Contract?">please let us know →</a>
          </div>
        </div>
      </div>
    </Card>
  );
}

NoContractsCard.propTypes = {
  title: PropTypes.string.isRequired,
  onlyActive: PropTypes.bool.isRequired,
};

NoContractsCard.defaultProps = {
  onlyActive: false,
};

export default memo(NoContractsCard);
