import * as timesheetActions from '../timesheets/actions';

export default function pristineTimesheetReducer(state = PRISTINETIMESHEET_BASE_STATE, action) {
  switch (action.type) {
    case timesheetActions.ADD_ENTRY:
    case timesheetActions.REMOVE_ENTRY:
    case timesheetActions.SET_ENTRY_KEY:
    case timesheetActions.SET_ENTRY_REMARKS:
    case timesheetActions.SET_ENTRY_AMOUNT:
    case timesheetActions.SET_ENTRY_HOMEWORK:
    case timesheetActions.SET_ENTRY_PROJECTID:
    case timesheetActions.ADD_EXPENSE:
    case timesheetActions.REMOVE_EXPENSE:
    case timesheetActions.SET_EXPENSE_AMOUNT:
    case timesheetActions.SET_EXPENSE_KEY:
    case timesheetActions.SET_EXPENSE_REMARK:
    case timesheetActions.AUTOCOMPLETE_TIMESHEET:
      if (!state) {
        return action.timesheet;
      }

      return state;
    case timesheetActions.CANCEL_TIMESHEET_EDITING:
    case timesheetActions.SAVE_TIMESHEET:
    case timesheetActions.SUBMIT_TIMESHEET:
      return null;

    default:
      return state;
  }
}

export const PRISTINETIMESHEET_BASE_STATE = null;
