import {
    FETCH_TENANTS,
    FETCH_TENANTS_SUCCESS,
  } from './actions';
  
  export default function tenantsReducer(state = { ...TENANTS_BASE_STATE }, action) {
    switch (action.type) {
      case FETCH_TENANTS:
        return state;
  
      case FETCH_TENANTS_SUCCESS:
        return {
          ...state,
          tenants: action.tenants
        };  
  
      default:
        return state;
    }
  }
  
  export const TENANTS_BASE_STATE = {
    tenants: []
  };
  