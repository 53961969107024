export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const RESET_TIMESHEET_PROPS = 'RESET_TIMESHEET_PROPS';
export const SET_BRANDING_COLOR = 'SET_BRANDING_COLOR';

export function toggleMenu() {
  return { type: TOGGLE_MENU };
}

export function showModal(id) {
  return { type: SHOW_MODAL, id };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function resetTimesheetProps() {
  return { type: RESET_TIMESHEET_PROPS };
}

export function setBrandingColor(brandingColor) {
  return { type: SET_BRANDING_COLOR, brandingColor };
}
