import { GEOCODE_ADDRESS_SUCCESS, GEOCODE_ADDRESS_FAILED } from './actions';

export default function geoCodeReducer(state = { GEOCODE_BASE_STATE }, action) {
  switch (action.type) {
    case GEOCODE_ADDRESS_SUCCESS: {
      const location = state.locationList.find((location) => location.contractId === action.location.contractId);
      if (location) {
        return { ...state };
      } else {
        return {
          ...state,
          locationList: [
            ...state.locationList,
            {
              contractId: action.location.contractId,
              lat: action.location.lat,
              lng: action.location.lng,
            },
          ],
        };
      }
    }

    case GEOCODE_ADDRESS_FAILED: {
      const location = state.locationList.find((location) => location.contractId === action.contractId);
      if (location) {
        return { ...state };
      } else {
        return {
          ...state,
          locationList: [
            ...state.locationList,
            { contractId: action.contractId, address: action.address, error: action.err },
          ],
        };
      }
    }

    default:
      return state;
  }
}

export const GEOCODE_BASE_STATE = {
  locationList: [],
};
