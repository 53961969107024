import { getUserProfile as getProfile } from '../../api/usersApi';
import { showRequestFailedToast, showRequestFailedToastWith } from '../../service/toastService';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED';
export const FETCH_PROFILE_FOR_USER = 'FETCH_PROFILE_FOR_USER';
export const FETCH_PROFILE_FOR_USER_SUCCESS = 'FETCH_PROFILE_FOR_USER_SUCCESS';
export const FETCH_PROFILE_FOR_USER_FAILED = 'FETCH_PROFILE_FOR_USER_FAILED';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const fetchProfile = () => {
  return async function (dispatch, getState) {
    dispatch({ type: FETCH_PROFILE });
    try {
      const { data } = await getProfile();

      // Check if a DB profile was found, returns null for id if it wasn't
      if (data.id == null) {
        showRequestFailedToastWith({
          title: 'No DB profile found for user',
          text: 'Contact an administrator to link a profile.',
        });
      }
      const newProfile = { ...data };
      dispatch({ type: FETCH_PROFILE_SUCCESS, user: newProfile });
      if (!getState().profile.user || !getState().users.selectedUser.myportalUserId) {
        dispatch({ type: SET_SELECTED_USER, user: newProfile });
      }
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_PROFILE_FAILED, error });
    }
  };
};
