import * as systemActions from './actions';
import * as timesheetActions from '../timesheets/actions';
import * as contractActions from '../contracts/actions';
import * as documentActions from '../documents/actions';
import * as newsActions from '../news/actions';
import * as trackActions from '../usertracks/actions';
import * as userActions from '../users/actions';
import * as profileActions from '../profile/actions';
import * as transactionActions from '../transactions/actions';
import * as assetActions from '../assets/actions';
import * as geoCodeActions from '../geoCode/actions';
import * as tenantsActions from '../tenants/actions';
import * as coursesActions from '../courses/actions';

export default function systemReducer(state = { ...SYSTEM_BASE_STATE }, action) {
  switch (action.type) {
    case systemActions.TOGGLE_MENU:
      return { ...state, hideMenu: !state.hideMenu };

    case systemActions.SHOW_MODAL:
      return { ...state, isShowingModal: action.id };

    case systemActions.HIDE_MODAL:
      return { ...state, isShowingModal: null };

    case systemActions.RESET_TIMESHEET_PROPS:
      return { ...state, isFetchingTimesheet: false, triedFetchingTimesheet: false };

    case systemActions.SET_BRANDING_COLOR:
      return { ...state, brandingColor: action.brandingColor };

    case timesheetActions.FETCH_TIMESHEETS:
      return { ...state, isFetchingTimesheets: true, triedFetchingTimesheets: true };

    case timesheetActions.FETCH_TIMESHEETS_SUCCESS:
    case timesheetActions.FETCH_TIMESHEETS_FAILED:
      return { ...state, isFetchingTimesheets: false, timesheetUpdated: false };

    case timesheetActions.FETCH_TIMESHEET:
    case timesheetActions.FETCH_TIMESHEET_BY_DATE:
      return { ...state, isFetchingTimesheet: true, triedFetchingTimesheet: true };

    case timesheetActions.FETCH_TIMESHEET_SUCCESS:
    case timesheetActions.FETCH_TIMESHEET_BY_DATE_SUCCESS:
    case timesheetActions.FETCH_TIMESHEET_FAILED:
    case timesheetActions.FETCH_TIMESHEET_BY_DATE_FAILED:
      return { ...state, isFetchingTimesheet: false, timesheetUpdated: false };

    case geoCodeActions.GEOCODE_ADDRESS:
      return { ...state, isGeocodingAddress: true, triedGeocodingAddress: true };

    case geoCodeActions.GEOCODE_ADDRESS_SUCCESS:
    case geoCodeActions.GEOCODE_ADDRESS_FAILED:
      return { ...state, isGeocodingAddress: false };

    case contractActions.FETCH_CONTRACTS:
      return { ...state, isFetchingContracts: true, triedFetchingContracts: true };

    case contractActions.FETCH_CONTRACTS_SUCCESS:
    case contractActions.FETCH_CONTRACTS_FAILED:
      return { ...state, isFetchingContracts: false };

    case timesheetActions.DOWNLOAD_TIMESHEET:
      return { ...state, isDownloadingTimesheet: true };

    case timesheetActions.DOWNLOAD_TIMESHEET_SUCCESS:
    case timesheetActions.DOWNLOAD_TIMESHEET_FAILED:
      return { ...state, isDownloadingTimesheet: false };

    case timesheetActions.UPLOAD_TIMESHEET:
      return { ...state, isUploadingTimesheet: true };

    case timesheetActions.UPLOAD_TIMESHEET_SUCCESS:
    case timesheetActions.UPLOAD_TIMESHEET_FAILED:
      return { ...state, isUploadingTimesheet: false };

    case timesheetActions.SAVE_TIMESHEET:
      return { ...state, isSavingTimesheet: true };
    case timesheetActions.SUBMIT_TIMESHEET:
      return { ...state, isSubmittingTimesheet: true };

    case timesheetActions.SAVE_TIMESHEET_SUCCESS:
      return { ...state, isSavingTimesheet: false, timesheetUpdated: true };

    case timesheetActions.SUBMIT_TIMESHEET_SUCCESS:
      return { ...state, isSubmittingTimesheet: false, timesheetUpdated: true };
    case timesheetActions.SAVE_TIMESHEET_FAILED:
    case timesheetActions.SUBMIT_TIMESHEET_FAILED:
      return { ...state, isSavingTimesheet: false, isSubmittingTimesheet: false };

    case timesheetActions.FETCH_HOLIDAYS:
      return { ...state, isFetchingHolidays: true, triedFetchingHolidays: true };

    case timesheetActions.FETCH_HOLIDAYS_SUCCESS:
    case timesheetActions.FETCH_HOLIDAYS_FAILED:
      return { ...state, isFetchingHolidays: false };

    case documentActions.FETCH_DOCUMENTS:
      return { ...state, isFetchingDocuments: true, triedFetchingDocuments: true };

    case documentActions.FETCH_DOCUMENTS_SUCCESS:
    case documentActions.FETCH_DOCUMENTS_FAILED:
      return { ...state, isFetchingDocuments: false };

    case newsActions.FETCH_NEWS:
      return { ...state, isFetchingNews: true, triedFetchingNews: true };

    case newsActions.FETCH_NEWS_SUCCESS:
    case newsActions.FETCH_NEWS_FAILED:
      return { ...state, isFetchingNews: false };

    case newsActions.FETCH_NEWS_COUNT:
      return { ...state, isFetchingNewsCount: true, triedFetchingNewsCount: true };

    case newsActions.FETCH_NEWS_COUNT_SUCCESS:
    case newsActions.FETCH_NEWS_COUNT_FAILED:
      return { ...state, isFetchingNewsCount: false };

    case newsActions.FETCH_NEWS_ITEM:
      return { ...state, isFetchingNewsItem: true, triedFetchingNewsItem: true };

    case newsActions.FETCH_NEWS_ITEM_SUCCESS:
    case newsActions.FETCH_NEWS_ITEM_FAILED:
      return { ...state, isFetchingNewsItem: false };

    case newsActions.FETCH_UZURE_BLOB_URL:
      return { ...state, isFetchingAzureBlobUrl: true, triedFetchingAzureBlobUrl: true };

    case newsActions.FETCH_UZURE_BLOB_URL_SUCCESS:
    case newsActions.FETCH_UZURE_BLOB_URL_FAILED:
      return { ...state, isFetchingAzureBlobUrl: false };

    case timesheetActions.SHOW_EXPENSE_MODAL:
      return {
        ...state,
        dayIndex: action.dayIndex,
      };

    case trackActions.SHOW_TRACKS_MODAL:
      return {
        ...state,
        dayIndex: action.dayIndex,
      };

    case trackActions.FETCH_USER_TRACKS:
      return { ...state, isFetchingUserTracks: true, triedFetchingUserTracks: true };

    case trackActions.FETCH_USER_TRACKS_FAILED:
    case trackActions.FETCH_USER_TRACKS_SUCCESS:
      return { ...state, isFetchingUserTracks: false };

    case userActions.FETCH_USERS:
      return { ...state, isFetchingUsers: true, triedFetchingUsers: true };

    case userActions.FETCH_USERS_SUCCESS:
    case userActions.FETCH_USERS_FAILED:
      return { ...state, isFetchingUsers: false };
    case userActions.SELECT_USER:
      return {
        ...state,
        triedFetchingContracts: false,
        triedFetchingDocuments: false,
        triedFetchingTimesheets: false,
        triedFetchingTimesheet: false,
        triedFetchingProfile: false,
        triedFetchingUserTracks: false,
        triedFetchingTransactions: false,
        triedFetchingCredits: false,
        triedFetchingAssetHistory: false,
        triedGeocodingAddress: false,
        triedFetchingTenants: false,
      };

    case transactionActions.FETCH_TRANSACTIONS:
      return { ...state, isFetchingTransactions: true, triedFetchingTransactions: true };

    case transactionActions.FETCH_TRANSACTIONS_SUCCESS:
    case transactionActions.FETCH_TRANSACTIONS_FAILED:
      return { ...state, isFetchingTransactions: false };

    case transactionActions.FETCH_CREDITS:
      return { ...state, isFetchingCredits: true, triedFetchingCredits: true };

    case transactionActions.FETCH_CREDITS_SUCCESS:
    case transactionActions.FETCH_CREDITS_FAILED:
      return { ...state, isFetchingCredits: false };

    case assetActions.FETCH_ASSET_HISTORY:
      return { ...state, isFetchingAssetHistory: true, triedFetchingAssetHistory: true };

    case assetActions.FETCH_ASSET_HISTORY_SUCCESS:
      return { ...state, isFetchingAssetHistory: false };

    case profileActions.FETCH_PROFILE:
    case profileActions.FETCH_PROFILE_FOR_USER:
      return { ...state, isFetchingProfile: true, triedFetchingProfile: true };
    case profileActions.FETCH_PROFILE_SUCCESS:
    case profileActions.FETCH_PROFILE_FOR_USER_SUCCESS:
      return { ...state, isFetchingProfile: false, triedFetchingContracts: false };
    case profileActions.FETCH_PROFILE_FAILED:
    case profileActions.FETCH_PROFILE_FOR_USER_FAILED:
      return {
        ...state,
        isFetchingProfile: false,
        triedFetchingContracts: true,
        triedFetchingAssetHistory: true,
        triedFetchingCredits: true,
      };

    case tenantsActions.FETCH_TENANTS:
      return { ...state, isFetchingTenants: true, triedFetchingTenants: true };
    case tenantsActions.FETCH_TENANTS_SUCCESS:
      return { ...state, isFetchingTenants: false };

    case coursesActions.FETCH_COURSE_DATA:
      return { ...state, isFetchingCourses: true, triedFetchingCourses: true };
    case coursesActions.FETCH_COURSE_DATA_SUCCES:
      return { ...state, isFetchingCourses: false };

    case userActions.FETCH_USER_TYPES:
      return { ...state, isFetchingUserTypes: true, triedFetchingUserTypes: true };
    case userActions.FETCH_USER_TYPES_SUCCESS:
      return { ...state, isFetchingUserTypes: false };

    default:
      return state;
  }
}

export const SYSTEM_BASE_STATE = {
  // system
  brandingColor: '#0ad8b7',
  hideMenu: window.innerWidth < 768,

  // modal
  isShowingModal: null,
  dayIndex: null,

  // timesheets
  isFetchingTimesheet: false,
  triedFetchingTimesheet: false,
  isFetchingTimesheets: false,
  triedFetchingTimesheets: false,
  timesheetUpdated: false,
  isDownloadingTimesheet: false,
  isUploadingTimesheet: false,
  isSavingTimesheet: false,
  isSubmittingTimesheet: false,
  isFetchingHolidays: false,
  triedFetchingHolidays: false,

  // geocoding
  isGeocodingAddress: false,
  triedGeocodingAddress: false,

  // contracts
  isFetchingContracts: false,
  triedFetchingContracts: false,

  // documents
  isFetchingDocuments: false,
  triedFetchingDocuments: false,

  // news
  isFetchingNews: false,
  triedFetchingNews: false,
  isFetchingNewsCount: false,
  triedFetchingNewsCount: false,
  isFetchingNewsItem: false,
  triedFetchingNewsItem: false,
  isFetchingAzureBlobUrl: false,
  triedFetchingAzureBlobUrl: false,

  // usertracks
  isFetchingUserTracks: false,
  triedFetchingUserTracks: false,

  // users
  isFetchingUsers: false,
  triedFetchingUsers: false,
  isFetchingUserTypes: false,
  triedFetchingUserTypes: false,

  // transactions
  isFetchingTransactions: false,
  triedFetchingTransactions: false,
  isFetchingCredits: false,
  triedFetchingCredits: false,

  // profile
  isFetchingProfile: false,
  triedFetchingProfile: false,

  // assets
  isFetchingAssetHistory: false,
  triedFetchingAssetHistory: false,

  // tenants
  isFetchingTenants: false,
  triedFetchingTenants: false,

  // coursess:
  isFetchingCourses: false,
  triedFetchingCourses: false,
};
