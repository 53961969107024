import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Card from '../components/common/Card';
import Loading from '../components/common/Loading';

import InvalidLinkPage from '../components/validateTimesheet/InvalidLinkPage';
import ValidateTimesheetPage from '../components/validateTimesheet/ValidateTimesheetPage';
import { getPublicLink } from '../redux/validateTimesheets/actions';
import multibrandingService from '../service/multibrandingService';

function ValidateTimesheet() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { isFetching, timesheet, contract, publicLink, errorMessage } = useSelector(
    (state) => state.validateTimesheets
  );

  useEffect(() => {
    const link = search.substring(1);
    dispatch(getPublicLink(link));
  }, [dispatch, search]);

  useEffect(() => {
    if (!isFetching && timesheet != null && timesheet.company != null) {
      multibrandingService.applyBranding(timesheet.company);
    }
  }, [isFetching, timesheet]);

  if (isFetching || (timesheet == null && errorMessage == null)) {
    return (
      <div className="validation-page-wrapper">
        <div className="logo-position">
          <div className="validation-brand--logo"></div>
        </div>
        <Card
          title="Loading.."
          icon="pe-7s-copy-file"
          iconGradient="bg-red-fade"
          cardClass="validation-card"
          contentClass="center-x-y pb-3">
          <Loading message="Hang on, we're loading the timesheet." />
        </Card>
      </div>
    );
  }

  return errorMessage === null || errorMessage === undefined ? (
    <ValidateTimesheetPage timesheet={timesheet} contract={contract} publicLink={publicLink} />
  ) : (
    <InvalidLinkPage />
  );
}

export default ValidateTimesheet;
