import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

function RejectTimesheetModal({ id, rejectionReasonEntered }) {
  const [rejectionReason, setRejectionReason] = useState('');

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title="Please specify the reason for rejection"
      contentClass="modal-body-center-h"
      confirmText="Reject Timesheet"
      confirmButtonDisabled={!rejectionReason.length}
      backdrop
      confirmHandler={() => rejectionReasonEntered(rejectionReason)}>
      <div className="row">
        <div className="col">
          <textarea
            type="text"
            className="form-control text-area"
            placeholder="Reason"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            rows={10}
            cols={50}
            />
        </div>
      </div>
    </Modal>
  );
}

RejectTimesheetModal.propTypes = {
  id: PropTypes.any.isRequired,
  rejectionReasonEntered: PropTypes.func,
};

export default memo(RejectTimesheetModal);
