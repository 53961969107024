import propTypes from 'prop-types';
import React, { useMemo } from 'react';

function TimePerDescription({ days, rates, standardHoursPerDay, className }) {
    const mappedDays = useMemo(
        () =>
            days.flatMap((c) =>
                c.entries
                    .filter((d) => d.amount != null)
                    .flatMap((d) => [
                        {
                            key: d.type ? d.type.key : '',
                            amount: d.amount,
                        },
                    ])
            ),
        [days]
    );

    const totalHours = useMemo(() => {
        const total = mappedDays.reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0);
        return total !== null ? total.toFixed(2) : null;
    }, [mappedDays]);

    const mappedRates = useMemo(
        () =>
            rates.map((rate) => {
                const totalPerRate = mappedDays.reduce((a, b) => {
                    if (rate.key === b.key) {
                        return parseFloat(a) + parseFloat(b.amount);
                    } else {
                        return parseFloat(a);
                    }
                }, 0);

                const formattedTotalPerRate = totalPerRate !== null ? totalPerRate.toFixed(2) : null;

                if (formattedTotalPerRate > 0) {
                    return (
                        <div className="row" key={`${rate.name}`}>
                            <div className="col-sm-5">{rate.name}:</div>
                            <div className="col-sm-4">{formattedTotalPerRate} h</div>
                            <div className="col-sm-3 text-right">
                                {parseFloat(formattedTotalPerRate / standardHoursPerDay).toFixed(2)} d
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            }),
        [standardHoursPerDay, mappedDays, rates]
    );

    return (
        <div>
            <label
                className={`dark-shade text-uppercase ${className || ''}`}
                data-toggle="collapse"
                data-target="#collapseHours">
                Time Per Description
            </label>
            {mappedRates}
            <div className="row mb-1 font-weight-bold">
                <div className="col-sm-5">Total time:</div>
                <div className="col-sm-4">{totalHours} h</div>
                <div className="col-sm-3 text-right">{parseFloat(totalHours / standardHoursPerDay).toFixed(2)} d</div>
            </div>
        </div>
    );
}

TimePerDescription.propTypes = {
    rates: propTypes.array.isRequired,
    days: propTypes.array.isRequired,
    standardHoursPerDay: propTypes.number.isRequired,
    className: propTypes.string,
};

export default TimePerDescription;
