import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import LoadingCard from '../common/LoadingCard';

function CreateTimesheet() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId, company, year, month } = useParams();
  const timesheets = useSelector((state) => state.timesheets.timesheetList);
  const { nameToDisplay } = useSelector((state) => state.users);

  const timesheet = useMemo(
    () =>
      timesheets.find((ts) => {
        const startDate = moment(ts.start);

        return (
          startDate.month() + 1 == month &&
          startDate.year() == year &&
          ts.contractId == contractId &&
          ts.company == company
        );
      }),
    [company, contractId, month, timesheets, year]
  );

  useEffect(() => {
    if (timesheet != null) {
      history.push(`/timesheets/edit/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}`);
    }
  }, [dispatch, history, timesheet]);

  return (
    <div className="col-lg-12">
      <LoadingCard
        title={`${nameToDisplay} Timesheet`}
        message="Hang on, we're loading your timesheet."
        icon="pe-7s-copy-file"
        iconGradient="bg-green-blue-top"
      />
    </div>
  );
}

export default CreateTimesheet;
