import React from 'react';
import NewsListCard from '../../components/news/NewsListCard';

export const News = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <NewsListCard />
        </div>
      </div>
    </div>
  )
}
