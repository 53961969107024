import PropTypes from 'prop-types';
import React, { memo } from 'react';

function VerticalTimeline({ children }) {
  return <div className="vertical-timeline">{children}</div>;
}

VerticalTimeline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.objectOf(PropTypes.node)]),
};

export default memo(VerticalTimeline);
