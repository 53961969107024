import React from 'react';
import userManager, { normalizeUrl } from '../service/userManager';
import { AuthProvider } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { dispatchSetUser } from '../redux/auth/actions';


const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const handleOnSignIn = user => {
    dispatch(dispatchSetUser(user));
    normalizeUrl();
  };

  return (
    <AuthProvider userManager={userManager} onSigninCallback={handleOnSignIn}>
      {children}
    </AuthProvider>
  );
};


export default AuthWrapper;
