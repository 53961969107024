import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deleteCourseEnrollment, getCourseEnrollmentsFromUser, getCourseReviewsFromCourse } from '../../api/coursesApi';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../common/Card';
import Loading from '../common/Loading';
import Table from '../common/Table';
import { showModal } from '../../redux/system/actions';
import CreateCourseReviewModal from '../common/modals/CreateCourseReviewModal';
import Tabs from '../common/Tabs';
import Tab from '../common/Tab';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getSuccesToastPropsBy } from '../../service/toastService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ProofOfCompletionModal from '../common/modals/ProofOfCompletionModal';
import EditRequestedEnrollmentModal from '../common/modals/EditRequestedEnrollmentModal';
import ViewDenyCourseEnrollmentRequest from '../common/modals/ViewDenyCourseEnrollmentRequest';

const CREATE_TS_MODAL_ID = 'createTS';
const CREATE_TS_MODAL_ID2 = 'createTS2';
const EDIT_TS_MODAL_ID = 'editTS';
const VIEW_TS_MODAL_ID = 'viewTS';

const MyEnrollmentsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);
  const [loading, setLoading] = useState(true);
  const [courseEnrollment, setCourseEnrollment] = useState({});
  const [courseEnrollments, setCourseEnrollments] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = useCallback((modalId, course) => dispatch(showModal(modalId, course)), [dispatch]);

  const fetchEnrollments = useCallback(
    async (status) => {
      setLoading(true);
      try {
        const enrollments = await getCourseEnrollmentsFromUser(currentLoggedUserId);
        const reviewObservables = enrollments.data.map((enrollment) => getCourseReviewsFromCourse(enrollment.course.id));

        if (reviewObservables.length === 0) {
          setReviews([]);
          setCourseEnrollments(enrollments.data);
          setLoading(false);
        } else {
          try {
            const reviewsData = await Promise.all(reviewObservables);
            const reviews = reviewsData.flatMap((review) => review.data);
            setReviews(reviews);
            const filteredEnrollments = enrollments.data.filter((enrollment) => {
              if (status === 0) return true;
              if (status === 1) return enrollment.status === 'Active';
              if (status === 2) return enrollment.status === 'Requested';
              if (status === 3) return enrollment.status === 'Finished';
              return false;
            });

            filteredEnrollments.sort((a, b) => new Date(b.dateOfEnrollment) - new Date(a.dateOfEnrollment));
            setCourseEnrollments(filteredEnrollments);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            throw new Error('Error fetching course reviews');
          }
        }
      } catch (error) {
        setLoading(false);
        throw new Error('Error fetching course enrollments');
      }
    },
    [currentLoggedUserId]
  );

  useEffect(() => {
    fetchEnrollments(selectedTab);
  }, [fetchEnrollments, selectedTab]);

  const handleConfirm = (confirmed) => {
    setIsOpen(false);
    if (confirmed) {
      deleteEnrollment(courseEnrollment);
    }
  };

  const updateReviewedStatus = useCallback(() => {
    setCourseEnrollments((enrollments) =>
      enrollments.map((courseEnrollment) => {
        const hasReview = reviews.some((review) => review.courseId === courseEnrollment.course.id && review.user.id === currentLoggedUserId);
        let reviewedStatus = 'Done';
        if (courseEnrollment.status === 'Requested') {
          reviewedStatus = 'Not applicable';
        } else if (!hasReview) {
          reviewedStatus = 'Not done';
        }
        return { ...courseEnrollment, reviewed: reviewedStatus };
      })
    );
  }, [currentLoggedUserId, reviews]);

  useEffect(() => {
    updateReviewedStatus();
  }, [reviews, updateReviewedStatus]); // Update reviewed status whenever reviews change

  const handleChangeTab = useCallback((selectedTab) => {
    setSelectedTab(selectedTab);
  }, []);

  const deleteEnrollment = async (courseEnrollment) => {
    try {
      await deleteCourseEnrollment(courseEnrollment.id);
      toast.success('You have successfully deleted your enrollment', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      fetchEnrollments(0);
    } catch (error) {
      throw new Error('Something went wrong!!!');
    }
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }, []);

  const columns = useMemo(
    () => (
      <>
        <th className="col-xs-1">#</th>
        <th className="col-xs-2">Course</th>
        <th className="col-xs-2">Startdate</th>
        <th className="col-xs-2" style={{ whiteSpace: 'nowrap' }}>
          Date of enrollment/request
        </th>
        <th className="col-xs-2">Status</th>
        <th className="col-xs-2">Reviewed</th>
        <th className="col-xs-2">Actions</th>
      </>
    ),
    []
  );

  const filteredEnrollments = useMemo(() => {
    return courseEnrollments.filter((enrollment) => {
      if (selectedTab === 0) return true;
      if (selectedTab === 1) return enrollment.status === 'Active';
      if (selectedTab === 2) return enrollment.status === 'Requested';
      if (selectedTab === 3) return enrollment.status === 'Finished';
      return false;
    });
  }, [courseEnrollments, selectedTab]);

  const navigateDetailPage = (courseEnrollment) => {
    history.push({
      pathname: `/courses/detail/${courseEnrollment.course.id}`,
      state: { course: courseEnrollment.course },
    });
  };

  const mappedCourseEnrollments = useMemo(
    () =>
      filteredEnrollments.map((courseEnrollment, index) => (
        <tr key={index} className="clickable" style={{ maxHeight: 46 }} onClick={() => navigateDetailPage(courseEnrollment)}>
          <td className="user-name" style={{ verticalAlign: 'middle' }}>
            {index + 1}
          </td>
          <td className="user-name" style={{ verticalAlign: 'middle', maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {courseEnrollment.course.name}
          </td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            {courseEnrollment.course.selfStudyCourse ? 'Not applicable (self study course)' : formatDate(courseEnrollment.course.startDate)}
          </td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{formatDate(courseEnrollment.dateOfEnrollment)}</td>
          <td className="user-name" style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            {courseEnrollment.status === 'Active' ? (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-green-blue">
                {courseEnrollment.status}
              </div>
            ) : courseEnrollment.status === 'Finished' ? (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-purple-red">
                {courseEnrollment.status}
              </div>
            ) : courseEnrollment.status === 'Denied' ? (
              // <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-red-fade">
              //   {courseEnrollment.status}
              // </div>
              <div style={{ margin: 0 }}>
                <span style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-red-fade mr-2">
                  {courseEnrollment.status}
                </span>
                <span style={{ margin: 0 }}>
                  <i
                    onClick={(event) => {
                      event.stopPropagation();
                      setCourseEnrollment(courseEnrollment);
                      handleShowModal(VIEW_TS_MODAL_ID, courseEnrollment);
                    }}
                    style={{ margin: 0, verticalAlign: 'middle' }}
                    className="pe-7s-info icon-gradient bg-red-fade"
                  />
                </span>
              </div>
            ) : courseEnrollment.status === 'Rejected' ? (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-red-fade">
                {courseEnrollment.status}
              </div>
            ) : (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-yellow-fade">
                {courseEnrollment.status}
              </div>
            )}
          </td>
          <td className="user-name" style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            {courseEnrollment.reviewed === 'Done' ? (
              <div className="flex-mycours">
                <i style={{ fontSize: 20 }} className="pe-7s-check text-success"></i>
                <span className="ml-2">Done</span>
              </div>
            ) : courseEnrollment.reviewed === 'Not applicable' || courseEnrollment.status !== 'Finished' ? (
              <>
                <span>Not applicable</span>
              </>
            ) : (
              <div className="flex-mycours">
                <i style={{ fontSize: 20 }} className="pe-7s-attention text-warning"></i>
                <span className="ml-2">Not done</span>
              </div>
            )}
          </td>
          <td className="user-name" style={{ verticalAlign: 'middle' }}>
            {courseEnrollment.status === 'Requested' ? (
              <>
                <i
                  onClick={(event) => {
                    event.stopPropagation();
                    setCourseEnrollment(courseEnrollment);
                    handleShowModal(EDIT_TS_MODAL_ID, courseEnrollment);
                  }}
                  style={{ margin: 0 }}
                  title="Edit enrollment"
                  className={`pe-7s-pen icon-gradient bg-green-blue-top`}
                />
                <i
                  style={{ margin: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(true);
                    setCourseEnrollment(courseEnrollment);
                  }}
                  title="Delete enrollment"
                  className={`pe-7s-trash icon-gradient bg-red-fade pl-2`}></i>
              </>
            ) : courseEnrollment.status === 'Finished' && courseEnrollment.reviewed !== 'Done' ? (
              <>
                <i
                  style={{ fontSize: 20 }}
                  className="pe-7s-comment feedback-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCourseEnrollment(courseEnrollment);
                    handleShowModal(CREATE_TS_MODAL_ID, courseEnrollment);
                  }}></i>
              </>
            ) : courseEnrollment.status === 'Active' || courseEnrollment.status === 'Rejected' ? (
              <div style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                <i
                  style={{ fontSize: 24, margin: 0 }}
                  className="pe-7s-cloud-upload feedback-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCourseEnrollment(courseEnrollment);
                    handleShowModal(CREATE_TS_MODAL_ID2);
                  }}></i>
              </div>
            ) : (
              <>None</>
            )}
          </td>
        </tr>
      )),
    [filteredEnrollments, formatDate, handleShowModal]
  );

  const handleRefreshEnrollments = useCallback(() => {
    fetchEnrollments(selectedTab);
  }, [fetchEnrollments, selectedTab]);

  return (
    <div className="container-fluid" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#ffffff' }}>
      <div className="mb-2">
        <Tabs value={selectedTab} onChange={(_, tabValue) => handleChangeTab(tabValue)} TabIndicatorProps={{ style: { background: 'var(--accent-color)' } }}>
          <Tab label="All" />
          <Tab label="Active enrollments" />
          <Tab label="Requested enrollments" />
          <Tab label="Finished enrollments" />
        </Tabs>
      </div>

      <Card title="My enrollments" icon="pe-7s-notebook" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
        {loading ? (
          <Loading message="Hang on, we're loading your enrollments." className="mt-5" />
        ) : (
          <>
            {filteredEnrollments.length === 0 ? (
              <div className="mt-5 text-center">
                <h4>No enrollments found</h4>
                <p style={{ color: '#999' }}>You are not enrolled in any courses yet.</p>
              </div>
            ) : (
              <>
                <CreateCourseReviewModal id={CREATE_TS_MODAL_ID} courseEnrollment={courseEnrollment} onReviewSubmit={handleRefreshEnrollments} />
                <ProofOfCompletionModal id={CREATE_TS_MODAL_ID2} courseEnrollment={courseEnrollment} onSubmit={handleRefreshEnrollments} />
                <EditRequestedEnrollmentModal id={EDIT_TS_MODAL_ID} courseEnrollment={courseEnrollment} onSubmit={handleRefreshEnrollments} />
                <ViewDenyCourseEnrollmentRequest id={VIEW_TS_MODAL_ID} courseEnrollment={courseEnrollment} />
                <Table columns={columns} data={mappedCourseEnrollments} showPagination={false} />
              </>
            )}
          </>
        )}
      </Card>

      {isOpen && (
        <div className="confirmation-overlay modal backdrop-modal">
          <div className="confirmation-popup">
            <p className="">Are you sure?</p>
            <div className="button-container">
              <button className="btn btn-secondary" onClick={() => handleConfirm(false)}>
                No
              </button>
              <button className="btn btn-primary" onClick={() => handleConfirm(true)}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .confirmation-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
          text-align: center;
        }

        .btn {
          margin: 0 5px;
        }

        .confirmation-popup {
          background-color: #252525;
          padding: 20px;
        }

        .button-container {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default MyEnrollmentsPage;
