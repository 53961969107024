import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage';
import system from './system/reducers';
import timesheets from './timesheets/reducers';
import validateTimesheets from './validateTimesheets/reducers';
import usertracks from './usertracks/reducers';
import contracts from './contracts/reducers';
import pristineTimesheet from './pristineTimesheet/reducers';
import documents from './documents/reducers';
import news from './news/reducers';
import users from './users/reducers';
import assets from './assets/reducers';
import profile from './profile/reducers';
import transactions from './transactions/reducers';
import geoCode from './geoCode/reducers';
import projects from './projects/reducers';
import tenants from './tenants/reducers';
import auth from './auth/reducers';
import courses from './courses/reducers';

const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['users'],
};

const rootReducer = combineReducers({
  system: system,
  timesheets,
  validateTimesheets,
  usertracks,
  contracts,
  documents,
  news,
  users,
  assets,
  profile,
  pristineTimesheet,
  transactions,
  geoCode,
  projects,
  tenants,
  auth,
  courses,
});

export default persistReducer(rootPersistConfig, rootReducer);
