import {
  FETCH_USER_TRACKS,
  FETCH_USER_TRACKS_SUCCESS,
  FETCH_USER_TRACKS_FAILED,
  UPDATE_MILESTONE_STATUS,
  UPDATE_MILESTONE_STATUS_SUCCESS,
  UPDATE_MILESTONE_STATUS_FAILED,
  UPDATE_SELECTED_MILESTONES,
  UPDATE_SELECTED_MILESTONES_SUCCESS,
  UPDATE_SELECTED_MILESTONES_FAILED,
  DELETE_USERTRACK,
  DELETE_USERTRACK_SUCCESS,
  DELETE_USERTRACK_FAILED,
  COMPLETE_USERTRACK_FAILED,
  COMPLETE_USERTRACK,
  COMPLETE_USERTRACK_SUCCESS,
  UPDATE_COMPLETION_DATE,
  UPDATE_COMPLETION_DATE_FAILED,
  UPDATE_COMPLETION_DATE_SUCCESS,
} from './actions';

export default function tracksReducer(state = { ...USERTRACKS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_USER_TRACKS:
    case COMPLETE_USERTRACK:
    case UPDATE_COMPLETION_DATE:
    case UPDATE_SELECTED_MILESTONES:
    case FETCH_USER_TRACKS_FAILED:
    case DELETE_USERTRACK:
    case DELETE_USERTRACK_SUCCESS:
    case DELETE_USERTRACK_FAILED:
    case UPDATE_SELECTED_MILESTONES_FAILED:
    case UPDATE_MILESTONE_STATUS_FAILED:
    case COMPLETE_USERTRACK_FAILED:
    case UPDATE_COMPLETION_DATE_FAILED:
      return { ...state };

    case FETCH_USER_TRACKS_SUCCESS:
      return {
        usertracksList: [...action.usertracks],
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
      };

    case UPDATE_MILESTONE_STATUS:
      return {
        ...state,
        usertracksList: state.usertracksList.map((track) => {
          if (track.trackId === action.userTrack.trackId) {
            return {
              ...track,
              selectedMilestones: track.selectedMilestones.map((milestone) => {
                if (milestone.milestoneId === action.milestone.milestoneId) {
                  return {
                    ...milestone,
                    status: action.milestone.status,
                  };
                }
                return milestone;
              }),
            };
          }
          return track;
        }),
      };

    case UPDATE_MILESTONE_STATUS_SUCCESS:
      return {
        ...state,
        usertracksList: state.usertracksList.map((track) => {
          if (track.trackId === action.userTrack.trackId) {
            return {
              ...track,
              trackStatus: action.userTrack.trackStatus,
              selectedMilestones: action.userTrack.selectedMilestones,
              completionDate: action.userTrack.completionDate,
            };
          }
          return track;
        }),
      };

    case COMPLETE_USERTRACK_SUCCESS:
      return {
        ...state,
        usertracksList: state.usertracksList.map((track) => {
          if (track.trackId === action.userTrack.trackId) {
            return {
              ...track,
              trackStatus: action.userTrack.trackStatus,
              completionDate: action.completionDate,
              selectedMilestones: action.milestones,
            };
          }
          return track;
        }),
      };

    case UPDATE_SELECTED_MILESTONES_SUCCESS:
      return {
        ...state,
        usertracksList: state.usertracksList.map((track) => {
          if (track.trackId === action.userTrackId) {
            return {
              ...track,
              completionDate: action.userTrack.completionDate,
              trackStatus: action.userTrack.status,
              selectedMilestones: action.selectedMilestones,
            };
          }
          return track;
        }),
      };


    case UPDATE_COMPLETION_DATE_SUCCESS:
      return {
        ...state,
        usertracksList: state.usertracksList.map((track) => {
          if (track.trackId === action.userTrackId) {
            return {
              ...track,
              completionDate: action.completionDateUserTrack,
              selectedMilestones: track.selectedMilestones.map((milestone) => {
                if (milestone.milestoneId === action.milestone.milestoneId) {
                  return {
                    ...milestone,
                    completionDate: action.milestone.completionDate,
                  };
                }
                return milestone;
              }),
            };
          }
          return track;
        }),
      };

    default:
      return state;
  }
}

export const USERTRACKS_BASE_STATE = {
  usertracksList: [],
  skip: null,
  take: null,
  totalCount: null,
  errorMessage: null,
};
