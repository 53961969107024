import faviconT4T from '../assets/images/favicon-t4t.ico';
import faviconTiT from '../assets/images/favicon-tit.ico';
import faviconT2T from '../assets/images/Talent2Test-favicon.png';
import faviconCT from '../assets/images/favicon-ct.ico';
import { TALENT2TEST, TALENTIT, TEAM4TALENT, CYBERTALENT } from '../constants/companies';

class MultibrandingService {
  applyBranding(company) {
    document.title = `My${company}`;

    switch (company) {
      case TALENTIT:
        import('../assets/css/branding/talentit.css');
        replaceFavicon(faviconTiT);
        return '#ff5500';
      case TALENT2TEST:
        import('../assets/css/branding/talent2test.css');
        replaceFavicon(faviconT2T);
        return '#58B0E3';
      case CYBERTALENT:
        import('../assets/css/branding/cybertalent.css');
        replaceFavicon(faviconCT);
        return '#5100DF';
      case TEAM4TALENT:
      default:
        import('../assets/css/branding/team4talent.css');
        replaceFavicon(faviconT4T);
        return '#0ad8b7';
    }
  }
}

function replaceFavicon(src) {
  const linkTag = document.createElement('link');
  linkTag.rel = 'shortcut icon';
  linkTag.href = src;

  document.getElementsByTagName('head')[0].appendChild(linkTag);
}

export default new MultibrandingService();
