import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ toggle, handleToggleChange, icon, text }) => {
  return (
    <>
      <div className="toggle">
        <div className="toggle-container" onClick={handleToggleChange}>
          <a>
            <div className={`toggle-btn ${!toggle ? 'toggle-disable' : ''}`}>{icon}</div>
          </a>
        </div>
        <div className="toggle-txt">{text}</div>
      </div>
    </>
  );
};

Toggle.propTypes = {
  toggle: PropTypes.any,
  handleToggleChange: PropTypes.any,
  icon: PropTypes.any,
  text: PropTypes.string,
};

export default Toggle;
