import api from './baseApi';

export function getUserPushNotifications() {
  return api.get('/Notifications/user-push-notifications');
}

export function getPushNotifications() {
  return api.get('/Notifications/push-notifications');
}

export function updateUserPushNotifications(userpushNotification) {
  return api.put('/Notifications/user-push-notifications', userpushNotification);
}

export function getPushNotificationsById(notificationId) {
  return api.get(`/Notifications/push-notifications/${notificationId}`);
}
