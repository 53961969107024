import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo } from 'react';
import Avatar from '../../common/Avatar';
import { useDispatch } from 'react-redux';

function ContractListRowDetails({ contract }) {
  const { client, accountManager, job } = contract;
  const { address: clientAddress } = client;
  const dispatch = useDispatch();

  const addressAsString = useMemo(
    () =>
      `${clientAddress.streetName} ${clientAddress.number}, ${clientAddress.zipcode} ${clientAddress.city} ${clientAddress.country}`,
    [clientAddress.city, clientAddress.country, clientAddress.number, clientAddress.streetName, clientAddress.zipcode]
  );

  useEffect(() => {
  }, [addressAsString, contract.contractId, dispatch]);

  const accManagerPhotoComponent = useMemo(
    () =>
      accountManager && accountManager.photo ? (
        <img src={accountManager.photo} className="navbar-avatar" />
      ) : accountManager && accountManager.name ? (
        <Avatar name={accountManager.name} className="navbar-avatar" />
      ) :
        <Avatar name={`X`} className="navbar-avatar" />,
    [accountManager]
  );

  return (
    <div className="row">
      <div className="col-sm-6">
        <h5>Contact Details</h5>
        <hr />
        <div>
          Contact Person: <label className="dark-shade">{client.contactPerson}</label>
        </div>
        <div>
          Timesheet Signer: <label className="dark-shade">{client.timesheetSigner}</label>
        </div>
        <h5>Account Manager</h5>
        <hr />
        <div className="d-flex" style={{ flexDirection: 'column' }}>
          <div className="flex-center-v acc-manager">
            {accManagerPhotoComponent}
            <label className="dark-shade acc-manager--name"> {(accountManager.name) ? accountManager.name : 'No name found'}</label>
          </div>

          <div style={{ flex: 1 }}>
            <div className="flex-center-v">
              <i className="pe-7s-phone acc-manager-icon" />{' '}
              <a href={(accountManager.phone) ? `tel:${accountManager.phone}` : undefined}>{(accountManager.phone) ? accountManager.phone: 'No number found'}</a>
            </div>
            <div className="flex-center-v">
              <i className="pe-7s-mail acc-manager-icon" />{' '}
              <a href={(accountManager.email) ? `mailto:${accountManager.email}` : undefined}>{(accountManager.email) ? accountManager.email : 'No email found'}</a>
            </div>{' '}
          </div>
        </div>
      </div>
      <div className="col-sm-6 d-flex flex-column">
        <div>
          <h5>Location</h5>
          <hr />
          Address: <label className="dark-shade">{addressAsString}</label>
        </div>
        <h5>Job Details</h5>
        <hr />
        <div>
          Title: <label className="dark-shade">{(job.name) ? job.name : 'No title found'}</label>
        </div>
        {/* {job.description != null && (
          <div>
            Description: <label className="dark-shade">{(job.description) ? job.description : 'No description found'}</label>
          </div>
        )} */}
        {/* <div className="contract-gmaps-container">{mapComponent}</div> */}
      </div>
    </div>
  );
}

ContractListRowDetails.propTypes = {
  contract: PropTypes.object.isRequired,
};

export default memo(ContractListRowDetails);
