//add "data-decimal={#number}" in the input, #number = count decimal
export function enforceDecimal(event, element){
    const decimal = parseInt(element.dataset.decimal) || 0;
    const val = event.target.value;
    if(decimal > 0){
      const splitVal = val.split('.');
      if(splitVal.length === 2 && splitVal[1].length > decimal){
        event.target.value = splitVal[0] + '.' + splitVal[1].substr(0, decimal);
      }
    } else if (decimal === 0){
      const splitVal = val.split('.');
      if(splitVal.length > 1){
        event.target.value = splitVal[0];
      }
    }
  }