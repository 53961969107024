import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';
import { deleteDocument, uploadDocument } from '../../../api/documentsApi';

function AddAttachmentCourseModal({ id, certificate, attachments, certificateAdded, onAttachmentAdded, onSubmitCertificate }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // This is for certificate
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [deletedFile, setDeletedFile] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [fileKey, setFileKey] = useState(0);

  useEffect(() => {
    setFileKey((prevKey) => prevKey + 1);
  }, [selectedFiles]);

  useEffect(() => {
    setChangesMade(false);
    if (certificateAdded) {
      setSelectedFile(certificateAdded);
    }
  }, [certificateAdded]);

  useEffect(() => {
    setChangesMade(false);
    if (attachments && attachments.length > 0) {
      setSelectedFiles(attachments);
    }
  }, [attachments]);

  const handleFileChangeCertificate = (event) => {
    const file = event.target.files[0];

    const allowedFormats = ['pdf'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension && allowedFormats.includes(fileExtension)) {
      setSelectedFile(event.target.files[0]);
    } else {
      toast.error(`Unsupported file format: ${file.name}`, getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = [];

    // Iterate over the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const allowedFormats = ['pdf', 'docx', 'doc', 'docm', 'xlsx'];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension && allowedFormats.includes(fileExtension)) {
        const isUploaded = selectedFiles.some((existingFile) => existingFile.name === file.name);

        if (!isUploaded) {
          newFiles.push(file);
          setChangesMade(true);
        } else {
          toast.error(`File ${file.name} is already uploaded`, getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
        }
      } else {
        toast.error(`Unsupported file format: ${file.name}`, getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      }
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const closeModal = () => {
    setSelectedFiles(attachments);

    setSelectedFile(certificateAdded);
    setDeletedFiles([]);
    setChangesMade(false);
  };

  const isConfirmButtonDisabled = () => {
    return !changesMade && !selectedFile;
  };

  const getContentType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return extension;
  };

  const handleCertificate = async () => {
    try {
      if (deletedFile) {
        await deleteDocument(deletedFile.id);
        setSelectedFile(null);
        onSubmitCertificate(null);
        setDeletedFile(null);

        toast.success('You have successfully deleted your certificate', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);
      if (selectedFile) {
        const result = await uploadDocument({
          name: selectedFile.name,
          contentType: getContentType(selectedFile.name),
          formData: formData,
        });

        toast.success('You have successfully saved your certificate', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

        if (onSubmitCertificate) {
          onSubmitCertificate(result.data);
        }
      }
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const handleAttachment = async () => {
    try {
      const filteredDeletedFiles = deletedFiles.filter((deletedFile) => !selectedFiles.some((selectedFile) => selectedFile.name === deletedFile.name));

      if (deletedFiles.length > 0) {
        await Promise.all(
          filteredDeletedFiles.map(async (file) => {
            if (!file.id) {
              return;
            }

            await deleteDocument(file.id);
          })
        );

        if (selectedFiles.length === 0) {
          setSelectedFiles([]);
        }

        onAttachmentAdded(selectedFiles);

        toast.success('File successfully deleted', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

        setDeletedFiles([]);
        setChangesMade(false);

        return;
      }

      const results = await Promise.all(
        selectedFiles.map(async (file) => {
          // i need to check if the file is already uploaded
          if (file.id) {
            return;
          }

          const formData = new FormData();
          formData.append('file', file);

          const result = await uploadDocument({
            name: file.name,
            contentType: file.type,
            formData: formData,
          });
          return result;
        })
      );

      const uploadedFiles = results.filter((result) => result !== undefined && result !== null).map((result) => result.data);
      const allFiles = [...attachments, ...uploadedFiles];

      setDeletedFiles([]);

      onAttachmentAdded(allFiles);

      setChangesMade(false);
      toast.success('You have successfully added your attachments', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const removeFile = (fileToRemove) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileToRemove.name));
    setDeletedFiles([...deletedFiles, fileToRemove]);

    setChangesMade(true);
  };

  const removeCertificate = () => {
    setSelectedFile(null);
    setDeletedFile(selectedFile);
    setChangesMade(true);
  };

  return (
    <Modal
      id={id}
      icon="pe-7s-file"
      iconGradient="bg-green-blue-top"
      title={certificate ? 'Certificate' : 'Attachments'}
      contentClass="modal-body-center-h"
      confirmText="SAVE"
      closeHandler={closeModal}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={certificate ? handleCertificate : handleAttachment}>
      <div style={{ width: '100%' }}>
        <label
          htmlFor="fileInput"
          className="form-control"
          style={{ height: '200px', width: '100%', position: 'relative', overflow: 'hidden', display: 'block', cursor: 'pointer' }}>
          {certificate ? (
            <input
              key={fileKey}
              type="file"
              id="fileInput"
              className="form-control"
              onChange={handleFileChangeCertificate}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
              accept=".pdf"
            />
          ) : (
            <input
              key={fileKey}
              type="file"
              id="fileInput"
              className="form-control"
              onChange={handleFileChange}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
              multiple
              accept=".pdf, .docx, .doc, .docm, .xlsx"
            />
          )}

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <i className="fas fa-cloud-upload-alt" style={{ fontSize: '2rem' }}></i>
            <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Click here to upload files</p>
            {certificate ? (
              <p style={{ fontSize: '0.8rem', color: '#999' }}>Supported formats: PDF</p>
            ) : (
              <p style={{ fontSize: '0.8rem', color: '#999' }}>Supported formats: PDF, DOCX, DOC, DOCM, XLSX</p>
            )}
          </div>
        </label>

        {certificate ? (
          selectedFile ? (
            <div style={{ height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
                <i className="fa fa-file" style={{ marginRight: '5px' }} />
                <p style={{ margin: 0 }}>{selectedFile.name}</p>
              </div>
              <div>
                <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => removeCertificate()} />
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: '15px',
                marginBottom: '10px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                textAlign: 'left',
              }}>
              <i className="fa fa-file" style={{ marginRight: '5px' }} />
              <p style={{ margin: 0 }}>No file selected</p>
            </div>
          )
        ) : selectedFiles.length > 0 ? (
          <div style={{ marginTop: '15px' }}>
            {selectedFiles.map((file, index) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }} key={index}>
                <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
                  <i className="fa fa-file" style={{ marginRight: '5px' }} />
                  <p style={{ margin: 0 }}>{file.name}</p>
                </div>
                <div>
                  <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => removeFile(file)} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{
              marginTop: '15px',
              marginBottom: '10px',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
            }}>
            <i className="fa fa-file" style={{ marginRight: '5px' }} />
            <p style={{ margin: 0 }}>No files selected</p>
          </div>
        )}
      </div>
    </Modal>
  );
}

AddAttachmentCourseModal.propTypes = {
  id: PropTypes.string.isRequired,
  certificate: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  certificateAdded: PropTypes.isRequired,
  onAttachmentAdded: PropTypes.func.isRequired,
  onSubmitCertificate: PropTypes.func.isRequired,
};

export default memo(AddAttachmentCourseModal);
