import axios from 'axios';
import { toast } from 'react-toastify';
import { getToastPropsBy } from '../service/toastService';

class BaseApi {
  constructor() {
    let service = axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    service.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error != null && error.response != null && error.response.status != null) {
          switch (error.response.status) {
            case 403:
              showErrorToast(
                'What are you doing?',
                'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f645-1f3fb-200d-2640-fe0f.png',
                error.response
              );
              break;
            case 401:
              showErrorToast(
                "You can't be here!",
                'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f91a.png',
                error.response
              );
              break;
          }
        }

        throw error;
      }
    );

    this.service = service;
  }

  get(path, params) {
    return this.service.get(path, { params });
  }

  getFile(path) {
    return this.service.get(path, {
      responseType: 'blob',
    });
  }

  getFileFormBlobStorage(path) {
    const authHeader = this.service.defaults.headers.common['Authorization'];
    delete this.service.defaults.headers.common['Authorization'];
    let result = this.service.get(path, {
      responseType: 'blob'
    });
    this.service.defaults.headers.common['Authorization'] = authHeader;
    return result;
  }

  post(path, data) {
    return this.service.post(path, data);
  }

  postFile(file, url) {
    var request = buildPostFileRequest(file, this.service.defaults.headers);
    return this.service.post(url, request.data, { headers: request.headers });
  }

  put(path, data, params) {
    return this.service.put(path, data, { params });
  }

  patch(path, data) {
    return this.service.patch(path, data);
  }

  delete(path, data) {
    return this.service.delete(path, data);
  }

  setBearerToken(bearer) {
    this.service.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
  }
}

function buildPostFileRequest(file, headers) {
  var formData = new FormData();
  formData.append('file', file);
  var fileHeaders = headers;
  fileHeaders.common['Content-Type'] = 'multipart/form-data';

  return {
    data: formData,
    headers: fileHeaders,
  };
}

function showErrorToast(title, icon, response) {
  let toastText = "We're not sure what happened. If this happens again, please contact your account manager.";

  if (response && response.data) {
    const error = response.data.errors && response.data.errors[0];
    const statusCode = response.status;

    toastText = `${error ? error.message : 'Please try again later.'} (${statusCode})`;
  }

  const { toastBody, toastOptions } = getToastPropsBy({ icon, title, text: toastText });
  toast.error(toastBody, toastOptions);
}

export default new BaseApi();
