import { UserManager } from 'oidc-client-ts';

const location = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const userManagerConfig = {
  authority: process.env.IS4_AUTHORITY,
  client_id: process.env.IS4_CLIENTID,
  redirect_uri: `${location}`,
  response_type: 'code',
  scope:
    'openid email roles offline_access profile t4t-myportal-timesheets t4t-myportal',
  loadUserInfo: true,
  filterProtocolClaims: true,
  post_logout_redirect_uri: `${location}`

};

export const normalizeUrl = ()=> {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );
}

export default new UserManager(userManagerConfig);
