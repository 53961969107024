import { FETCH_COURSE_DATA_SUCCES, SET_SELECTED_TAB, SET_FILTER_VALUE, FREE_TO_FOLLOW, TO_REQUEST } from './actions';

export default function coursesReducer(state = { COURSES_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_COURSE_DATA_SUCCES: {
      let filteredCourses = [];
      let totalCount = action.totalCount;
      switch (action.historyType) {
        case FREE_TO_FOLLOW: {
          filteredCourses = action.courses.filter((course) => course.typeOfCourse === 'Free to follow');
          totalCount = filteredCourses.length;
          break;
        }
        case TO_REQUEST: {
          filteredCourses = action.courses.filter((course) => course.typeOfCourse === 'To request');
          totalCount = filteredCourses.length;
          break;
        }
        default:
          filteredCourses = action.courses;
          break;
      }

      return {
        ...state,
        courses: filteredCourses,
        skip: action.skip,
        take: action.take,
        totalCount: totalCount,
      };
    }

    case SET_FILTER_VALUE: {
      return {
        ...state,
        filterValue: action.filter,
      };
    }

    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    default:
      return state;
  }
}

export const COURSES_BASE_STATE = {
  courses: [],
  filterValue: null,
  skip: 0,
  take: 10,
  totalCount: null,
  selectedTab: 0,
};
