import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../redux/system/actions';
import Modal from '../Modal';
import SelectContractModal from './SelectContractModal';
import TimesheetService from '../../../service/timesheetService';

function CreateTimesheetModal({ id }) {
  const dispatch = useDispatch();
  const { handleCreateTimesheet } = TimesheetService();
  const contracts = useSelector((state) => state.contracts.contractsList);
  const [selectedContract, setSelectedContract] = useState(contracts.length === 1 ? contracts[0] : undefined);
  // use end date of contract if end date is in the past, else use current date
  const [selectedDate, setSelectedDate] = useState(!selectedContract ? new Date() : selectedContract.endDate && new Date(selectedContract.endDate) < new Date() ? new Date(selectedContract.endDate) : new Date());
  const [contractStartYear, setContractStartYear] = useState(selectedContract ? selectedContract.startDate.getFullYear() : null);
  const [contractStartMonth, setContractStartMonth] = useState(selectedContract ? selectedContract.startDate.getMonth() : null);
  const [contractEndYear, setContractEndYear] = useState(selectedContract && selectedContract.endDate ? selectedContract.endDate.getFullYear() : undefined);
  const [contractEndMonth, setContractEndMonth] = useState(selectedContract && selectedContract.endDate ? selectedContract.endDate.getMonth() : undefined);
  const [shouldShowTimesheets, setShouldShowTimesheets] = useState(false);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const handleSelectContractConfirm = useCallback((contract) => {
    setSelectedContract(contract);
    setSelectedDate(contract.endDate && new Date(contract.endDate) < new Date() ? new Date(contract.endDate) : new Date());
    setContractStartMonth(contract.startDate.getMonth());
    setContractStartYear(contract.startDate.getFullYear());
    setContractEndMonth(contract.endDate ? contract.endDate.getMonth() : undefined);
    setContractEndYear(contract.endDate ? contract.endDate.getFullYear() : undefined);
    setShouldShowTimesheets(true);
  }, []);

  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const setConfirmButtonDisabled = useCallback((date) => {
    if (date >= addMonths(new Date(), 2)) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, []);

  const handleChangeDate = useCallback(
    (date) => {
      setSelectedDate(date);
      setConfirmButtonDisabled(date);
    },
    [setConfirmButtonDisabled]
  );

  const handleChangeYear = useCallback(
    (date) => {
      const previousSelectedYear = new Date(selectedDate).getFullYear();
      const newSelectedYear = new Date(date).getFullYear();
      if (newSelectedYear < previousSelectedYear) {
        setSelectedDate(new Date(date.getFullYear(), 11, 1));
      }
      if (newSelectedYear > previousSelectedYear) {
        setSelectedDate(new Date(newSelectedYear, 0, 1));
      }
      setConfirmButtonDisabled(selectedDate);
    },
    [selectedDate, setConfirmButtonDisabled]
  );

  useEffect(() => {
    if (shouldShowTimesheets) {
      setShouldShowTimesheets(false);
      dispatch(showModal(id));
    }
  }, [dispatch, id, shouldShowTimesheets]);

  const handleBackButton = useCallback(() => {
    setSelectedContract(null);
    return <SelectContractModal id={id} confirmHandler={handleSelectContractConfirm} />;
  }, [id, handleSelectContractConfirm]);
  if (selectedContract == null) {
    return <SelectContractModal id={id} confirmHandler={handleSelectContractConfirm} />;
  }

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title="What month is this timesheet for?"
      contentClass="modal-body-center-h"
      confirmText="Create Timesheet"
      confirmButtonDisabled={isConfirmButtonDisabled}
      draggable
      disabledText="You can only create timesheets for the current month, next month or past months"
      confirmHandler={() => handleCreateTimesheet(selectedContract, selectedDate)}
      showBackButton={contracts.length > 1}
      backButtonHandler={handleBackButton}>
      <DatePicker
        inline
        selected={selectedDate}
        onChange={handleChangeDate}
        minDate={new Date(contractStartYear, contractStartMonth)}
        maxDate={new Date(contractEndYear ? contractEndYear : 2099, contractEndMonth !== undefined ? contractEndMonth : 11)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        onYearChange={handleChangeYear}
      />
    </Modal>
  );
}

CreateTimesheetModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(CreateTimesheetModal);
