import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/system/actions';
import Card from '../common/Card';
import CreateTimesheetModal from '../common/modals/CreateTimesheetModal';

const MODAL_ID = 'CreateTS';

function NoTimesheetsCard({ title }) {
  const dispatch = useDispatch();
  const handleShowModal = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(showModal(MODAL_ID));
    },
    [dispatch]
  );

  return (
    <>
      <Card
        title={title || 'Your Timesheets'}
        icon="pe-7s-copy-file"
        iconGradient="bg-green-blue-top"
        contentClass="no-current-card">
        <div className="row-fluid">
          <div className="col-lg-12">
            <div>{`It looks like you haven't created a timesheet yet.`}</div>
            <div>
              <a onClick={handleShowModal}>Create one now →</a>
            </div>
          </div>
        </div>
      </Card>
      <CreateTimesheetModal id={MODAL_ID} />
    </>
  );
}

NoTimesheetsCard.propTypes = {
  title: PropTypes.string,
};

export default memo(NoTimesheetsCard);
