import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import LoadingCard from '../../common/LoadingCard';
import NoContractsCard from '../contracts/NoContractsCard';
import CurrentTimesheetDetailsCard from './CurrentTimesheetDetailsCard';
import NoCurrentTimesheetCard from './NoCurrentTimesheetCard';
import moment from 'moment';

function CurrentTimesheetCard() {
  const { timesheetList: timesheets, contractsHaveTimesheetForCurrentMonth } = useSelector((state) => state.timesheets);
  const { contractsList: contracts } = useSelector((state) => state.contracts);
  const { isFetchingTimesheets, isFetchingContracts, isFetchingProfile } = useSelector((state) => state.system);
  const { nameToDisplay } = useSelector((state) => state.users);

  const sortedTimesheets = useMemo(() => {
    if (contracts.length && timesheets.length) {
      // Sort the filtered timesheets by date
      return [...timesheets].sort((a, b) => {
        if (new Date(a.start) > new Date(b.start)) {
          return -1;
        } else if (new Date(a.start) < new Date(b.start)) {
          return 1;
        } else {
          // If the year/month combination is the same, compare the latest timeline event date
          const latestEventDateA = Math.max(...a.timelineEvents.map((event) => new Date(event.date).getTime()));
          const latestEventDateB = Math.max(...b.timelineEvents.map((event) => new Date(event.date).getTime()));
          return latestEventDateA > latestEventDateB ? -1 : latestEventDateA < latestEventDateB ? 1 : 0;
        }
      });
    }
  }, [contracts.length, timesheets]);

  const latestTimesheet = useMemo(() => {
    if (sortedTimesheets) {
      if (contractsHaveTimesheetForCurrentMonth) {
        return { timesheet: sortedTimesheets[0] };
      } else {
        let message = '';
        if (contracts.length === 1) {
          message = `It looks like you don't have a timesheet yet for ${moment().format('MMMM YYYY')}.`;
        } else {
          message = `It looks like not all contracts have a timesheet yet for ${moment().format('MMMM YYYY')}.`;
        }
        return { timesheet: null, message };
      }
    }
  }, [contracts.length, contractsHaveTimesheetForCurrentMonth, sortedTimesheets]);

  const contractForLatestTimesheet = useMemo(() => {
    if (contractsHaveTimesheetForCurrentMonth && latestTimesheet && latestTimesheet.timesheet) {
      const contract = contracts.find((c) => c.contractId === latestTimesheet.timesheet.contractId);
      return contract;
    }
  }, [contracts, contractsHaveTimesheetForCurrentMonth, latestTimesheet]);

  if (isFetchingTimesheets || isFetchingContracts || isFetchingProfile) {
    return (
      <LoadingCard
        message={`Hang on, we're loading your timesheets.`}
        title={`${nameToDisplay} Current Timesheet`}
        icon="pe-7s-copy-file"
        iconGradient="bg-green-blue-top"
      />
    );
  }

  return contractsHaveTimesheetForCurrentMonth && contractForLatestTimesheet ? (
    <CurrentTimesheetDetailsCard timesheet={latestTimesheet.timesheet} contract={contractForLatestTimesheet} />
  ) : contracts.length === 0 ? (
    <NoContractsCard title={`${nameToDisplay} Current Timesheet`} onlyActive={true} />
  ) : (
    <NoCurrentTimesheetCard title={`${nameToDisplay} Current Timesheet`} message={latestTimesheet && latestTimesheet.message} />
  );
}

export default CurrentTimesheetCard;
