import { getUsers, getUserTypes } from '../../api/usersApi';
import { showRequestFailedToast } from '../../service/toastService';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const FETCH_USER_TYPES = 'FETCH_USER_TYPES';
export const FETCH_USER_TYPES_SUCCESS = 'FETCH_USER_TYPES_SUCCESS';
export const FETCH_USER_TYPES_FAILED = 'FETCH_USER_TYPES_FAILED';
export const SELECT_USER = 'SELECT_USER';
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';

export function fetchUsers(skip, take, filter) {
  return async function (dispatch) {
    dispatch({ type: FETCH_USERS });
    try {
      const response = await getUsers({ skip, take, filter });
      return dispatch({ type: FETCH_USERS_SUCCESS, users: response.data.data, skip, take, totalCount: response.data.totalCount });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_USERS_FAILED, error });
    }
  };
}

export function fetchUserTypes() {
  return async function (dispatch) {
    dispatch({ type: FETCH_USER_TYPES });
    try {
      const response = await getUserTypes();
      return dispatch({ type: FETCH_USER_TYPES_SUCCESS, userTypes: response.data });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_USER_TYPES_FAILED, error });
    }
  };
}

export function selectUser(user) {
  return function (dispatch, getState) {
    dispatch({ type: SELECT_USER, selectedUser: user, profile: getState().profile });
  };
}

export function setFilterValue(filter) {
  return { type: SET_FILTER_VALUE, filter };
}
