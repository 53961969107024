import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import {
    addEntry,
    addExpense,
    removeEntry,
    setEntryAmount,
    setEntryHomework,
    setEntryKey,
    setEntryProjectId,
    setEntryRemarks,
    showExpenseModal,
} from '../../redux/timesheets/actions';
import ExpensesModal from '../common/modals/ExpensesModal';
import Select from '../common/Select';
import * as inputValidation from '../../service/inputValidationService';

const MIN_AMOUNT = 0;
const MAX_AMOUNT = 24;

function TimesheetEditRow({
    timesheet,
    contractEndDate,
    contractStartDate,
    day,
    dayIndex,
    rates,
    isFreeTextAllowed,
    holiday,
    projects,
    standardHoursPerDay,
    inputErrorsByEntry,
}) {
    const dispatch = useDispatch();
    const [openedExpenses, setOpenedExpenses] = useState([]);
    const { date: dateOfDay } = day;
    const [selectedProjectOption, setSelectedProjectOption] = useState('');

    const rateOptions = useMemo(
        () =>
            [...rates]
                .sort((a, b) => (a.isBillable > b.isBillable ? -1 : a.isBillable < b.isBillable ? 1 : 0))
                .reduce((acc, element) => {
                    if (element.key == 'DLST') {
                        return [element, ...acc];
                    }
                    return [...acc, element];
                }, [])
                .map((rate) => ({ value: rate.key, text: rate.name })),
        [rates]
    );

    const projectOptions = useMemo(
        () =>
            [...projects]
                .filter((project) => dateOfDay <= project.endDate && dateOfDay >= project.startDate)
                .map((project) => ({ value: project.projectId, text: project.projectName })),
        [projects, dateOfDay]
    );

    const handleSetEntryKey = useCallback(
        (entryIndex, key) => dispatch(setEntryKey(timesheet, dayIndex, entryIndex, key, isFreeTextAllowed)),
        [dayIndex, dispatch, isFreeTextAllowed, timesheet]
    );
    const handleSetEntryAmount = useCallback((entryIndex, amount) => dispatch(setEntryAmount(timesheet, dayIndex, entryIndex, amount)), [dayIndex, dispatch, timesheet]);
    const handleSetEntryRemarks = useCallback(
        (entryIndex, remarks) => dispatch(setEntryRemarks(timesheet, dayIndex, entryIndex, remarks)),
        [dayIndex, dispatch, timesheet]
    );
    const handleSetEntryProjectId = useCallback(
        (entryIndex, projectId) => {
            dispatch(setEntryProjectId(timesheet, dayIndex, entryIndex, projectId));
            setSelectedProjectOption(projectId);
        },
        [dayIndex, dispatch, timesheet]
    );
    const handleAddEntry = useCallback(() => dispatch(addEntry(timesheet, dayIndex)), [dayIndex, dispatch, timesheet]);
    const handleRemoveEntry = useCallback((entryIndex) => dispatch(removeEntry(timesheet, dayIndex, entryIndex)), [dayIndex, dispatch, timesheet]);
    const handleAddExpense = useCallback((entryIndex) => dispatch(addExpense(timesheet, dayIndex, entryIndex)), [dayIndex, dispatch, timesheet]);
    const handleShowExpenseModal = useCallback(() => dispatch(showExpenseModal(dayIndex)), [dayIndex, dispatch]);
    const handleSetEntryHomework = useCallback(
        (entryIndex, isHomework) => {
            dispatch(setEntryHomework(timesheet, dayIndex, entryIndex, isHomework));
        },
        [dayIndex, dispatch, timesheet]
    );
    const showExpenses = useCallback(
        (entryId, day) => {
            setOpenedExpenses((prevState) => {
                if (prevState.includes(entryId)) {
                    return prevState.filter((tId) => tId !== entryId);
                }
                return [...prevState, entryId];
            });

            if (day.entries[entryId].expenses.length <= 0) {
                handleAddExpense(entryId);
            }
            handleShowExpenseModal();
        },
        [handleAddExpense, handleShowExpenseModal]
    );

    const returnExpensesButtonClass = useCallback((numberOfExpensesInEntry) => {
        let colorClass = 'bg-purple-fade';

        if (numberOfExpensesInEntry == 0) {
            colorClass = 'bg-red-fade';
        }
        return 'pe-7s-cash icon-gradient timesheet-detail-action ' + colorClass;
    }, []);

    const isFieldDisabled = useCallback(
        (date) => {
            if (contractEndDate === null) {
                if (timesheet.state === 1 || timesheet.state === 2 || moment(contractStartDate) > date) return true;
            } else {
                if (timesheet.state === 1 || timesheet.state === 2 || moment(contractStartDate) > date || date > moment(contractEndDate).add(12, 'hours')) return true;
            }

            return false;
        },
        [contractEndDate, contractStartDate, timesheet.state]
    );

    const isButtonEnabled = useCallback((date) => !isFieldDisabled(date), [isFieldDisabled]);

    const autoFillHoursEmptyRow = useCallback(
        (i, key, day) => {
            handleSetEntryKey(i, key);
            let bookedHours = 0;

            if (day.entries.length > 1) {
                day.entries.forEach((entry) => {
                    bookedHours += +entry.amount;
                });
            }
            if (key !== '' && day.entries[i].amount == 0) {
                handleSetEntryAmount(i, bookedHours < standardHoursPerDay ? standardHoursPerDay - bookedHours : 0);
            } else if (key === '') {
                handleSetEntryAmount(i, 0);
            }
        },
        [standardHoursPerDay, handleSetEntryAmount, handleSetEntryKey]
    );

    const setHoursAmount = useCallback(
        (e, i) => {
            inputValidation.enforceDecimal(e, e.target);
            handleSetEntryAmount(i, e.target.value);
        },
        [handleSetEntryAmount]
    );
    const getInputClassNamesByProp = (prop, entryIndex) => {
        let output = 'form-control';
        if (inputErrorsByEntry != null && inputErrorsByEntry[entryIndex] != null && inputErrorsByEntry[entryIndex][prop] != null) {
            output += ' error-input';
        }
        return output;
    };

    const getErrorTextToDisplay = useCallback(
        (entryIndex) => {
            if (inputErrorsByEntry == null || inputErrorsByEntry[entryIndex] == null) {
                return undefined;
            }
            const { generic: genericError, type: typeError, remarks: remarksError, amount: amountError } = inputErrorsByEntry[entryIndex];

            if (genericError != null) {
                return genericError;
            } else if (typeError != null) {
                return typeError;
            } else if (remarksError != null) {
                return remarksError;
            } else if (amountError != null) {
                return amountError;
            }
        },
        [inputErrorsByEntry]
    );

    const holidayRate = useMemo(() => rates.find((rate) => rate.isHolidayRate), [rates]);
    const isDayInContractRange = useMemo(
        () => moment(day.date) >= moment(contractStartDate) && (contractEndDate == null || moment(day.date) < moment(contractEndDate)),
        [contractEndDate, contractStartDate, day.date]
    );
    const isWeekend = moment(day.date).day() === 0 || moment(day.date).day() == 6;

    // Only fill in holidays if the timesheet is in DRAFT state, if the day is in the contract range (between start and end date), if the day has not been edited before and if the day is during the week
    useEffect(() => {
        if (timesheet.state === 0 && isDayInContractRange && !day.isEdited && holidayRate && holiday && !isWeekend) {
            if (isFreeTextAllowed) {
                handleSetEntryKey(0, holidayRate.name);
                handleSetEntryAmount(0, standardHoursPerDay);
            } else {
                autoFillHoursEmptyRow(0, holidayRate.key, day);
            }
            handleSetEntryRemarks(0, holiday.localName);
        }
    }, [
        dispatch,
        autoFillHoursEmptyRow,
        handleSetEntryAmount,
        handleSetEntryKey,
        handleSetEntryRemarks,
        isWeekend,
        holidayRate,
        holiday,
        day,
        isFreeTextAllowed,
        contractStartDate,
        contractEndDate,
        isDayInContractRange,
        timesheet.state,
        standardHoursPerDay,
    ]);

    return (
        <>
            {day.entries.map((entry, entryIndex) => {
                const date = moment(day.date);
                const typeInputComponent = isFreeTextAllowed ? (
                    <DebounceInput
                        type="text"
                        className={getInputClassNamesByProp('type', entryIndex)}
                        value={entry.type || ''}
                        minLength={2}
                        maxLength={30}
                        title={isFieldDisabled(date) ? '' : 'Max 30 characters'}
                        disabled={isFieldDisabled(date)}
                        onChange={(e) => handleSetEntryKey(entryIndex, e.target.value)}
                        debounceTimeout="500"
                    />
                ) : (
                    <Select
                        options={rateOptions}
                        className={getInputClassNamesByProp('type', entryIndex)}
                        selected={entry.type && entry.type.key}
                        changeHandler={(key) => {
                            autoFillHoursEmptyRow(entryIndex, key, day);
                        }}
                        disabled={isFieldDisabled(date)}
                    />
                );

                const internalRateKey = 'ANC1';

                return (
                    <React.Fragment key={`day-${dayIndex}-entry-${entryIndex}-trow`}>
                        <tr className={`timesheet-detail-row${isWeekend ? ' weekend' : ''}`}>
                            <td className="timesheet-detail-row--date">
                                <div>
                                    <p>
                                        {entryIndex == 0 && date.format('ddd, Do')}
                                        <>
                                            {entry.isHomework && (
                                                <i
                                                    className="pe-7s-home icon-gradient bg-green-fade float-right"
                                                    style={{
                                                        paddingLeft: '3px',
                                                        cursor: isButtonEnabled(date) ? 'pointer' : 'default',
                                                    }}
                                                    onClick={isButtonEnabled(date) ? () => handleSetEntryHomework(entryIndex, !entry.isHomework) : undefined}
                                                    title={isButtonEnabled(date) ? 'Remove homework' : undefined}
                                                />
                                            )}
                                        </>
                                    </p>
                                </div>
                            </td>

                            <td className="timesheet-detail-row--description">{typeInputComponent}</td>
                            <td className="timesheet-detail-row--description">
                                {entry.type && entry.type.key === internalRateKey && projectOptions && projectOptions.length > 0 && (
                                    <Select
                                        placeholder="Select a project"
                                        options={projectOptions}
                                        selected={selectedProjectOption ? selectedProjectOption : entry.projectId}
                                        changeHandler={(projectId) => {
                                            handleSetEntryProjectId(entryIndex, projectId); // Set selected projectId to entry.projectId
                                        }}
                                        disabled={isFieldDisabled(date)}
                                        className="mb-2"
                                    />
                                )}
                                <DebounceInput
                                    type="text"
                                    className={getInputClassNamesByProp('remarks', entryIndex)}
                                    value={entry.remarks || ''}
                                    minLength={2}
                                    maxLength={40}
                                    title={isFieldDisabled(date) ? undefined : 'Max 40 characters'}
                                    disabled={isFieldDisabled(date)}
                                    onChange={(e) => handleSetEntryRemarks(entryIndex, e.target.value)}
                                    debounceTimeout={500}
                                />
                            </td>
                            <td className="timesheet-detail-row--amount">
                                <DebounceInput
                                    id={`amount${day.date}-entry${entryIndex}`}
                                    className={getInputClassNamesByProp('amount', entryIndex)}
                                    type="number"
                                    data-decimal={2}
                                    value={entry.amount}
                                    disabled={isFieldDisabled(date)}
                                    min={MIN_AMOUNT}
                                    max={MAX_AMOUNT}
                                    step="any"
                                    onChange={() => {
                                        // Prevent error in console
                                    }}
                                    onInput={(e) => {
                                        if (e.target.value !== '' && e.target.value != null) {
                                            setHoursAmount(e, entryIndex);
                                        } else {
                                            // Weird behaviour when passing empty string instead of undefined
                                            handleSetEntryAmount(entryIndex, undefined);
                                        }
                                    }}
                                    debounceTimeout="1000"
                                />
                            </td>
                            <td className="timesheet-detail-row--actions">
                                <>
                                    {isButtonEnabled(date) && (
                                        <i
                                            className="pe-7s-plus icon-gradient bg-yellow-fade timesheet-detail-action"
                                            data-toggle="tooltip"
                                            title="Add a subentry"
                                            onClick={handleAddEntry}
                                        />
                                    )}
                                    {isButtonEnabled(date) && day.entries.length > 1 && (
                                        <i
                                            className="pe-7s-trash icon-gradient bg-light-blue-fade timesheet-detail-action"
                                            data-toggle="tooltip"
                                            title="Remove entry"
                                            onClick={() => handleRemoveEntry(entryIndex)}
                                        />
                                    )}
                                    {isButtonEnabled(date) && (
                                        <i
                                            className="pe-7s-home icon-gradient bg-purple-fade timesheet-detail-action"
                                            data-toggle="tooltip"
                                            title="Mark entry as 'working from home'"
                                            onClick={() => handleSetEntryHomework(entryIndex, !entry.isHomework)}
                                        />
                                    )}
                                    {isButtonEnabled(date) && (
                                        <i
                                            className={returnExpensesButtonClass(day.entries[entryIndex].expenses.length)}
                                            style={{ paddingLeft: '3px' }}
                                            data-toggle="tooltip"
                                            title="Show expenses of the entry"
                                            onClick={() => showExpenses(entryIndex, day)}
                                        />
                                    )}
                                </>
                            </td>
                        </tr>
                        {getErrorTextToDisplay(entryIndex) != null && (
                            <tr className="error-row">
                                <td colSpan="5">
                                    <p className="error-row-text">
                                        <b>ERROR:</b> {getErrorTextToDisplay(entryIndex)}
                                    </p>
                                </td>
                            </tr>
                        )}
                        {((isButtonEnabled(date) && openedExpenses.includes(entryIndex)) || timesheet.state === 1 || timesheet.state === 2) &&
                            day.entries[entryIndex].expenses.length > 0 && (
                                <ExpensesModal
                                    timesheet={timesheet}
                                    isWeekend={isWeekend}
                                    dayIndex={dayIndex}
                                    expenses={day.entries[entryIndex].expenses}
                                    entryIndex={entryIndex}
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </>
    );
}

TimesheetEditRow.propTypes = {
    timesheet: PropTypes.object.isRequired,
    contractEndDate: PropTypes.instanceOf(Date),
    contractStartDate: PropTypes.instanceOf(Date).isRequired,
    day: PropTypes.object.isRequired,
    dayIndex: PropTypes.number.isRequired,
    rates: PropTypes.array.isRequired,
    isFreeTextAllowed: PropTypes.bool.isRequired,
    rowId: PropTypes.number,
    showRow: PropTypes.bool,
    holiday: PropTypes.object,
    projects: PropTypes.array,
    standardHoursPerDay: PropTypes.number.isRequired,
    inputErrorsByEntry: PropTypes.object,
};

export default memo(TimesheetEditRow);
