import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { addCourseReview } from '../../../api/coursesApi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';

function CreateCourseReviewModal({ id, courseEnrollment, onReviewSubmit }) {
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);
  const [feedback, setFeedBack] = useState('');
  const [rating, setRating] = useState(-1); // Initialize with -1 to represent no selection
  const [hover, setHover] = useState(null);
  const totalStars = 5;

  const isConfirmButtonDisabled = () => {
    return feedback.trim() === '' || rating === -1; // Check for -1 instead of null
  };

  useEffect(() => {
    // Reset state when modal opens
    setFeedBack('');
    setRating(-1);
    setHover(null);
  }, [id]); // Trigger effect when id changes (modal opens)

  const handleCreateCourseReview = async () => {
    const courseReview = {
      description: feedback,
      starRating: rating,
      reviewDate: new Date(),
      userId: currentLoggedUserId,
      courseId: courseEnrollment.course.id,
    };

    try {
      await addCourseReview(courseReview);
      toast.success('You have successfully sent a review for this course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

      if (onReviewSubmit) {
        onReviewSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const closeModal = () => {
    setFeedBack('');
    setRating(-1);
    setHover(null);
  };

  return (
    <Modal
      id={id}
      icon="pe-7s-chat"
      iconGradient="bg-green-blue-top"
      title={`Review (${courseEnrollment && courseEnrollment.course ? courseEnrollment.course.name : ''})`}
      contentClass="modal-body-center-h"
      confirmText="SEND REVIEW"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleCreateCourseReview}>
      <div className="width100">
        <label>Please give us an honest opinion so that we can optimize our libraries!</label>
        <textarea placeholder="Type here..." className="form-control custom-input" rows="5" value={feedback} onChange={(e) => setFeedBack(e.target.value)} />

        <div className="mt-2">
          <h4>Rating</h4>
          <p>Give it some stars!</p>
          <div className="star-rating">
            {[...Array(totalStars)].map((_, index) => {
              const currentRating = index + 1;
              return (
                <label key={index} className="star-label">
                  <input
                    type="radio"
                    name="rating"
                    value={currentRating}
                    onChange={() => setRating(currentRating)}
                    checked={rating === currentRating} // Check if the current rating matches the selected rating
                    style={{ display: 'none' }} // Hide the radio button
                  />
                  <span
                    className="star"
                    style={{
                      fontSize: '24px',
                      color: currentRating <= (hover || rating) ? '#ffc107' : '#e4e5e9',
                    }}
                    onMouseEnter={() => setHover(currentRating)}
                    onMouseLeave={() => setHover(null)}>
                    &#9733;
                  </span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}

CreateCourseReviewModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(CreateCourseReviewModal);
