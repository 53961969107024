  import {
    showRequestFailedToast,
  } from '../../service/toastService';
  import { getProjectsByUserId } from '../../api/projectsApi';

  export const FETCH_PROJECTS = 'FETCH_PROJECTS';
  export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
  export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED';
  
  export function fetchProjectsByUserId(userId) {
    return async (dispatch) => {
      dispatch({ type: FETCH_PROJECTS });
      try {
        const response = await getProjectsByUserId(userId);
        return dispatch({ 
          type: FETCH_PROJECTS_SUCCESS, 
          projects: response.data.projects,
          minProjectStartDate: response.data.minStartDate,
          maxProjectEndDate: response.data.maxEndDate,
        });
      } catch (error) {
        dispatch({ type: FETCH_PROJECTS_FAILED, error });
        showRequestFailedToast();
      }
    };
  }
  