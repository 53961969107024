import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addCourse, addCourseDocument, addCourseLink, getCourseCategories } from '../../../api/coursesApi';
import { DebounceInput } from 'react-debounce-input';
import Select from '../Select';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';
import { toast } from 'react-toastify';
import { showModal } from '../../../redux/system/actions';
import AddAttachmentCourseModal from './AddAttachmentCourseModal';
import AddLinkCourseModal from './AddLinkCourseModal';
import { forEach } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CREATE_TS_ADD_ATTACHMENT_MODAL_ID = 'createTSNewCourse2';
const CREATE_TS_ADD_LINK_MODAL_ID = 'createTSNewCourseLink';

const ValidatedDebounceInput = ({ value, onChange, minLength, placeholder, ...props }) => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setIsValid(inputValue.length >= minLength);
    onChange(e);
  };

  return (
    <div>
      <DebounceInput {...props} className={`form-control ${isValid ? '' : 'is-invalid'}`} placeholder={placeholder} value={value} onChange={handleInputChange} />
    </div>
  );
};

function RequestNewCourseModal({ id, courseCategory }) {
  const dispatch = useDispatch();
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);
  const [course, setCourse] = useState({
    startDate: null,
    selfStudyCourse: null,
    name: '',
    category: courseCategory.name,
    duration: '',
    typeOfCourse: '',
    company: '',
    place: '',
    certificate: null,
    price: '',
    link: 'No',
    attachment: '',
    description: '',
    motivationRequest: '',
    status: 'Requested',
    isPublic: true,
    userId: currentLoggedUserId,
    courseCategoryId: courseCategory.id,
  });
  const [unitDuration, setUnitDuration] = useState('');

  const [courseCategories, setCourseCategories] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [attachmentsResult, setAttachmentsResult] = useState([]);
  const [links, setLinks] = useState([]);
  const [showCertificate, setShowCertificate] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [certificateAdded, setCertificateAdded] = useState(null);

  const handleShowModal = useCallback(
    (modalId, course) => {
      dispatch(showModal(modalId, course));
    },

    [dispatch]
  );

  const handleCreateCourse = async () => {
    try {
      const durationFormat = `${course.duration} ${unitDuration}`;
      let attachmentValue = 'No'; // Default value for attachment

      if (attachmentsResult !== null && attachmentsResult.length > 0) {
        attachmentValue = 'Yes';
      }

      let courseToCreate;

      if (course.selfStudyCourse === 'true') {
        courseToCreate = {
          ...course,
          duration: durationFormat,
          attachment: attachmentValue,
          startDate: new Date(),
          certificateDocument: course.certificate === 'true' ? certificateAdded.id || null : null,
        };
      } else {
        courseToCreate = {
          ...course,
          duration: durationFormat,
          attachment: attachmentValue,
          startDate: new Date(course.startDate.getTime() + 24 * 60 * 60 * 1000), // Adding one day
          certificateDocument: course.certificate === 'true' ? certificateAdded.id || null : null,
        };
      }

      const result = await addCourse(courseToCreate);

      forEach(links, async (link) => {
        const courseLink = {
          url: link,
          courseId: result.data.id,
        };

        await addCourseLink(courseLink);
      });

      forEach(attachmentsResult, async (attachment) => {
        const courseDocument = {
          courseId: result.data.id,
          documentId: attachment.id,
        };

        await addCourseDocument(courseDocument);
      });

      closeModal();
      toast.success('You have successfully sent a request for a new course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const resetCourse = () => {
    setCourse({
      startDate: null,
      selfStudyCourse: null,
      name: '',
      category: courseCategory.name,
      duration: '',
      typeOfCourse: '',
      company: '',
      place: '',
      certificate: null,
      price: '',
      link: 'No',
      attachment: '',
      description: '',
      motivationRequest: '',
      status: 'Requested',
      isPublic: true,
      userId: currentLoggedUserId,
      certificateDocument: null,
      courseCategoryId: courseCategory.id,
    });

    setUnitDuration('');
  };

  const isConfirmButtonDisabled = () => {
    const propertiesToCheck = Object.keys(course).filter((key) => !['link', 'attachment', 'category', 'userId', 'courseCategoryId', 'status', 'isPublic'].includes(key));

    // Check if any required fields are empty or if there are issues with duration or validity
    if (propertiesToCheck.some((key) => course[key] === '' || !unitDuration || !isValid)) {
      return true;
    }

    if (course.selfStudyCourse === null) {
      return true;
    }

    // Additional checks for certificate and startDate if they are mandatory
    if (propertiesToCheck.includes('startDate') && course.startDate === null && course.selfStudyCourse === 'false') {
      return true;
    }

    if (course.certificate === null || (course.certificate === 'true' && certificateAdded === null)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const fetchCourseCategories = async () => {
      try {
        const result = await getCourseCategories();
        setCourseCategories(result.data);
      } catch (error) {
        throw new Error('Error fetching course categories');
      }
    };

    fetchCourseCategories();
  }, []);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleDurationChange = (e) => {
    const { value } = e.target;
    const isValidInput = /^[1-9]\d*$/.test(value);
    setIsValid(isValidInput);
    if (isValidInput) {
      setCourse({ ...course, duration: value });
    }
  };

  const handleCertificate = (document) => {
    setCertificateAdded(document);
  };

  const handleAttachmentAdded = (attachmentData) => {
    // Handle the attachment data here
    setAttachmentsResult(attachmentData);
  };

  const handleLinkAdded = (linkData) => {
    toast.success('You have successfully updated your links', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

    if (linkData.length > 0) {
      setCourse({ ...course, link: 'Yes' });
    } else {
      setCourse({ ...course, link: 'No' });
    }

    setLinks(linkData);
  };

  const handleDateChange = (date) => {
    const currentDate = new Date();
    if (date < currentDate) {
      return;
    }
    setCourse({ ...course, startDate: date });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input matches the specified pattern
    const isValidInput = /^\d+(\.\d{1,2})?$/.test(inputValue);
    setIsValid(isValidInput);
    if (isValidInput) {
      setCourse({ ...course, price: inputValue });
    }
  };

  const [clearSelectedFilesKey, setClearSelectedFilesKey] = useState(0);

  const closeModal = () => {
    resetCourse();
    setCurrentStep(1);
    setUnitDuration('');
    setAttachmentsResult([]);
    setCertificateAdded(null);
    setCertificate(false);
    setShowCertificate(false);
    setLinks([]);
    setOpenModal(false);
    setClearSelectedFilesKey(clearSelectedFilesKey + 1); // Update the key to force re-render

    // Here i want to trigger someting so that the selectedfiles array inside the addAttachmentCourseModal are empty
  };

  return (
    <>
      <Modal
        isLarge={true}
        id={id}
        keepOpen={openModal}
        icon="pe-7s-notebook"
        iconGradient="bg-green-blue-top"
        title="Request new course"
        contentClass="modal-body-center-h"
        confirmText="SEND REQUEST"
        closeHandler={() => closeModal()}
        confirmButtonDisabled={isConfirmButtonDisabled()}
        confirmHandler={handleCreateCourse}>
        <AddLinkCourseModal id={CREATE_TS_ADD_LINK_MODAL_ID} links={links} onLinkAdded={handleLinkAdded} />
        <AddAttachmentCourseModal
          key={clearSelectedFilesKey}
          id={CREATE_TS_ADD_ATTACHMENT_MODAL_ID}
          certificate={certificate}
          attachments={attachmentsResult}
          certificateAdded={certificateAdded}
          onAttachmentAdded={handleAttachmentAdded}
          onSubmitCertificate={handleCertificate}
        />

        <div style={{ width: '100%' }}>
          {currentStep === 1 && (
            <>
              <button
                className="btn btn-primary mr-2 mb-2"
                onClick={() => {
                  setOpenModal(true);
                  setCertificate(false);
                  handleShowModal(CREATE_TS_ADD_ATTACHMENT_MODAL_ID, course);
                }}>
                <i style={{ marginLeft: '10px', fontSize: 16 }} className="fas fa-plus"></i>
                Add Attachment(s)
                {attachmentsResult.length > 0 && <span className="badge badge-secondary ml-2">{attachmentsResult.length}</span>}
              </button>

              <button
                className="btn btn-primary mr-2 mb-2"
                onClick={() => {
                  setOpenModal(true);
                  handleShowModal(CREATE_TS_ADD_LINK_MODAL_ID, course);
                }}>
                <i style={{ marginLeft: '10px', fontSize: 16 }} className="fas fa-plus"></i>
                Add Link(s)
                {links.length > 0 && <span className="badge badge-secondary ml-2">{links.length}</span>}
              </button>

              {showCertificate && (
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => {
                    setOpenModal(true);
                    setCertificate(true);
                    handleShowModal(CREATE_TS_ADD_ATTACHMENT_MODAL_ID, course);
                  }}>
                  <i style={{ marginLeft: '10px', fontSize: 16 }} className="fas fa-plus"></i>
                  Add Certificate
                  {certificateAdded !== null && <span className="badge badge-secondary ml-2">1</span>}
                </button>
              )}

              <div className="custom-grid">
                <div>
                  <label>Name *</label>
                  <ValidatedDebounceInput
                    type="text"
                    minLength={2}
                    value={course.name}
                    onChange={(e) => setCourse({ ...course, name: e.target.value })}
                    placeholder="Course name"
                  />
                </div>

                <div>
                  <label>Category *</label>
                  <DebounceInput type="text" className="form-control" value={courseCategory.name} disabled />
                </div>

                <div>
                  <label>Duration *</label>
                  <div style={{ display: 'flex' }}>
                    <ValidatedDebounceInput style={{ width: '70px' }} type="text" minLength={1} min={1} value={course.duration} onChange={handleDurationChange} />

                    <select value={unitDuration} onChange={(e) => setUnitDuration(e.target.value)} className="form-control">
                      <option value="">Select Unit</option>
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label>Type of course *</label>
                  <Select
                    options={[
                      { value: '', text: 'Select a type' },
                      { value: 'Free to follow', text: 'Free to follow' },
                      { value: 'To request', text: 'To request' },
                    ]}
                    selected={course.typeOfCourse}
                    changeHandler={(selectedValue) => setCourse({ ...course, typeOfCourse: selectedValue })}
                    defaultEmpty={false}
                  />
                </div>

                <div>
                  <label>Company *</label>
                  <ValidatedDebounceInput
                    type="text"
                    minLength={2}
                    value={course.company}
                    onChange={(e) => setCourse({ ...course, company: e.target.value })}
                    placeholder="Company name"
                  />
                </div>

                <div>
                  <label>Place *</label>
                  <Select
                    options={[
                      { value: '', text: 'Select a place' },
                      { value: 'Online', text: 'Online' },
                      { value: 'Class', text: 'Class' },
                    ]}
                    selected={course.place}
                    changeHandler={(selectedValue) => setCourse({ ...course, place: selectedValue })}
                    defaultEmpty={false}
                  />
                </div>

                <div>
                  <label>Certificate *</label>
                  <Select
                    options={[
                      { value: '', text: 'Select certificate status' },
                      { value: 'true', text: 'Yes' },
                      { value: 'false', text: 'No' },
                    ]}
                    selected={course.certificate}
                    changeHandler={(selectedValue) => {
                      setCourse({ ...course, certificate: selectedValue });
                      setShowCertificate(selectedValue === 'true');
                    }}
                    defaultEmpty={false}
                  />
                </div>

                <div>
                  <label>Price *</label>
                  <ValidatedDebounceInput type="number" minLength={1} min={0} value={course.price} onChange={handleChange} placeholder="e.g., 59.99" />
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '30px', marginBottom: '30px', gap: '10px' }}>
                <div style={{ width: '74%', marginRight: '21px' }}>
                  <label>Description *</label>
                  <textarea
                    placeholder="Enter description here..."
                    rows={5}
                    className="form-control"
                    value={course.description}
                    onChange={(e) => setCourse({ ...course, description: e.target.value })}></textarea>
                </div>

                <div style={{ maxWidth: '169px', overflow: 'hidden' }}>
                  <div style={{ maxWidth: '169px', overflow: 'hidden', marginBottom: '10px' }}>
                    <label>Self study course *</label>
                    <Select
                      options={[
                        { value: '', text: 'Select self study course status' },
                        { value: 'true', text: 'Yes' },
                        { value: 'false', text: 'No' },
                      ]}
                      selected={course.selfStudyCourse}
                      changeHandler={(selectedValue) => setCourse({ ...course, selfStudyCourse: selectedValue })}
                      defaultEmpty={false}
                    />
                  </div>

                  {course.selfStudyCourse === 'false' && (
                    <div style={{ maxWidth: '169px', overflow: 'hidden' }}>
                      <label>StartDate *</label>
                      <DatePicker
                        style={{ maxWidth: '169px' }}
                        className="form-control"
                        selected={course.startDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <div style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}>
              <div style={{ width: '100%' }}>
                <label>Motivation request *</label>
                <textarea
                  placeholder="Please provide your motivation for requesting this new course"
                  rows={8}
                  className="form-control width100"
                  value={course.motivationRequest}
                  onChange={(e) => setCourse({ ...course, motivationRequest: e.target.value })}></textarea>
              </div>
            </div>
          )}

          <div style={{ margin: 'auto', textAlign: 'center' }}>
            {currentStep > 1 && (
              <button type="button" className="btn btn-primary documents-row-button" onClick={handlePreviousStep}>
                <i style={{ marginRight: '10px', fontSize: 16 }} className="fas fa-arrow-left"></i>
                Go Back
              </button>
            )}
            {currentStep < 2 && (
              <button type="button" className="btn btn-primary documents-row-button" onClick={handleNextStep}>
                Next step
                <i style={{ marginLeft: '10px', fontSize: 16 }} className="fas fa-arrow-right"></i>
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

RequestNewCourseModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(RequestNewCourseModal);
