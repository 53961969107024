import React, { memo, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { setTemporaryTimesheetSigner } from '../../../redux/timesheets/actions';

function ChangeTimesheetSignerModal({ id, timesheet }) {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const emailRef = useRef(null);

  const validateEmail = (event) => {
    const emailRegExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsValid(emailRegExp.test(event.target.value));
  };

  const handleChangeTimesheetSigner = useCallback(() => {
    dispatch(setTemporaryTimesheetSigner(timesheet, emailRef.current.value));
  }, [dispatch, timesheet]);

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title={'Please enter an e-mail for the temporary signer'}
      contentClass="modal-body-center-h"
      confirmButtonDisabled={!isValid}
      disabledText="Please provide a valid e-mail"
      confirmHandler={() => handleChangeTimesheetSigner(emailRef)}>
      <input
        onChange={validateEmail}
        placeholder="E-mail"
        className="form-control"
        type="email"
        ref={emailRef}></input>
    </Modal>
  );
}

ChangeTimesheetSignerModal.propTypes = {
  id: PropTypes.string.isRequired,
  timesheet: PropTypes.object.isRequired,
};

export default memo(ChangeTimesheetSignerModal);
