import {
  APPROVE_TIMESHEET,
  APPROVE_TIMESHEET_FAILED,
  APPROVE_TIMESHEET_SUCCESS,
  GET_PUBLIC_LINK,
  GET_PUBLIC_LINK_FAILED,
  GET_PUBLIC_LINK_SUCCESS,
  REJECT_TIMESHEET,
  REJECT_TIMESHEET_FAILED,
  REJECT_TIMESHEET_SUCCESS,
} from './actions';

export default function validateTimesheetsReducer(state = { ...VALIDATE_TIMESHEETS_BASE_STATE }, action) {
  switch (action.type) {
    case GET_PUBLIC_LINK:
      return {
        ...VALIDATE_TIMESHEETS_BASE_STATE,
        isFetching: true,
      };
    case GET_PUBLIC_LINK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        timesheet: action.response.data.timesheet,
        contract: action.response.data.contract,
        publicLink: action.response.data.publicLink,
      };
    case APPROVE_TIMESHEET:
    case REJECT_TIMESHEET:
      return {
        ...state,
        isSubmitting: true,
      };
    case APPROVE_TIMESHEET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isApproved: true,
      };
    case REJECT_TIMESHEET_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isRejected: true,
      };
    case GET_PUBLIC_LINK_FAILED:
    case REJECT_TIMESHEET_FAILED:
    case APPROVE_TIMESHEET_FAILED:
      return {
        ...VALIDATE_TIMESHEETS_BASE_STATE,
        isSubmitting: false,
        errorMessage: 'Something went wrong, Please contact Team4Talent.',
      };
    default:
      return state;
  }
}

export const VALIDATE_TIMESHEETS_BASE_STATE = {
  isFetching: false,
  isApproved: false,
  isRejected: false,
  isSubmitting: false,
  doneSubmitting: false,
  errorMessage: undefined,
  timesheet: undefined,
  contract: undefined,
  publicLink: undefined,
};
