import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy } from '../../../service/toastService';

function AddLinkCourseModal({ id, links, onLinkAdded }) {
  const [link, setLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changesMade, setChangesMade] = useState(false);
  const [tempLinks, setTempLinks] = useState(links);

  useEffect(() => {
    setTempLinks(links);
  }, [links]);

  const closeModal = () => {
    setLink('');
    setErrorMessage('');
    setChangesMade(false);
    setTempLinks(links);
  };

  const isConfirmButtonDisabled = () => {
    return !changesMade;
  };

  const handleAddLink = (link) => {
    if (!isLinkValid(link)) {
      toast.error('Please enter a valid URL starting with http:// or https://', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      return;
    }

    if (tempLinks.includes(link)) {
      toast.error('This link already exists', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      return;
    }

    setLink('');
    setChangesMade(true);
    setTempLinks([...tempLinks, link]);
  };

  const isLinkValid = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
  };

  const handleSaveLinks = () => {
    onLinkAdded(tempLinks);
    closeModal();
  };

  const removeLink = (linkToRemove) => {
    setTempLinks(tempLinks.filter((link) => link !== linkToRemove));
    setChangesMade(true);
  };

  return (
    <Modal
      id={id}
      icon="pe-7s-file"
      iconGradient="bg-green-blue-top"
      title="Link"
      contentClass="modal-body-center-h"
      confirmText="SAVE"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleSaveLinks}>
      <div className="width100">
        <p style={{ marginBottom: '5px', fontSize: '12px' }}>(IMPORTANT: press enter to add the link)</p>
        <input
          placeholder="Press Enter to add link"
          type="text"
          className={`form-control width100 ${errorMessage ? 'is-invalid' : ''}`}
          minLength={2}
          value={link}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // Call your function here
              handleAddLink(e.target.value);
            }
          }}
          onChange={(e) => setLink(e.target.value)}
          debounceTimeout={500}
        />

        {errorMessage ? (
          <div className="invalid-feedback">{errorMessage}</div>
        ) : tempLinks.length > 0 ? (
          <div>
            {tempLinks.map((link, index) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }} key={index}>
                <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
                  <i className="fa fa-link" style={{ marginRight: '5px', fontSize: '16px' }} />
                  <p style={{ margin: 0 }}>{link}</p>
                </div>
                <div>
                  <i className="fa fa-trash" style={{ cursor: 'pointer', fontSize: '18px' }} onClick={() => removeLink(link)} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p style={{ marginTop: '10px' }}>No links available.</p>
        )}
      </div>
    </Modal>
  );
}

AddLinkCourseModal.propTypes = {
  id: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  onLinkAdded: PropTypes.func.isRequired,
};

export default memo(AddLinkCourseModal);
