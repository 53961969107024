import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { useSelector } from 'react-redux';
import { getCourseCategories, updateCourse } from '../../../api/coursesApi';
import { DebounceInput } from 'react-debounce-input';
import Select from '../Select';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';
import DatePicker from 'react-datepicker';

function EditRequestedCourseModal({ id, course, onCourseSubmit }) {
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  const [updatedCourse, setUpdatedCourse] = useState(course);

  const [durationValue, setDurationValue] = useState('');
  const [unitDuration, setUnitDuration] = useState('');

  useEffect(() => {
    if (course && course.duration) {
      setUpdatedCourse(course);
      const parts = course.duration.split(' ');
      if (parts.length === 2) {
        setDurationValue(parts[0]); // Set the numeric part
        setUnitDuration(parts[1]); // Set the unit part
      }
    }
  }, [course, course.duration]);

  const [courseCategories, setCourseCategories] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const handleUpdateCourse = async () => {
    try {
      if (updatedCourse.status === 'Denied') {
        const updatedCourseWithStatus = { ...updatedCourse, status: 'Requested' };
        await updateCourse(updatedCourseWithStatus);
      } else {
        await updateCourse(updatedCourse);
      }
      toast.success('You have successfully updated your course request', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

      if (onCourseSubmit) {
        onCourseSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const isConfirmButtonDisabled = () => {
    // Exclude 'link' and 'attachment' properties from the check
    const propertiesToCheck = Object.keys(updatedCourse).filter((key) => key !== 'link' && key !== 'attachment');
    return propertiesToCheck.some((key) => updatedCourse[key] === '' || !unitDuration);
  };

  useEffect(() => {
    const fetchCourseCategories = async () => {
      try {
        const result = await getCourseCategories();
        setCourseCategories(result.data);
      } catch (error) {
        throw new Error('Error fetching course categories');
      }
    };

    fetchCourseCategories();
  }, []);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleDurationChange = (e) => {
    setDurationValue(e.target.value);
    // Update the full duration string in the course object
    setUpdatedCourse({ ...updatedCourse, duration: `${e.target.value} ${unitDuration}` });
  };

  const handleDateChange = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date time to midnight to compare only dates

    if (date && date >= currentDate) {
      const formattedDate = date.toLocaleDateString('en-GB'); // Formats date as dd/MM/yyyy

      setUpdatedCourse({ ...updatedCourse, startDate: formattedDate });
    }
  };

  const handleUnitChange = (e) => {
    setUnitDuration(e.target.value);
    // Update the full duration string in the course object
    setUpdatedCourse({ ...updatedCourse, duration: `${durationValue} ${e.target.value}` });
  };

  const closeModal = () => {
    setUpdatedCourse(course);
  };

  function isValidDate(date) {
    return date && !isNaN(new Date(date).getTime());
  }

  return (
    <Modal
      isLarge={true}
      id={id}
      icon="pe-7s-pen"
      iconGradient="bg-green-blue-top"
      title="Edit requested course"
      contentClass="modal-body-center-h"
      confirmText="UPDATE REQUEST"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleUpdateCourse}>
      <div style={{ width: '100%' }}>
        {currentStep === 1 && (
          <>
            <div className="custom-grid">
              <div>
                <label>Name</label>
                <DebounceInput
                  type="text"
                  className="form-control"
                  minLength={2}
                  value={updatedCourse.name}
                  onChange={(e) => setUpdatedCourse({ ...updatedCourse, name: e.target.value })}
                  debounceTimeout="500"
                />
              </div>

              <div>
                <label>Category</label>
                <Select
                  options={[
                    { value: '', text: 'Select a category' },
                    ...courseCategories.map((category) => ({
                      value: category.name,
                      text: category.name,
                    })),
                  ]}
                  selected={updatedCourse.category}
                  changeHandler={(selectedValue) => {
                    const selectedCategory = courseCategories.find((category) => category.name === selectedValue);
                    if (selectedCategory) {
                      setUpdatedCourse({ ...updatedCourse, category: selectedValue, courseCategoryId: selectedCategory.id });
                    } else {
                      // Handle case when no category is selected
                      setUpdatedCourse({ ...updatedCourse, category: '', courseCategoryId: '' });
                    }
                  }}
                  defaultEmpty={false}
                />
              </div>

              <div>
                <label>Duration</label>
                <div style={{ display: 'flex' }}>
                  <DebounceInput
                    style={{ width: '70px' }}
                    type="number"
                    className="form-control"
                    minLength={1}
                    min={1}
                    value={durationValue}
                    onChange={handleDurationChange}
                    debounceTimeout={500}
                  />
                  <select value={unitDuration} onChange={handleUnitChange} className="form-control">
                    <option value="">Select Unit</option>
                    <option value="hours">hours</option>
                    <option value="days">days</option>
                  </select>
                </div>
              </div>

              <div>
                <label>Type of course</label>
                <Select
                  options={[
                    { value: '', text: 'Select a type' },
                    { value: 'Free to follow', text: 'Free to follow' },
                    { value: 'To request', text: 'To request' },
                  ]}
                  selected={updatedCourse.typeOfCourse}
                  changeHandler={(selectedValue) => setUpdatedCourse({ ...updatedCourse, typeOfCourse: selectedValue })}
                  defaultEmpty={false}
                />
              </div>

              <div>
                <label>Company</label>
                <DebounceInput
                  type="text"
                  className="form-control"
                  minLength={2}
                  value={updatedCourse.company}
                  onChange={(e) => setUpdatedCourse({ ...updatedCourse, company: e.target.value })}
                  debounceTimeout="500"
                />
              </div>

              <div>
                <label>Place</label>
                <Select
                  options={[
                    { value: '', text: 'Select a place' },
                    { value: 'Online', text: 'Online' },
                    { value: 'Class', text: 'Class' },
                  ]}
                  selected={updatedCourse.place}
                  changeHandler={(selectedValue) => setUpdatedCourse({ ...updatedCourse, place: selectedValue })}
                  defaultEmpty={false}
                />
              </div>

              <div>
                <label>Certificate</label>
                <Select
                  options={[
                    { value: '', text: 'Select certificate status' },
                    { value: 'true', text: 'Yes' },
                    { value: 'false', text: 'No' },
                  ]}
                  selected={updatedCourse.certificate ? 'true' : 'false'}
                  changeHandler={(selectedValue) => setUpdatedCourse({ ...updatedCourse, certificate: selectedValue })}
                  defaultEmpty={false}
                />
              </div>

              <div>
                <label>Price</label>
                <DebounceInput
                  type="number"
                  className="form-control"
                  minLength={2}
                  min={0}
                  value={updatedCourse.price}
                  onChange={(e) => setUpdatedCourse({ ...updatedCourse, price: e.target.value })}
                  debounceTimeout="500"
                />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: '30px', marginBottom: '30px', gap: '10px' }}>
              <div style={{ width: '100%' }}>
                <label>Description *</label>
                <textarea
                  placeholder="Enter description here..."
                  rows={5}
                  className="form-control"
                  value={updatedCourse.description}
                  onChange={(e) => setUpdatedCourse({ ...updatedCourse, description: e.target.value })}></textarea>
              </div>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <div className="width100" style={{ marginTop: '30px', marginBottom: '30px' }}>
            <div className="width100">
              <label>Motivation request</label>
              <textarea
                placeholder="Please provide your motivation for requesting this new course"
                rows={8}
                className="form-control width100"
                value={updatedCourse.motivationRequest}
                onChange={(e) => setUpdatedCourse({ ...updatedCourse, motivationRequest: e.target.value })}></textarea>
            </div>
          </div>
        )}

        <div style={{ margin: 'auto', textAlign: 'center' }}>
          {currentStep > 1 && (
            <button type="button" className="btn btn-primary documents-row-button" onClick={handlePreviousStep}>
              <i style={{ marginRight: '10px', fontSize: 16 }} className="fas fa-arrow-left"></i>
              Go Back
            </button>
          )}
          {currentStep < 2 && (
            <button type="button" className="btn btn-primary documents-row-button" onClick={handleNextStep}>
              Next step
              <i style={{ marginLeft: '10px', fontSize: 16 }} className="fas fa-arrow-right"></i>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

EditRequestedCourseModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(EditRequestedCourseModal);
