import api from './baseApi';

export function getPublicLink(publicLink) {
  return api.get(`/validateTimesheet/${publicLink}`);
}

export function approveTimesheet(timesheet, publicLink) {
  return api.put(
    `/timesheets/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}/${publicLink}/approve`
  );
}

export function rejectTimesheet(timesheet, publicLink, rejectionReason) {
  return api.put(
    `/timesheets/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}/${publicLink}/reject`,
    rejectionReason
  );
}
