import React, { useEffect, useState } from 'react';
import '../../assets/css/courses/style.css';
import { getCourseCategories } from '../../api/coursesApi';
import { useHistory } from 'react-router-dom';
import Loading from '../common/Loading';
import { getSasUriForBlob } from '../../api/documentsApi';
import { useSelector } from 'react-redux';

export default function CourseCategoriesPage() {
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getCourseCategories();
        const categoriesWithImages = await Promise.all(
          response.data.map(async (category) => {
            if (!category.imageReference.includes('/')) {
              const imageUrl = await handleImageDownload(category.imageReference);
              return { ...category, imageUrl };
            }
            return category;
          })
        );

        setCategories(categoriesWithImages);
      } catch (error) {
        throw new Error('Error fetching course categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function navigateToMyPersonalInformation() {
    history.push('/courses/personal');
  }

  function navigateToCourses(category) {
    const lowercaseCategoryName = category.name.toLowerCase();
    history.push({
      pathname: `/courses/category/${lowercaseCategoryName}`,
      state: { category: category },
    });
  }

  async function handleImageDownload(uri) {
    try {
      const response = await getSasUriForBlob(uri);
      const imageUrl = response.data;

      return imageUrl;
    } catch (error) {
      return null;
    }
  }

  // TEMP fix
  if (!currentLoggedUserId) {
    return (
      <div className="container-fluid">
        <h1>No DB profile found for user</h1>
        <p>Contact an administrator to link a profile.</p>
      </div>
    );
  }

  return (
    <div className="container-fluid" style={{ marginBottom: '10px' }}>
      <button type="button" className="btn btn-primary mb-3" onClick={() => navigateToMyPersonalInformation()}>
        Manage Personal information
      </button>

      {loading ? (
        <Loading message="Hang on, we're loading course categories." />
      ) : (
        <div className="image-grid">
          {categories.map((category, index) => (
            <div className="" key={index}>
              {category.imageReference.includes('/') ? (
                <img src={require(`../../assets/images/categories_temp/${category.imageReference.split('/').pop()}`)} alt={category.name} className="category-image" />
              ) : (
                // download the image from the
                <img src={category.imageUrl} alt={category.name} className="category-image" />
              )}
              <button type="button" className="mt-2 btn btn-primary width100" onClick={() => navigateToCourses(category)}>
                {category.name}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
