import {
    FETCH_PROJECTS_SUCCESS,
} from './actions';

export default function projectsReducer(state = { ...PROJECTS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_PROJECTS_SUCCESS: {
        const projects = action.projects.projects ? action.projects.projects : action.projects;
        return {
          ...state,
          projectsList: [...projects],
          minProjectStartDate: action.minProjectStartDate,
          maxProjectEndDate: action.maxProjectEndDate,
        };
      }

    default:
      return state;
  }
}

export const PROJECTS_BASE_STATE = {
  projectsList: [],
  minProjectStartDate: null,
  maxProjectEndDate: null,
  errorMessage: undefined,
};
