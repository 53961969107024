import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import {
  addExpense,
  removeExpense,
  setExpenseAmount,
  setExpenseKey,
  setExpenseRemark,
} from '../../../redux/timesheets/actions';
import InputWithSymbol from '../InputWithSymbol';
import Select from '../Select';

const EXPENSE_OPTIONS = [
  { type: 1, text: 'Expense' },
  { type: 2, text: 'Transport' },
];

const MIN_AMOUNT = 0;
const MAX_AMOUNT = Number.MAX_SAFE_INTEGER;

function ExpensesModal({ timesheet, entryIndex, expenses, isWeekend, dayIndex }) {
  const dispatch = useDispatch();
  const fieldsAreDisabled = useMemo(() => timesheet.state === 1 || timesheet.state === 2, [timesheet.state]);
  const handleAddExpense = useCallback(
    () => dispatch(addExpense(timesheet, dayIndex, entryIndex)),
    [dayIndex, dispatch, entryIndex, timesheet]
  );
  const handleRemoveExpense = useCallback(
    (expenseindex) => dispatch(removeExpense(timesheet, dayIndex, entryIndex, expenseindex)),
    [dayIndex, dispatch, entryIndex, timesheet]
  );
  const handleSetExpenseKey = useCallback(
    (expenseIndex, key) => dispatch(setExpenseKey(timesheet, dayIndex, entryIndex, expenseIndex, key)),
    [dayIndex, dispatch, entryIndex, timesheet]
  );
  const handleSetExpenseAmount = useCallback(
    (expenseIndex, key) =>
      dispatch(setExpenseAmount(timesheet, dayIndex, entryIndex, expenseIndex, key, MIN_AMOUNT, MAX_AMOUNT)),
    [dayIndex, dispatch, entryIndex, timesheet]
  );
  const handleSetExpenseRemark = useCallback(
    (expenseIndex, key) => dispatch(setExpenseRemark(timesheet, dayIndex, entryIndex, expenseIndex, key)),
    [dayIndex, dispatch, entryIndex, timesheet]
  );
  const mappedExpenses = useMemo(
    () =>
      expenses.map((expense, i) => {
        return (
          <tr key={`day-${dayIndex}-entry-${entryIndex}-expenses-${i}`} className={`timesheet-detail-row${isWeekend ? ' weekend' : ''}`}>
            <td className="timesheet-detail-row--date">
              <div>
                <p>
                  &nbsp;
                  <i className="pe-7s-cash icon-gradient bg-green-fade float-right" style={{ paddingLeft: '3px' }} />
                </p>
              </div>
            </td>
            <td className="timesheet-detail-row--description">
              <Select
                options={EXPENSE_OPTIONS}
                selected={expense.type}
                disabled={fieldsAreDisabled}
                changeHandler={(value) =>
                  handleSetExpenseKey(i, {
                    type: value,
                  })
                }
                defaultEmpty={false}
              />
            </td>

            <td className="timesheet-detail-row--description">
              <DebounceInput
                className="form-control"
                type="text"
                maxLength={40}
                title={fieldsAreDisabled ? '' : 'Max 40 characters'}
                disabled={fieldsAreDisabled}
                value={expense.remarks || ''}
                onChange={(e) =>
                  handleSetExpenseRemark(i, {
                    remarks: e.target.value,
                  })
                }
                debounceTimeout="500"
              />
            </td>
            <td className="timesheet-detail-row--amount">
              <InputWithSymbol
                type="number"
                decimal={2}
                value={expense.amount}
                min={MIN_AMOUNT}
                max={MAX_AMOUNT}
                disabled={fieldsAreDisabled}
                symbolPrefix={expense.type === 'Expense' ? '€' : undefined}
                symbolSuffix={expense.type !== 'Expense' ? 'km' : undefined}
                changeHandler={(e) =>
                  handleSetExpenseAmount(i, {
                    amount: e,
                  })
                }
              />
            </td>
            <td className="timesheet-detail-row--actions">
              {!fieldsAreDisabled && (
                <>
                  <i
                    className="pe-7s-plus icon-gradient bg-yellow-fade timesheet-detail-action"
                    data-toggle="tooltip"
                    title="Add new expense"
                    onClick={handleAddExpense}
                  />
                  <i
                    className="pe-7s-trash icon-gradient bg-purple-fade timesheet-detail-action"
                    data-toggle="tooltip"
                    title="Remove expense"
                    onClick={() => handleRemoveExpense(i)}
                  />
                </>
              )}
            </td>
          </tr>
        );
      }),
    [dayIndex, entryIndex, expenses, fieldsAreDisabled, isWeekend, handleAddExpense, handleRemoveExpense, handleSetExpenseAmount, handleSetExpenseKey, handleSetExpenseRemark]
  );
  return <>{mappedExpenses}</>;
}

ExpensesModal.propTypes = {
  timesheet: PropTypes.any,
  entryIndex: PropTypes.any,
  expenses: PropTypes.array,
  dayIndex: PropTypes.number,
  isWeekend: PropTypes.bool,
};

export default memo(ExpensesModal);
