import React from 'react';
import ContractsListCard from '../components/dashboard/contracts/ContractsListCard';

function ContractsPage() {
  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <ContractsListCard />
        </div>
      </div>
    </div>
  );
}

export default ContractsPage;
