import React, { useCallback, useState } from 'react';
import Card from '../common/Card';
import Tabs from '../common/Tabs';
import Tab from '../common/Tab';
import MyCoursesPage from './MyCourses';
import OverviewPage from './Overview';
import MyEnrollmentsPage from './MyEnrollments';

export default function PersonalInformationPage() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = useCallback((selectedTab) => {
    setSelectedTab(selectedTab);
  }, []);

  const goToMyEnrollments = () => {
    setSelectedTab(2); // Switch to "My Enrollments" tab
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <OverviewPage goToMyEnrollments={goToMyEnrollments} />;
      case 1:
        return <MyCoursesPage />;
      case 2:
        return <MyEnrollmentsPage />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <Card title="Personal Information" icon="pe-7s-user" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
            <Tabs value={selectedTab} onChange={(_, tabValue) => handleChangeTab(tabValue)} TabIndicatorProps={{ style: { background: 'var(--accent-color)' } }}>
              <Tab label="Overview" />
              <Tab label="My courses" />
              <Tab label="My enrollments" />
            </Tabs>

            {renderTabContent()}
          </Card>
        </div>
      </div>
    </div>
  );
}
