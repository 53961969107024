import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Card from '../common/Card';

function InvalidLinkPage({ message }) {
  return (
    <div className="validation-page-wrapper">
      <div className="logo-position">
        <div className="validation-brand--logo"></div>
      </div>
      <Card
        title="Oops.. You landed on an invalid link!"
        icon="pe-7s-close"
        iconGradient="bg-red-fade"
        cardClass="validation-card"
        contentClass="center-x-y pb-3">
        <p>{message}</p>
        <p className="text-center">
          Try refreshing your page.
          <br /> If the problem still persists, please contact <a href={"mailto:info@t4t.rocks"}>Team4Talent</a> for more information.
        </p>
      </Card>
    </div>
  );
}

InvalidLinkPage.propTypes = {
  message: PropTypes.string,
};

export default memo(InvalidLinkPage);
