import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../common/LoadingIcon';
import Card from './Card';

function LoadingCard({ message, ...props }) {
  return (
    <Card {...props} contentClass="no-current-card">
      <div className="row-fluid">
        <div className="col-lg-12">
          <div>
            <LoadingIcon message={message} />
          </div>
        </div>
      </div>
    </Card>
  );
}

LoadingCard.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconGradient: PropTypes.string,
};

export default memo(LoadingCard);
