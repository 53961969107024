import {
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FOR_USER,
  FETCH_PROFILE_FOR_USER_FAILED,
  FETCH_PROFILE_FOR_USER_SUCCESS,
} from './actions';

export default function profileReducer(state = { ...PROFILE_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_PROFILE:
    case FETCH_PROFILE_FOR_USER:
      return { ...state, errorMessage: PROFILE_BASE_STATE.errorMessage };

    case FETCH_PROFILE_SUCCESS:
    case FETCH_PROFILE_FOR_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };

    case FETCH_PROFILE_FAILED:
    case FETCH_PROFILE_FOR_USER_FAILED:
      return { ...state, errorMessage: action.error };

    default:
      return state;
  }
}

export const PROFILE_BASE_STATE = {
  errorMessage: null,
  user: null,
};
