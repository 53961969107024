import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { DebounceInput } from 'react-debounce-input';
import * as inputValidation from '../../service/inputValidationService';

function InputWithSymbol({ type, value, symbolPrefix, symbolSuffix, disabled, min, max, changeHandler, decimal }) {
  const errorMessage = useMemo(() => {
    if (min == null && max == null) return null;
    if (min != null && value < min) return `Min value is ${min}`;
    if (max != null && value > max) return `Max value exceeded`;

    return null;
  }, [max, min, value]);
  return (
    <>
      <div className="form-control" id={disabled ? 'disabledInputWithSymbolContainer' : 'inputWithSymbolContainer'}>
        {symbolPrefix != null && (
          <>
            <p>{symbolPrefix}</p>
            <span>&nbsp;</span>
          </>
        )}
        <DebounceInput
          id="inputWithSymbol"
          className="no-borders"
          type={type}
          value={value}
          disabled={disabled}
          min="0"
        data-decimal={decimal}
        onChange={(e) => {
            if (changeHandler != null) changeHandler(e.target.value);
          }}
        onInput={(e) => {
          if(decimal){
            inputValidation.enforceDecimal(e, e.target);
          }
        }}
        debounceTimeout="1000"
        />
        {symbolSuffix != null && (
          <>
            <span>&nbsp;</span>
            <p>{symbolSuffix}</p>
          </>
        )}
      </div>
      {(min != null || max != null) && errorMessage != null && <p className="error-text">{errorMessage}</p>}
    </>
  );
}

InputWithSymbol.propTypes = {
  value: PropTypes.any.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  symbolPrefix: PropTypes.string,
  symbolSuffix: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  changeHandler: PropTypes.func,
  decimal: PropTypes.number,
};

export default memo(InputWithSymbol);
