import api from './baseApi';

export function getUserTracks(userId, skip, take) {
    return api.get(`userTracks/client/${userId}?skip=${skip}&take=${take}`);
}

export function updateMilestoneStatus(userTrack, milestone) {
  return api.patch(`userTracks/client/updateMilestoneStatus/${userTrack.trackId}`, milestone);
}

export function completeUserTrack(userTrack, milestones) {
  return api.patch(`userTracks/client/completeUserTrack/${userTrack.trackId}`, milestones);
}

export function updateCompletionDate(userTrackId, milestone) {
  return api.patch(`userTracks/client/updateCompletionDate/${userTrackId}`, milestone);
}

export function updateSelectedMilestonesForUsertrack(userTrackId, selectedMilestonesIds) {
  return api.put(`userTracks/${userTrackId}`, selectedMilestonesIds);
}

export function deleteUserTrack(userTrack) {
  return api.delete(`userTracks/${userTrack.trackId}`)
}