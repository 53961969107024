import React, { useEffect } from 'react';
import Redirecting from './Redirecting';
import { useAuth } from 'react-oidc-context';
import Authenticated from './Authenticated';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchSetUser } from '../redux/auth/actions';
import { fetchProfile } from '../redux/profile/actions';

function App() {
  const user = useSelector((state) => state.auth.user);
  const { errorMessage: profileErrorMessage, user: profileUser } = useSelector((state) => state.profile);
  const auth = useAuth();
  const dispatch = useDispatch();

  if (!auth.isLoading && !auth.user) {
    auth.signinRedirect();
  }

  useEffect(() => {
    if (auth.user) {
      dispatch(dispatchSetUser(auth.user));
      if (!profileErrorMessage && !profileUser) {
        dispatch(fetchProfile());
      }
    }
  }, [auth.user, dispatch, profileErrorMessage, profileUser]);

  if (!auth.isLoading && auth.isAuthenticated && user && profileErrorMessage == null && profileUser) {
    return <Authenticated></Authenticated>;
  }
  return <Redirecting></Redirecting>;
}

export default App;
