import api from './baseApi';

export function getNews(params) {
  return api.get('/news/client', params);
}

export function getNewsItem(id, params) {
  return api.get(`/news/${id}`, params);
}

export function getAzureBlobUrl(params) {
  return api.get('/news/azureUri', params);
}

export function getNewsCount(params) {
    return api.get('/news/client/count', params);
}