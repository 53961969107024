import PropTypes from 'prop-types';
import React, { memo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';

function ViewDenyCourseRequestModal({ id, course }) {
  const isConfirmButtonDisabled = () => {
    return true;
  };

  const handleConfirm = () => {};

  return (
    <Modal
      id={id}
      icon="pe-7s-info"
      iconGradient="bg-green-blue-top"
      title="Feedback denial course request"
      contentClass="modal-body-center-h"
      confirmText="SAVE"
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleConfirm}>
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <label>Feedback</label>
          <div
            className="width100"
            style={{ border: '1px solid #ccc', padding: '8px', borderRadius: '4px', minHeight: '100px' }}
            dangerouslySetInnerHTML={{ __html: course.motivationDenyRequest }}></div>
        </div>
      </div>
    </Modal>
  );
}

ViewDenyCourseRequestModal.propTypes = {
  id: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
};

export default memo(ViewDenyCourseRequestModal);
