import { getTransactionsByUserId, getCreditsByUserId } from '../../api/transactionsApi';
import { showRequestFailedToast } from '../../service/toastService';

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILED = 'FETCH_TRANSACTIONS_FAILED';
export const FETCH_CREDITS = 'FETCH_CREDITS';
export const FETCH_CREDITS_SUCCESS = 'FETCH_CREDITS_SUCCESS';
export const FETCH_CREDITS_FAILED = 'FETCH_CREDITS_FAILED';

export const fetchTransactionsByUserId = (userId, skip, take) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_TRANSACTIONS });
    try {
      const response = await getTransactionsByUserId(userId, {skip, take});
      return dispatch({
        type: FETCH_TRANSACTIONS_SUCCESS,
        transactions: response.data.data,
        skip,
        take,
        totalCount: response.data.totalCount,
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_TRANSACTIONS_FAILED, error });
    }
  };
};

export const fetchCreditsByUserId = (userId) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_CREDITS });
    try {
      const response = await getCreditsByUserId(userId);
      return dispatch({ type: FETCH_CREDITS_SUCCESS, credits: response.data });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_CREDITS_FAILED, error });
    }
  };
};
