import { FETCH_CONTRACTS_SUCCESS } from './actions';
import moment from 'moment';

export default function contractsReducer(state = { CONTRACTS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_CONTRACTS_SUCCESS: {
      const now = moment();
      return {
        ...state,
        contractsList: action.contracts.contracts
          .map((contract) => {
            return {
              ...contract,
              isActive: !contract.endDate || moment(contract.endDate).isAfter(now),
              endDate: contract.endDate && new Date(contract.endDate),
              startDate: contract.startDate && new Date(contract.startDate),
            };
          })
          .sort((a, b) => b.endDate - a.endDate),
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
        hasActiveContracts : action.hasActiveContracts,
      };
    }

    default:
      return state;
  }
}

export const CONTRACTS_BASE_STATE = {
  contractsList: [],
  skip: null,
  take: null,
  totalCount: null,
  hasActiveContracts : null,
};
