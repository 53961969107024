import api from './baseApi';

export function getDocuments(params) {
  return api.get('/documents/tenantDocuments', params);
}

export function getDocument(documentId) {
  return api.get(`/documents/admin/document/${documentId}`);
}

export function getSasUriForBlob(documentId) {
  return api.get(`/documents/admin/sasUri/azureBlobStorageSasUri/${documentId}`);
}

export function downloadDocument(uri) {
  return api.getFileFormBlobStorage(uri);
}

export function uploadDocument(document) {
  const courseDocument = {
    documentType: document.documentType,
    contentType: document.contentType,
    extraInfo: document.extraInfo,
    version: document.version,
    published: document.published,
    tenantIds: document.tenants,
    userTypeIds: document.userTypes,
    uploadedOn: document.uploadedOn,
    name: document.name,
  };

  const modelData = JSON.stringify(courseDocument);
  const formData = document.formData;
  formData.append('data', modelData);

  return api.post(`/documents/admin/add/azureBlobStorage`, formData);
}

export function deleteDocument(documentId) {
  return api.delete(`/documents/admin/delete/document/${documentId}`);
}
