import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deleteCourse, getCoursesFromUser, updateCourse } from '../../api/coursesApi';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../common/Card';
import Loading from '../common/Loading';
import Table from '../common/Table';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getSuccesToastPropsBy } from '../../service/toastService';
import { showModal } from '../../redux/system/actions';
import EditRequestedCourseModal from '../common/modals/EditRequestedCourseModal';
import { useHistory } from 'react-router-dom';
import ViewDenyCourseRequest from '../common/modals/ViewDenyCourseRequest';
import Tabs from '../common/Tabs';
import Tab from '../common/Tab';

const CREATE_TS_MODAL_ID = 'createTS';
const VIEW_TS_MODAL_ID = 'viewTS';

const MyCoursesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleShowModal = useCallback((modalId, course) => dispatch(showModal(modalId, course)), [dispatch]);

  const handleChangeTab = useCallback(
    (selectedTab) => {
      setSelectedTab(selectedTab);
      fetchMyCourses(selectedTab);
    },
    [dispatch, currentLoggedUserId]
  );

  useEffect(() => {
    fetchMyCourses(selectedTab);
  }, [currentLoggedUserId]);

  const deleteCourseFunction = async (course) => {
    try {
      await deleteCourse(course.id);
      toast.success('You have successfully deleted the course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      fetchMyCourses();
    } catch (error) {
      toast.error('Something went wrong! Try again later');
    }
  };

  const handleConfirm = (confirmed) => {
    setIsOpen(false);
    if (confirmed) {
      deleteCourseFunction(course);
    }
  };

  const fetchMyCourses = useCallback(async (status) => {
    setLoading(true);
    try {
      const courses = await getCoursesFromUser(currentLoggedUserId);

      const filteredCourses = courses.data.filter((course) => {
        if (status === 0) return true;
        if (status === 1) return course.status === 'Approved';
        if (status === 2) return course.status === 'Requested';
        if (status === 3) return course.status === 'Denied';
        if (status === 4) return course.status === 'Finished';
        return false;
      });

      filteredCourses.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      setCourses(filteredCourses);
      setLoading(false);
    } catch (error) {
      throw new Error('Error fetching courses from user');
    }
  });

  const handleRefreshCourses = useCallback(() => {
    fetchMyCourses(selectedTab);
  }, [course, selectedTab]);

  const columns = useMemo(
    () => (
      <>
        <th className="col-xs-1">#</th>
        <th className="col-xs-3">Name</th>
        <th className="col-xs-2">Startdate</th>
        <th className="col-xs-2">Category</th>
        <th className="col-xs-3">Company</th>
        <th className="col-xs-2">Place</th>
        <th className="col-xs-2">Duration</th>
        <th className="col-xs-1">Certificate</th>
        <th className="col-xs-1">Price</th>
        <th className="col-xs-1" style={{ whiteSpace: 'nowrap' }}>
          Type of course
        </th>
        <th className="col-xs-1">Status</th>
        <th className="col-xs-1">Actions</th>
      </>
    ),
    []
  );

  const navigateDetailPage = (course) => {
    history.push({
      pathname: `/courses/detail/${course.id}`,
      state: { course: course },
    });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const mappedCourses = useMemo(
    () =>
      courses.map((course, index) => (
        <tr key={index} className={`clickable ${course.status === 'Approved' ? 'green-row' : 'red-row'}`} onClick={() => navigateDetailPage(course)}>
          <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
          <td style={{ verticalAlign: 'middle', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{course.name}</td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            {course.selfStudyCourse ? 'Not applicable (self study course)' : formatDate(course.startDate)}
          </td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{course.category}</td>
          <td style={{ verticalAlign: 'middle', maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{course.company}</td>
          <td style={{ verticalAlign: 'middle' }}>{course.place}</td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{course.duration}</td>
          <td style={{ verticalAlign: 'middle' }}>{course.certificate ? 'Yes' : 'No'}</td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>€ {course.price}</td>
          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{course.typeOfCourse}</td>

          <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
            {course.status === 'Requested' ? (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-yellow-fade">
                {course.status}
              </div>
            ) : course.status === 'Approved' ? (
              <div style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-green-blue">
                {course.status}
              </div>
            ) : (
              <div style={{ margin: 0 }}>
                <span style={{ margin: 0 }} className="badge badge-pill t4t-badge bg-red-fade mr-2">
                  {course.status}
                </span>
                <span style={{ margin: 0 }}>
                  <i
                    onClick={(event) => {
                      event.stopPropagation();
                      setCourse(course);
                      handleShowModal(VIEW_TS_MODAL_ID, course);
                    }}
                    style={{ margin: 0, verticalAlign: 'middle' }}
                    className="pe-7s-info icon-gradient bg-red-fade"
                  />
                </span>
              </div>
            )}
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            {course.status === 'Requested' || course.status === 'Denied' ? (
              <>
                <i
                  onClick={(event) => {
                    event.stopPropagation();
                    setCourse(course);
                    handleShowModal(CREATE_TS_MODAL_ID, course);
                  }}
                  style={{ margin: 0 }}
                  title="Edit course"
                  className={`pe-7s-pen icon-gradient bg-green-blue-top`}
                />
                <i
                  style={{ margin: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(true);
                    setCourse(course);
                  }}
                  title="Delete course"
                  className={`pe-7s-trash icon-gradient bg-red-fade pl-2`}></i>
              </>
            ) : (
              <>None</>
            )}
          </td>
        </tr>
      )),
    [courses]
  );

  return (
    <div className="container-fluid" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', color: '#ffffff' }}>
      <div className="mb-2">
        <Tabs value={selectedTab} onChange={(_, tabValue) => handleChangeTab(tabValue)} TabIndicatorProps={{ style: { background: 'var(--accent-color)' } }}>
          <Tab label="All" />
          <Tab label="Approved courses" />
          <Tab label="Requested courses" />
          <Tab label="Denied courses" />
          <Tab label="Finished courses" />
        </Tabs>
      </div>

      <Card title="My courses" icon="pe-7s-notebook" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
        {loading ? (
          <Loading message="Hang on, we're loading your courses." className="mt-5" />
        ) : (
          <>
            {courses.length === 0 ? (
              <div className="mt-5 text-center">
                <h4>No courses found</h4>
                <p style={{ color: '#999' }}>You dont have any courses.</p>
              </div>
            ) : (
              <>
                <EditRequestedCourseModal id={CREATE_TS_MODAL_ID} course={course} onCourseSubmit={handleRefreshCourses} />
                <ViewDenyCourseRequest id={VIEW_TS_MODAL_ID} course={course} />
                <Table columns={columns} data={mappedCourses} showPagination={false} />
              </>
            )}
          </>
        )}
      </Card>

      {isOpen && (
        <div className="confirmation-overlay modal backdrop-modal">
          <div className="confirmation-popup">
            <p className="">Are you sure?</p>
            <div className="button-container">
              <button className="btn btn-secondary" onClick={() => handleConfirm(false)}>
                No
              </button>
              <button className="btn btn-primary" onClick={() => handleConfirm(true)}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .confirmation-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
          text-align: center;
        }

        .btn {
          margin: 0 5px;
        }

        .confirmation-popup {
          background-color: #252525;
          padding: 20px;
        }

        .button-container {
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default MyCoursesPage;
