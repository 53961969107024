import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocument, fetchDocuments } from '../../redux/documents/actions';
import Card from '../common/Card';
import Loading from '../common/Loading';
import LoadingIcon from '../common/LoadingIcon';
import Table from '../common/Table';
import moment from 'moment';

function DocumentsListCard() {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents.documentsList);
  const { skip, take, totalCount } = useSelector((state) => state.documents);
  const { nameToDisplay } = useSelector((state) => state.users);
  const { isFetchingDocuments, triedFetchingDocuments } = useSelector((state) => state.system);
  const { tenant_id: tenantIdLoggedInUser } = useSelector((state) => state.auth.user.profile);
  const { tenantId: tenantIdSelectedUser, managingOtherUser } = useSelector((state) => state.users.selectedUser);

  const getDocumentIcon = useCallback((contentType) => {
    switch (contentType) {
      case 'xlsx':
      case 'xls':
        return <i className="fa fa-file-excel fa-16" />;
      case 'docx':
      case 'doc':
        return <i className="fa fa-file-word fa-16" />;
      case 'pdf':
        return <i className="fa fa-file-pdf fa-16" />;
      default:
        return <i className="fa fa-file fa-16" />;
    }
  }, []);

  const handleDownloadDocument = useCallback(
    (documentId, documentName) => dispatch(downloadDocument(documentId, documentName)),
    [dispatch]
  );

  const handleChangePage = (pageIndex) => {
    dispatch(fetchDocuments(pageIndex, take, managingOtherUser ? tenantIdSelectedUser : tenantIdLoggedInUser, true));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchDocuments(0, event.target.value, managingOtherUser ? tenantIdSelectedUser : tenantIdLoggedInUser, true));
  };

  const columns = (
    <>
      <th className="column-zero-width" />
      <th>Name</th>
      <th>Content Type</th>
      <th>Uploaded On</th>
      <th>Version</th>
      <th className="column-zero-width" />
    </>
  );

  const mappedDocuments = useMemo(
    () =>
      documents.map((document, i) => {
        return (
          <tr key={i} className="highlight-row">
            <td>{skip * take + i + 1}</td>
            <td>
              {getDocumentIcon(document.contentType)} {document.name}
            </td>
            <td>{document.contentType}</td>
            <td>{moment(document.uploadedOn).format('D MMMM YYYY')}</td>
            <td>{document.version}</td>
            <td style={{ display: 'flex', justifyContent: 'right' }}>
              {document.isDownloading && <LoadingIcon style={{ alignSelf: 'center' }} />}
              <button
                type="button"
                disabled={document.isDownloading}
                className="btn btn-primary btn-sm documents-row-button pull-right"
                onClick={() => handleDownloadDocument(document.id, document.name)}>
                <i className="pe-7s-download icon-gradient bg-white"></i> Download
              </button>
            </td>
          </tr>
        );
      }),
    [documents, getDocumentIcon, handleDownloadDocument, skip, take]
  );

  return (
    <Card
      title={`${nameToDisplay} Documents`}
      icon="pe-7s-folder"
      iconGradient="bg-green-blue-top"
      contentClass={mappedDocuments.length > 0 && !isFetchingDocuments && triedFetchingDocuments ? 'card-table-content' : 'no-current-card'}>
      {isFetchingDocuments || !triedFetchingDocuments ? (
        <Loading message="Hang on, we're loading your documents." />
      ) : mappedDocuments.length > 0 ? (
        <>
          <p>
            These are general documents that are important to you while you work for us.
            <br />
            Please read them carefully.
          </p>
          <Table
            columns={columns}
            data={mappedDocuments}
            totalCount={totalCount}
            skip={skip}
            take={take}
            changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
            changeRowsPerPageHandler={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <div>You have no documents yet.</div>
      )}
    </Card>
  );
}

export default memo(DocumentsListCard);
