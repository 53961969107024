import { getTenants } from "../../api/tenantsApi";
import { showRequestFailedToast } from "../../service/toastService";

export const FETCH_TENANTS = "FETCH_TENANTS";
export const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
export const FETCH_TENANTS_FAILED = "FETCH_TENANTS_FAILED";

export function fetchTenants() {
    return async (dispatch) => {
        dispatch({ type: FETCH_TENANTS });
        try {
          const response = await getTenants();
          return dispatch({
            type: FETCH_TENANTS_SUCCESS,
            tenants: response.data
          });
        } catch (error) {
          showRequestFailedToast;
          return dispatch({ type: FETCH_TENANTS_FAILED, error });
        }
      };
}