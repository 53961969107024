import Cookies from "js-cookie";
import GetCookie from "./getCookie";
import RemoveCookie from "./removeCookie";

const UpdateCookie = (cookieName, usrin)=>{
    if(GetCookie(cookieName) == undefined){
        Cookies.set(cookieName, usrin, {
            expires:Infinity,
            secure:true,
            sameSite:'strict',
            path:'/'
        });
    } else {
        RemoveCookie(cookieName);
        if(GetCookie(cookieName) == undefined){
            Cookies.set(cookieName, usrin, {
                expires:Infinity,
                secure:true,
                sameSite:'strict',
                path:'/'
            });
        }
    }
};
export default UpdateCookie;