import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './rootReducer';
// Middleware that checks for immutability when working with the Redux Store.
import immutableStateInvariant from 'redux-immutable-state-invariant';
import persistStore from 'redux-persist/es/persistStore';
// Middleware that allows injecting middleware in between dispatching an action and a reducer handling it.
// This is used for API calls.
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
  let middleware = [thunk];

  if (process.env.NODE_ENV !== 'production') {
    const reduxLogger = require('redux-logger').createLogger();
    middleware = [...middleware, immutableStateInvariant(), reduxLogger];
  }

  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

  const persistor = persistStore(store);

  return { store, persistor };
}
