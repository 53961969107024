export const sortContractsByDateAndStatus = (contracts) => {
    return [...contracts].sort((a, b) => {
      if (!a.endDate && !b.endDate) {
        return 0;
      } else if (!a.endDate) {
        return -1;
      } else if (!b.endDate) {
        return 1;
      } else if (a.endDate > b.endDate) {
        return -1;
      } else if (a.endDate < b.endDate) {
        return 1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else if (a.isActive && !b.isActive) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  