import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function ValidateHeader({ month, year }) {
  const { contract } = useSelector(
    (state) => state.validateTimesheets
  );
   if (contract.company == "CyberTalent"){
  return (
   <div className="validation-header-CT">
      <h1 className="mb-0 ml-4 font-weight-bold pt-3" style={{color: '#fff'}}>
        TIMESHEET{' '}
        <span className="timesheet-date-validationPage" style={{color: '#fff'}}>
          {month.toUpperCase()} {year}
        </span>
      </h1>
      <a className="mr-3 navbar-brand validation-brand--logo" href="https://t4t.rocks" />
    </div>)
  }
   return (
    <div className="validation-header">
      <h1 className="mb-0 ml-4 font-weight-bold pt-3">
        TIMESHEET{' '}
        <span className="timesheet-date-validationPage">
          {month.toUpperCase()} {year}
        </span>
      </h1>
      <a className="mr-3 navbar-brand validation-brand--logo" href="https://t4t.rocks" />
    </div>
  );
}

ValidateHeader.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

export default ValidateHeader;
