import React from 'react'
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import moment from 'moment';
import { ImageGrid } from "react-fb-image-video-grid";

const NewsItemCard = ({ newsItem, azureBlobUrl }) => {
    return (
        <div className='news-item'>
            <header id='top'>
                <h1>{newsItem.title}</h1>
                <p>{moment(newsItem.publishedOn).local().startOf('seconds').fromNow()}</p>
            </header>
            <main>
                {parse(newsItem.description)}
            </main>
            {newsItem.images.length > 0 ?
                <footer>
                    <ImageGrid>
                        {newsItem.images.map(image => {
                            return <img key={image} src={azureBlobUrl.uri + image} alt={image}></img>
                        })}
                    </ImageGrid>
                </footer>
                : ""}
        </div>
    )
}

NewsItemCard.propTypes = {
    newsItem: PropTypes.object.isRequired,
    azureBlobUrl: PropTypes.object.isRequired,
};

export default NewsItemCard