import {
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_COUNT_SUCCESS,
  FETCH_NEWS_ITEM_SUCCESS,
  FETCH_UZURE_BLOB_URL_SUCCESS
} from './actions';

export default function newsReducer(state = { ...NEWS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_NEWS_SUCCESS: {
      const newItems = state.newsList;

      action.news.forEach(newsItem => {
        if(!newItems.find((i) => i.id === newsItem.id)) {
          newItems.push(newsItem);
        }
      });
      return {
        ...state,
        newsList: newItems,
        moreToFetch: action.news.length == 10
      };
    }
    case FETCH_NEWS_COUNT_SUCCESS: {
      return {
        ...state,
        newsCount: action.newsCount
      };
    }
    case FETCH_NEWS_ITEM_SUCCESS: {
      return {
        ...state,
        newsList: [...state.newsList, action.newsItem]
      };
    }
    case FETCH_UZURE_BLOB_URL_SUCCESS: {
      return {
        ...state,
        azureBlobUrl: action.url
      };
    }

    default:
      return state;
  }
}

export const NEWS_BASE_STATE = {
  newsList: [],
  newsCount: 0,
  azureBlobUrl: "",
  moreToFetch: true
};
