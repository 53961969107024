import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../common/Card';
import { milestoneStatusToText } from '../../enums/milestoneState';
import Select from '../common/Select';
import {
  completeUserTrackAndMilestones,
  setMilestoneStatus,
  setCompletionDate,
  fetchUserTracks,
} from '../../redux/usertracks/actions';
import { showModal } from '../../redux/system/actions';
import EditSelectedMilestonesModal from '../common/modals/EditSelectedMilestonesModal';
import DeleteSelectedUserTrackModal from '../common/modals/DeleteSelectedUserTrackModal';
import Table from '../common/Table';
import SetCompletionDateMilestonesModal from '../common/modals/SetCompletionDateMilestonesModal';
import Loading from '../common/Loading';
import LoadingIcon from '../common/LoadingIcon';
import { stopLoadingSelector } from '../../redux/helperSelectors';

const NAME_CUTOFF_LENGTH = 50;
const DESCRIPTION_CUTOFF_LENGTH = 120;
const DETAILS_CUTOFF_LENGTH = 65;
const MODAL_ID_MILESTONES = 'editSelectedMilestones';
const MODAL_ID_DELETE = 'deleteSelectedUserTrack';
const MODAL_ID_COMPLETION_DATE_MILESTONE = 'setCompletionDateMilestone';
const MODAL_ID_COMPLETION_DATE_USERTRACK = 'setCompletionDateUserTrack';
const MODAL_ID_EDIT_COMPLETION_DATE = 'editCompletionDate';

function UsertracksListCard() {
  const dispatch = useDispatch();
  const [openedCards, setOpenedCards] = useState([]);
  const [openedMilestones, setOpenedMilestones] = useState([]);
  const [selectedUserTrack, setSelectedUserTrack] = useState(null);
  const [userTrackSubmitting, setUserTrackSubmitting] = useState(false);
  const [pendingMilestoneUpdate, setPendingMilestoneUpdate] = useState([]);
  const [modalData, setModalData] = useState(null);
  const { usertracksList: userTracks } = useSelector((state) => state.usertracks);
  const milestoneStatuses = useMemo(() => milestoneStatusToText.map((rate, i) => ({ value: i, text: rate })), []);
  const { selectedUser, nameToDisplay } = useSelector((state) => state.users);
  const { skip, take, totalCount } = useSelector((state) => state.usertracks);
  const { isFetchingUserTracks, triedFetchingUserTracks } = useSelector((state) => state.system);
  const stopLoading = useSelector((state) => stopLoadingSelector(state));
  const isLoading = (isFetchingUserTracks || !triedFetchingUserTracks) && !stopLoading;

  const handleChangePage = (pageIndex) => {
    dispatch(fetchUserTracks(selectedUser.myportalUserId, pageIndex, take));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchUserTracks(selectedUser.myportalUserId, 0, event.target.value));
  };

  const handleSetOpenedCard = useCallback(
    (trackId) =>
      setOpenedCards((prevState) => {
        if (prevState.includes(trackId)) {
          return prevState.filter((tId) => tId !== trackId);
        }
        return [...prevState, trackId];
      }),
    []
  );

  const handleSetOpenedMilestone = useCallback(
    (milestoneId) =>
      setOpenedMilestones((prevState) => {
        if (prevState.includes(milestoneId)) {
          return prevState.filter((mId) => mId !== milestoneId);
        }
        return [...prevState, milestoneId];
      }),
    []
  );

  const handleSetPendingMilestoneUpdate = useCallback(
    (milestoneId) =>
      setPendingMilestoneUpdate((prevState) => {
        if (prevState.includes(milestoneId)) {
          return prevState.filter((mId) => mId !== milestoneId);
        }
        return [...prevState, milestoneId];
      }),
    []
  );

  const handleChangeMilestoneStatus = useCallback(
    (userTrack, milestone, status, completionDate) => {
      setUserTrackSubmitting(true);
      handleSetPendingMilestoneUpdate(milestone.milestoneId);
      const milestoneToUpdate = { milestoneId: milestone.milestoneId, status, completionDate };
      dispatch(setMilestoneStatus(userTrack, milestoneToUpdate)).then(() => {
        setUserTrackSubmitting(false);
        handleSetPendingMilestoneUpdate(milestone.milestoneId);
      });
    },
    [dispatch, handleSetPendingMilestoneUpdate]
  );

  const handleCompleteUserTrack = useCallback(
    (userTrack, completionDate) => {
      setUserTrackSubmitting(true);
      const milestonesToUpdate = userTrack.selectedMilestones
        .filter((milestone) => milestone.status !== 2)
        .map((milestone) => {
          return {
            milestoneId: milestone.milestoneId,
            status: 2,
            completionDate,
          };
        });
      dispatch(completeUserTrackAndMilestones(userTrack, milestonesToUpdate)).then(() => {
        setUserTrackSubmitting(false);
      });
    },
    [dispatch]
  );

  const handleChangeCompletionDate = useCallback(
    (userTrackId, milestone, completionDate) => {
      const milestoneToUpdate = {
        milestoneId: milestone.milestoneId,
        completionDate,
      };
      dispatch(setCompletionDate(userTrackId, milestoneToUpdate));
    },
    [dispatch]
  );

  const handleShowModal = useCallback(
    (e, userTrack, modalId) => {
      e.stopPropagation();
      setSelectedUserTrack(userTrack);
      dispatch(showModal(modalId));
    },
    [dispatch]
  );

  const columns = (
    <>
      <th className="col-lg-2">Career Track Name</th>
      <th className="col-lg-3">Creation Date</th>
      <th className="col-lg-4">Completion Date</th>
      <th className="col-lg-2">Status</th>
    </>
  );

  const mappedUsertracks = useMemo(
    () =>
      userTracks.map((userTrack, index) => {
        const trackId = userTrack.trackId;
        const activeRowClass = openedCards.includes(trackId) ? 'active-row left-border-row' : '';
        return (
          <React.Fragment key={`${trackId}-${index}`}>
            <tr
              key={`${trackId}`}
              className={`clickable ${activeRowClass}`}
              onClick={() => handleSetOpenedCard(trackId)}>
              <td>{userTrack.careerTrackName}</td>
              <td>{moment(userTrack.creationDate).format('D MMMM YYYY')}</td>
              <td>{userTrack.completionDate && moment(userTrack.completionDate).format('D MMMM YYYY')}</td>
              <td>
                {userTrack.trackStatus == 0 ? 'Ongoing' : userTrack.status == 1 ? 'On Hold' : 'Completed'}
                <span className="pull-right">
                  <i
                    title="Edit selected milestones"
                    className={`pe-7s-pen icon-gradient bg-green-blue-top pl-2`}
                    onClick={(e) => handleShowModal(e, userTrack, MODAL_ID_MILESTONES)}
                  />
                  {userTrack.trackStatus != 2 && (
                    <i
                      title="Complete usertrack"
                      className={`pe-7s-check icon-gradient bg-green-blue-top pl-2`}
                      style={{ cursor: userTrackSubmitting ? 'wait' : 'pointer' }}
                      onClick={(e) => {
                        if (!userTrackSubmitting) {
                          handleShowModal(e, userTrack, MODAL_ID_COMPLETION_DATE_USERTRACK);
                        }
                      }}
                    />
                  )}
                  <i
                    title="Delete usertrack"
                    className={`pe-7s-trash icon-gradient bg-red-fade pl-2`}
                    onClick={(e) => handleShowModal(e, userTrack, MODAL_ID_DELETE)}
                  />
                </span>
              </td>
            </tr>
            {openedCards.includes(trackId) && (
              <>
                <tr className="left-border-row">
                  <th>Milestone Name</th>
                  <th>Description</th>
                  <th>Details</th>
                  <th>Status</th>
                </tr>
                {[...userTrack.selectedMilestones]
                  .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                  .map((milestone) => {
                    let name;
                    let description;
                    let details;

                    if (!openedMilestones.includes(milestone.milestoneId)) {
                      name =
                        milestone.name.length > NAME_CUTOFF_LENGTH
                          ? `${milestone.name.substring(0, NAME_CUTOFF_LENGTH)}...`
                          : milestone.name;
                      description =
                        milestone.description.length > DESCRIPTION_CUTOFF_LENGTH
                          ? `${milestone.description.substring(0, DESCRIPTION_CUTOFF_LENGTH)}...`
                          : milestone.description;
                      details =
                        milestone.details !== null && milestone.details.length > DETAILS_CUTOFF_LENGTH
                          ? `${milestone.details.substring(0, DETAILS_CUTOFF_LENGTH)}...`
                          : milestone.details;
                    } else {
                      name = milestone.name;
                      description = milestone.description;
                      details = milestone.details;
                    }

                    return (
                      <tr
                        key={`${trackId}${milestone.milestoneId}`}
                        className="clickable left-border-row"
                        onClick={() => handleSetOpenedMilestone(milestone.milestoneId)}>
                        <td>{name}</td>
                        <td>{description}</td>
                        <td>{details}</td>
                        <td>
                          <td style={{ display: 'flex', borderTop: 0 }}>
                          <Select
                            options={milestoneStatuses}
                            selected={milestone.status}
                            disabled={pendingMilestoneUpdate.includes(milestone.milestoneId)}
                            clickHandler={(e) => e.stopPropagation()}
                            changeHandler={(value) => {
                              if (value == 2) {
                                setModalData({ userTrack, milestone, value });
                                dispatch(showModal(MODAL_ID_COMPLETION_DATE_MILESTONE));
                              } else {
                                handleChangeMilestoneStatus(userTrack, milestone, value);
                              }
                            }}
                            defaultEmpty={false}
                          />
                          {milestone.status == 2 && !pendingMilestoneUpdate.includes(milestone.milestoneId) && (
                            <i
                              title="Edit completion date"
                              className={`pe-7s-date icon-gradient bg-green-blue-top row-icon`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalData({ userTrack, milestone, value: 2 });
                                dispatch(showModal(MODAL_ID_EDIT_COMPLETION_DATE));
                              }}></i>
                          )}
                          {pendingMilestoneUpdate.includes(milestone.milestoneId) && (
                            <div style={{ alignSelf: 'center', marginLeft: '20px' }}>
                              <LoadingIcon />
                            </div>
                          )}
                          </td>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </React.Fragment>
        );
      }),
    [
      userTracks,
      openedCards,
      userTrackSubmitting,
      handleSetOpenedCard,
      handleShowModal,
      openedMilestones,
      milestoneStatuses,
      pendingMilestoneUpdate,
      handleSetOpenedMilestone,
      dispatch,
      handleChangeMilestoneStatus,
    ]
  );

  return (
    <>
      <Card
        title={`${nameToDisplay} Career Tracks`}
        icon="pe-7s-graph1"
        iconGradient="bg-green-blue-top"
        contentClass={!isLoading && mappedUsertracks.length > 0 ? 'card-table-content' : 'no-current-card'}>
        {isLoading ? (
          <Loading message="Hang on, we're loading your active career tracks." />
        ) : mappedUsertracks.length > 0 ? (
          <Table
            columns={columns}
            data={mappedUsertracks}
            totalCount={totalCount}
            skip={skip}
            take={take}
            changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
            changeRowsPerPageHandler={handleChangeRowsPerPage}
          />
        ) : (
          <div>You have no active career tracks at this moment.</div>
        )}
      </Card>
      {selectedUserTrack && (
        <EditSelectedMilestonesModal id={MODAL_ID_MILESTONES} selectedUserTrack={selectedUserTrack} />
      )}
      {selectedUserTrack && <DeleteSelectedUserTrackModal id={MODAL_ID_DELETE} selectedUserTrack={selectedUserTrack} />}
      {modalData && (
        <SetCompletionDateMilestonesModal
          id={MODAL_ID_COMPLETION_DATE_MILESTONE}
          key={`_${modalData.milestone.milestoneId}`}
          selectedUserTrack={modalData.userTrack}
          confirmText="Complete Milestone"
          confirmHandler={({ selectedDate }) =>
            handleChangeMilestoneStatus(modalData.userTrack, modalData.milestone, modalData.value, selectedDate)
          }
        />
      )}
      {selectedUserTrack && (
        <SetCompletionDateMilestonesModal
          id={MODAL_ID_COMPLETION_DATE_USERTRACK}
          key={selectedUserTrack.trackId}
          selectedUserTrack={selectedUserTrack}
          confirmText="Complete Usertrack"
          confirmHandler={({ selectedDate }) => handleCompleteUserTrack(selectedUserTrack, selectedDate)}
        />
      )}
      {modalData && (
        <SetCompletionDateMilestonesModal
          id={MODAL_ID_EDIT_COMPLETION_DATE}
          key={modalData.milestone.milestoneId}
          selectedUserTrack={modalData.userTrack}
          milestone={modalData.milestone}
          confirmText="Update"
          title="Edit completion date"
          confirmHandler={({ selectedDate }) =>
            handleChangeCompletionDate(modalData.userTrack.trackId, modalData.milestone, selectedDate)
          }
        />
      )}
    </>
  );
}

export default UsertracksListCard;
