import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../common/Card';
import { fetchTransactionsByUserId } from '../../redux/transactions/actions';
import moment from 'moment';
import Table from '../common/Table';
import Loading from '../common/Loading';
import { stopLoadingSelector } from '../../redux/helperSelectors';

function TransactionsListCard() {
  const dispatch = useDispatch();
  const { selectedUser, nameToDisplay } = useSelector((state) => state.users);
  const {
    transactionsList: transactions,
    credits,
    skip,
    take,
    totalCount,
  } = useSelector((state) => state.transactions);
  const { isFetchingTransactions, triedFetchingTransactions } = useSelector((state) => state.system);
  const stopLoading = useSelector((state) => stopLoadingSelector(state));
  const isLoading = (isFetchingTransactions || !triedFetchingTransactions) && !stopLoading;

  const handleChangePage = (pageIndex) => {
    dispatch(fetchTransactionsByUserId(selectedUser.myportalUserId, pageIndex, take));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchTransactionsByUserId(selectedUser.myportalUserId, 0, event.target.value));
  };

  const columns = (
    <>
      <th>Amount</th>
      <th>Description</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
    </>
  );

  const mappedTransactions = useMemo(
    () =>
      transactions.map((t, i) => {
        let isPositiveTransaction = t.amount >= 0;
        return (
          <tr key={i}>
            <td className={isPositiveTransaction ? 'positive' : 'negative' }>
              {t.amount >= 0 ? '+' : ''}
              {t.amount}
            </td>
            <td>{t.description}</td>
            <td>{t.transactionTypeName}</td>
            <td>{moment(t.dateOfTransaction).format('D MMMM YYYY')}</td>
          </tr>
        );
      }),
    [transactions]
  );

  return (
    <>
      <Card
        title={`${nameToDisplay} Transactions`}
        titleExtension={`CREDITS BALANCE: ${credits ? credits.remainingCredits : 0} `}
        titleExtensionClass={`${(credits && credits.remainingCredits >= credits.maxCredits) && 'max-credits'}`}
        titleExtensionTooltip={`${(credits && credits.remainingCredits >= credits.maxCredits) ? 'Maximum credits reached' : ''}`}
        icon="pe-7s-credit"
        iconGradient="bg-green-blue-top"
        contentClass={!isLoading && mappedTransactions.length > 0 ? 'card-table-content' : 'no-current-card'}>
        {isLoading ? (
          <Loading message="Hang on, we're loading your transactions." />
        ) : mappedTransactions.length > 0 ? (
          <Table
            columns={columns}
            data={mappedTransactions}
            totalCount={totalCount}
            skip={skip}
            take={take}
            changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
            changeRowsPerPageHandler={handleChangeRowsPerPage}
          />
        ) : (
          <div>You have no transactions yet.</div>
        )}
      </Card>
    </>
  );
}

export default TransactionsListCard;
