import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { statesToBadgeProps } from '../../../enums/timesheetState';
import { downloadTimesheet } from '../../../redux/timesheets/actions';
import Card from '../../common/Card';
import LoadingIcon from '../../common/LoadingIcon';

function CurrentTimesheetDetailsCard({ timesheet, contract }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedUser } = useSelector((state) => state.users);
  const { isDownloadingTimesheet, isSavingTimesheet } = useSelector((state) => state.system);

  const handleDownloadTimesheet = useCallback(() => dispatch(downloadTimesheet(timesheet)), [dispatch, timesheet]);
  const handleNavigateToTimesheet = useCallback(
    () =>
      !isSavingTimesheet &&
      history.push(`/timesheets/edit/${timesheet.company}/${timesheet.contractId}/${timesheet.timesheetId}`),
    [history, isSavingTimesheet, timesheet.company, timesheet.contractId, timesheet.timesheetId]
  );

  const stateBadge = useMemo(() => statesToBadgeProps[timesheet.state], [timesheet.state]);
  const nameToDisplay = useMemo(
    () => (selectedUser.managingOtherUser ? `${selectedUser.firstName} ${selectedUser.lastName} -` : 'Your'),
    [selectedUser]
  );

  return (
    <Card
      title={`${nameToDisplay} Current Timesheet`}
      titleExtension={`${moment(timesheet.start).format('MMMM').toUpperCase()}`}
      icon="pe-7s-copy-file"
      iconGradient="bg-green-blue-top"
      cardClass="overflow-hidden">
      <div className="row">
        <div className="col-lg-6 overflow-anywhere">
          <div>
            Client: <label className="dark-shade">{contract.client.name}</label>
          </div>
          <div>
            Account Manager: <label className="dark-shade">{contract.accountManager.name}</label>
          </div>
          <div className="flex-center-v">
            State:
            <div className={`badge badge-pill t4t-badge ${stateBadge.background}`} style={{ marginLeft: '5px' }}>
              {stateBadge.text}
            </div>
          </div>
        </div>
        <div className="col-lg-6 overflow-anywhere">
          <div>
            Contract:{' '}
            <label className="dark-shade" title={timesheet.contractId}>
              {timesheet.contractId.length > 22 ? `${timesheet.contractId.substring(0, 19)}...` : timesheet.contractId}
            </label>
          </div>
          <div>
            Signer: <label className="dark-shade">{contract.client.timesheetSigner}</label>
          </div>
          <div>
            Contract End:{' '}
            {contract.endDate ? (
              <label className="dark-shade">{moment(contract.endDate).format('D MMMM YYYY')}</label>
            ) : (
              <label className="dark-shade">No end date</label>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="flex-center-v" style={{ marginTop: '25px' }}>
            {isDownloadingTimesheet || isSavingTimesheet ? (
              <div style={{ marginLeft: '15px' }}>
                <LoadingIcon />
              </div>
            ) : (
              <button className="btn btn-sm btn-primary" onClick={handleDownloadTimesheet}>
                Download
              </button>
            )}
            {isSavingTimesheet ? (
              <div style={{ marginLeft: '15px' }}>
                <LoadingIcon />
              </div>
            ) : (
              <button
                className="btn btn-sm btn-primary"
                style={{ marginLeft: '5px' }}
                onClick={handleNavigateToTimesheet}>
                View timesheet →
              </button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

CurrentTimesheetDetailsCard.propTypes = {
  timesheet: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
};

export default memo(CurrentTimesheetDetailsCard);
