import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TEAM4TALENT, TALENT2TEST, CYBERTALENT } from '../../constants/companies';
import Card from '../common/Card';
import LoadingIcon from '../common/LoadingIcon';

function ContactInfoCard() {
  const { isFetchingContracts, triedFetchingContracts } = useSelector((state) => state.system);
  const { company, candidate_type: userType } = useSelector((state) => state.auth.user.profile);
  const contracts = useSelector((state) =>
    state.contracts.contractsList.filter((c) => c.isActive && c.accountManager.email)
  );
  const isLoading = isFetchingContracts || !triedFetchingContracts;
  const link = (email) => {
    return <a href={`mailto:${email}?subject=Hello&body=type your message`}>{email}</a>
  }

  const accountManagerEmails = useMemo(() => {
    return !contracts || isLoading ? (
      <LoadingIcon class="justify-content-start ml-3" message="Hang on, we're loading your contract info."/>
    ) : !contracts.length ? (
      link('invoice@talent-it.be')
    ) : contracts.length === 1 ? (
      link(contracts[0].accountManager.email)
    ) : (
      contracts.map((c, index) => {
        return (
          <React.Fragment key={index}>
            {link(c.accountManager.email)}
            <span>
              {' '}
              <small>({c.contractId})</small>
            </span>
            <br />
          </React.Fragment>
        );
      })
    );
  }, [contracts, isLoading]);

  const tableRows = {
    [TEAM4TALENT]: [
      { department: 'Fleet', email: 'fleet@talent-it.be' },
      {
        department: 'Timesheet administration',
        email: userType === 'Employee' ? 'boekhouding@team4talent.be' : 'invoice@talent-it.be',
      },
      { department: 'CC questions', email: 'joeri.jans@t4t.rocks' },
      { department: 'Contract questions', email: accountManagerEmails, contractInfo: true },
    ],
    [TALENT2TEST]: [
      { department: 'Fleet', email: 'fleet@talent-it.be' },
      { department: 'Timesheet administration', email: 'invoice@talent-it.be' },
      { department: 'CC questions', email: 'ben.vandevelde@talent-it.cc' },
      { department: 'Contract questions', email: accountManagerEmails, contractInfo: true },
    ],
    [CYBERTALENT]: [
      { department: 'Fleet', email: 'fleet@talent-it.be' },
      { department: 'Timesheet administration', email: 'invoice@talent-it.be' },
      { department: 'CC questions', email: 'judith.eulaers@cybertalent.be' },
      { department: 'Contract questions', email: accountManagerEmails, contractInfo: true },
    ],
    default: [
      { department: 'Fleet', email: 'fleet@talent-it.be' },
      { department: 'Timesheet administration', email: 'invoice@talent-it.be' },
      { department: 'CC questions', email: 'geert.broodcoorens@talent-it.cc' },
      { department: 'Contract questions', email: accountManagerEmails, contractInfo: true },
    ],
  };

  const getTableRows = (company) => tableRows[company] || tableRows.default;

  return (
    <Card
      title={`Links & contact details`}
      icon="pe-7s-notebook"
      iconGradient="bg-green-blue-top"
      contentClass="card-table-content">
      <table className="table">
        <thead>
          <tr>
            <th className="w-25">Department</th>
            <th className="w-75">Email</th>
          </tr>
        </thead>
        <tbody>
          {getTableRows(company).map((row, index) => (
            <tr key={index}>
              <td>{row.department}</td>
              <td>
                {row.contractInfo ? (
                  row.email
                ) : (
                  link(row.email)
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}

export default ContactInfoCard;
