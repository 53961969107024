import React, { memo, useMemo } from 'react';
import propTypes from 'prop-types';

const EXPENSES = ['Expense', 'Transport'];

function ExpensesPerDescription({ days, className }) {
  const entries = useMemo(
    () =>
      days.flatMap((c) =>
        c.entries.flatMap((d) =>
          d.expenses.flatMap((e) => [
            {
              type: e.type,
              amount: e.amount,
            },
          ])
        )
      ),
    [days]
  );
  const expensesMapped = useMemo(
    () =>
      EXPENSES.map((expensetype) => {
        const totalPerExpense = entries.reduce((a, b) => {
          if (expensetype === b.type) {
            return parseFloat(a) + parseFloat(b.amount);
          } else {
            return parseFloat(a);
          }
        }, 0);

        if (totalPerExpense > 0) {
          return (
            <div className="row" style={{ marginBottom: '5px' }} key={`${expensetype}`}>
              <div className="col-sm-5">{expensetype}: </div>
              <div className="col-sm-7">
                {expensetype == 'Expense' ? (
                  <span>€ {totalPerExpense ? totalPerExpense.toFixed(2) : '0'}</span>
                ) : (
                  <span>{totalPerExpense ? totalPerExpense.toFixed(2) : '0'} km</span>
                )}
              </div>
            </div>
          );
        }
      }),
    [entries]
  );

  return (
    <div>
      <label
        className={`mt-2 dark-shade text-uppercase ${className || ''}`}
        data-toggle="collapse"
        data-target="#collapseHours">
        Expenses Per Description
      </label>
      {entries.length ? expensesMapped : <p>No expenses present.</p>}
    </div>
  );
}

ExpensesPerDescription.propTypes = {
  days: propTypes.array.isRequired,
  className: propTypes.string,
};

export default memo(ExpensesPerDescription);
