import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSelectedUserTrack, updateSelectedMilestones, fetchUserTracks } from '../../../redux/usertracks/actions';

function EditSelectedMilestonesModal({ id, selectedUserTrack }) {
  const dispatch = useDispatch();
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const { skip, take, usertracksList: usertracks } = useSelector((state) => state.usertracks);
  const { selectedUser } = useSelector((state) => state.users);

  const initializeCheckboxes = useCallback(() => {
    setSelectedMilestones(selectedUserTrack.selectedMilestones);
    selectedUserTrack.milestones.length == selectedUserTrack.selectedMilestones.length
      ? setIsAllSelected(true)
      : setIsAllSelected(false);
  }, [selectedUserTrack.milestones.length, selectedUserTrack.selectedMilestones]);

  useMemo(() => {
    {
      initializeCheckboxes();
    }
  }, [initializeCheckboxes]);

  const handleSelectAll = useCallback(() => {
    setIsAllSelected(!isAllSelected);
    setSelectedMilestones(selectedUserTrack.milestones);
    if (isAllSelected) {
      setSelectedMilestones([]);
    }
  }, [isAllSelected, selectedUserTrack.milestones]);

  const handleSelectedMilestoneChange = useCallback(
    (checkedMilestone) => {
      const isChecked = selectedMilestones.some(
        (selectedMilestone) => selectedMilestone.milestoneId === checkedMilestone.milestoneId
      );
      if (isChecked) {
        setSelectedMilestones(
          selectedMilestones.filter(
            (selectedMilestone) => selectedMilestone.milestoneId !== checkedMilestone.milestoneId
          )
        );
        if (selectedUserTrack.milestones.length !== selectedMilestones.length - 1) {
          setIsAllSelected(false);
        }
      } else {
        setSelectedMilestones(selectedMilestones.concat(checkedMilestone));
        if (selectedUserTrack.milestones.length === selectedMilestones.length + 1) {
          setIsAllSelected(true);
        }
      }
    },
    [selectedMilestones, selectedUserTrack.milestones.length]
  );

  const handleConfirmModal = useCallback(() => {
    if (selectedMilestones.length) {
      dispatch(updateSelectedMilestones(selectedUserTrack.trackId, selectedMilestones));
    } else {
      dispatch(deleteSelectedUserTrack(selectedUserTrack)).then(() => {
        const skipToSend = usertracks.length - 1 > 0 ? skip : skip > 0 ? skip - 1 : skip;
        dispatch(fetchUserTracks(selectedUser.myportalUserId, skipToSend, take));
      });
    }
  }, [dispatch, selectedUser.myportalUserId, selectedMilestones, selectedUserTrack, skip, take, usertracks.length]);

  return (
    <Modal
      id={id}
      icon="pe-7s-flag"
      iconGradient="bg-green-blue-top"
      title="Selected milestones"
      confirmText={selectedMilestones.length ? 'Update Milestones' : 'Delete Usertrack'}
      confirmHandler={handleConfirmModal}
      closeHandler={initializeCheckboxes}>
      <>
        <div>
          <div>
            <label>
              <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} />
              <span>
                <strong> Select all</strong>
              </span>
            </label>
          </div>
          {[...selectedUserTrack.milestones]
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((milestone, index) => (
              <div key={`cb-${index}`}>
                <label>
                  <input
                    value={milestone}
                    type="checkbox"
                    checked={selectedMilestones.some(
                      (selectedMilestone) => selectedMilestone.milestoneId === milestone.milestoneId
                    )}
                    onChange={() => handleSelectedMilestoneChange(milestone)}
                  />
                  <span> {milestone.name}</span>
                </label>
              </div>
            ))}
          {!selectedMilestones.length && (
            <span className="warning">By deselecting all milestones, this usertrack will be deleted.</span>
          )}
        </div>
      </>
    </Modal>
  );
}

EditSelectedMilestonesModal.propTypes = {
  id: PropTypes.string.isRequired,
  selectedUserTrack: PropTypes.object.isRequired,
};

export default memo(EditSelectedMilestonesModal);
