const states = {
  DRAFT: 0,
  PENDINGAPPROVAL: 1,
  APPROVED: 2,
  EXPORTED: 3,
};

export const statesToBadgeProps = {
  0: { background: 'bg-yellow-fade', text: 'draft' },
  1: { background: 'bg-blue-purple', text: 'pending' },
  2: { background: 'bg-green-blue', text: 'approved' },
  3: { background: 'bg-green-blue', text: 'approved' },
};

export default states;
