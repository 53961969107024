import LoadingIcon from './common/LoadingIcon';
import React from 'react';

const Redirecting = () => (
  <div className="wrapper wrapper-stretch d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
    <div className="flex-center-v" style={{ flexDirection: 'column' }}>
      <div style={{ marginBottom: '25px' }}>
        <img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f44b.png" />
      </div>
      <LoadingIcon message="Hang on, we're redirecting you." />
    </div>
  </div>
);


export default Redirecting;
