import PropTypes from 'prop-types';
import React, { memo } from 'react';

function DropdownButton({ text, children }) {
  return (
    <div className="dropdown">
      <button className="btn btn-primary btn-sm btn-dropdown-toggle" data-toggle="dropdown">
        {text}
      </button>
      <div className="dropdown-menu">{children}</div>
    </div>
  );
}

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default memo(DropdownButton);
