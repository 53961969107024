import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserPushNotifications, updateUserPushNotifications } from '../../api/notificationsApi';
import '@t4t/notifications';

function NotificationWrapper({ currentLoggedUserId, tenantIdLoggedInUser }) {
  const ref = useRef();
  const url = process.env.SIGNALR_URL;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ref.current) {
      ref.current.currentLoggedUserId = currentLoggedUserId;
      ref.current.tenantIdLoggedInUser = tenantIdLoggedInUser;
    }
  }, [tenantIdLoggedInUser, currentLoggedUserId]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('update-notification', (e) => {
        handleUpdateNotification(e.detail);
      });
    }
  }, [ref.current]);

  const handleUpdateNotification = (notification) => {
    const updateNotification = async () => {
      try {
        await updateUserPushNotifications(notification);
      } catch (e) {
        throw new Error('Failed to update notification', e);
      }
    };

    updateNotification();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notificationsData = await getUserPushNotifications();
        localStorage.setItem('notifications', JSON.stringify(notificationsData.data));
        setLoading(false);
      } catch (e) {
        throw new Error('Failed to get notifications');
      }
    };

    fetchData();
  }, [currentLoggedUserId, tenantIdLoggedInUser]);

  if (loading) {
    return null;
  }

  return (
    <div style={{ position: 'relative', marginRight: 20 }}>
      <notification-component ref={ref} connectionUrl={url} currentLoggedUserId={currentLoggedUserId} currentTenantId={tenantIdLoggedInUser}></notification-component>
    </div>
  );
}

NotificationWrapper.propTypes = {
  currentLoggedUserId: PropTypes.string.isRequired,
  tenantIdLoggedInUser: PropTypes.string.isRequired,
};

export default NotificationWrapper;
