import React, { memo, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../common/Card';
import Table from '../common/Table';
import { fetchAssetHistory, setSelectedTab, LOANED, RETURNED } from './../../redux/assets/actions';
import moment from 'moment';
import TabPanel from '../common/TabPanel';
import Loading from '../common/Loading';
import Tabs from '../common/Tabs';
import Tab from '../common/Tab';
import { stopLoadingSelector } from '../../redux/helperSelectors';

function AssetHistoryCard() {
  const dispatch = useDispatch();
  const { selectedUser, nameToDisplay } = useSelector((state) => state.users);
  const { isFetchingAssetHistory, triedFetchingAssetHistory } = useSelector((state) => state.system);
  const {
    loanedAssetHistory,
    skipLoaned,
    takeLoaned,
    totalCountLoaned,
    loanedAssetHistoryLoaded,
    returnedAssetHistory,
    skipReturned,
    takeReturned,
    totalCountReturned,
    returnedAssetHistoryLoaded,
    selectedTab,
  } = useSelector((state) => state.assets);
  const stopLoading = useSelector((state) => stopLoadingSelector(state));
  const isLoading =
    (isFetchingAssetHistory ||
      !triedFetchingAssetHistory ||
      !(loanedAssetHistoryLoaded && returnedAssetHistoryLoaded)) &&
      !stopLoading;

  const handleChangeTab = useCallback(
    (selectedTab) => {
      dispatch(setSelectedTab(selectedTab));
    },
    [dispatch]
  );

  const handleChangePage = (pageIndex, historyType) => {
    dispatch(
      fetchAssetHistory(
        selectedUser.myportalUserId,
        pageIndex,
        historyType == LOANED ? takeLoaned : takeReturned,
        historyType
      )
    );
  };

  const handleChangeRowsPerPage = (event, historyType) => {
    dispatch(fetchAssetHistory(selectedUser.myportalUserId, 0, event.target.value, historyType));
  };

  const columns = (
    <>
      <th className="col-lg-6">Asset</th>
      <th>Asset Category</th>
      <th>Asset of Tenant</th>
      <th>Loan Date</th>
      <th>Return Date</th>
    </>
  );

  const mappedLoanedAssetHistory = useMemo(
    () =>
      loanedAssetHistory.map((ah, i) => {
        return (
          <tr key={i}>
            <td>{ah.assetName}</td>
            <td>{ah.assetCategoryName}</td>
            <td>{ah.assetTenantName}</td>
            <td>{ah.loanDate && moment(ah.loanDate).format('D MMMM YYYY')}</td>
            <td>{ah.returnDate && moment(ah.returnDate).format('D MMMM YYYY')}</td>
          </tr>
        );
      }),
    [loanedAssetHistory]
  );

  const mappedReturnedAssetHistory = useMemo(
    () =>
      returnedAssetHistory.map((ah, i) => {
        return (
          <tr key={i}>
            <td>{ah.assetName}</td>
            <td>{ah.assetCategoryName}</td>
            <td>{ah.assetTenantName}</td>
            <td>{ah.loanDate && moment(ah.loanDate).format('D MMMM YYYY')}</td>
            <td>{ah.returnDate && moment(ah.returnDate).format('D MMMM YYYY')}</td>
          </tr>
        );
      }),
    [returnedAssetHistory]
  );

  return (
    <>
      <Card
        title={`${nameToDisplay} Assets`}
        icon="pe-7s-car"
        iconGradient="bg-green-blue-top"
        contentClass={isLoading ? 'no-current-card' : 'card-table-content'}>
        {isLoading ? (
          <Loading message="Hang on, we're loading your asset history." />
        ) : (
          <>
            <Tabs
              value={selectedTab}
              onChange={(_, tabValue) => handleChangeTab(tabValue)}
              TabIndicatorProps={{ style: { background: 'var(--accent-color)' } }}>
              <Tab label="Loaned Assets" />
              <Tab label="Returned Assets" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              {mappedLoanedAssetHistory.length > 0 ? (
                <>
                  <Table
                    columns={columns}
                    data={mappedLoanedAssetHistory}
                    totalCount={totalCountLoaned}
                    skip={skipLoaned}
                    take={takeLoaned}
                    changePageHandler={(_, pageIndex) => handleChangePage(pageIndex, LOANED)}
                    changeRowsPerPageHandler={(event) => handleChangeRowsPerPage(event, LOANED)}
                  />
                </>
              ) : (
                <div className="mt-3">You have no loaned assets yet.</div>
              )}
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
              {mappedReturnedAssetHistory.length > 0 ? (
                <>
                  <Table
                    columns={columns}
                    data={mappedReturnedAssetHistory}
                    totalCount={totalCountReturned}
                    skip={skipReturned}
                    changePageHandler={(_, pageIndex) => handleChangePage(pageIndex, RETURNED)}
                    take={takeReturned}
                    changeRowsPerPageHandler={(event) => handleChangeRowsPerPage(event, RETURNED)}
                  />
                </>
              ) : (
                <div className="mt-3">You have no returned assets yet.</div>
              )}
            </TabPanel>
          </>
        )}
      </Card>
    </>
  );
}

export default memo(AssetHistoryCard);
