import React from 'react';
import Card from '../../common/Card';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function NeedHelpCard () {
  const contractsList = useSelector(state => state.contracts.contractsList);
  const contracts = contractsList.filter(x => x.isActive === true);

  return (
    <Card contentClass='need-help d-flex bg-blue-purple' cardClass='height-100'>
      <div className='row-fluid' style={{ zIndex: 19 }}>
        <h4 className='white'>Need help?</h4>
        <p className='white'>
          {"We've got you covered. "}
          <a href='mailto:info@t4t.rocks?subject=?MyPortal'>Get in touch → 💌</a>
        </p>
        <p className='white'>
          {'Prefer talking to a human? '}
          <a href='tel:03 870 51 51'>Call us → 🎧</a>
        </p>
        {contracts && contracts.length && contracts.length > 0 ? <ContractMoreInfo client={contracts[0].client} accountManager={contracts[0].accountManager} /> : ''}
      </div>
    </Card>
  );
}

const ContractMoreInfo = props => (
  <>
    {props ? (
      <p className='white'>
        {'For more information about your contract with ' +
          props.client.name +
          ', you can contact your account manager: ' +
          props.accountManager.name +
          ' @ '}
        {props.accountManager.phone ? <AccountManagerPhoneLink phone={props.accountManager.phone} /> : ''}
        {!props.accountManager.phone && props.accountManager.email ? (
          <AccountManagerMailLink mail={props.accountManager.email} />
        ) : (
          ''
        )}
      </p>
    ) : (
      ''
    )}
  </>
);

// Could make this DRYer but react doesn't play well with dynamic href's
const AccountManagerPhoneLink = props => (
  <>
    <a href={'tel:' + props.phone}>📞{props.phone}</a>
  </>
);

const AccountManagerMailLink = props => (
  <>
    <a href={'mailto:' + props.mail + '?subject=Vraagje'}>💌{props.mail}</a>
  </>
);

ContractMoreInfo.propTypes = {
  client: PropTypes.object,
  accountManager: PropTypes.object
};

AccountManagerPhoneLink.propTypes = {
  phone: PropTypes.string
};

AccountManagerMailLink.propTypes = {
  mail: PropTypes.string
};

export default NeedHelpCard;
