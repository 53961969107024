import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { deleteSelectedUserTrack, fetchUserTracks } from '../../../redux/usertracks/actions';

function DeleteSelectedUserTrackModal({ id, selectedUserTrack }) {
  const dispatch = useDispatch();
  const { skip, take, usertracksList: usertracks } = useSelector((state) => state.usertracks);
  const { selectedUser } = useSelector((state) => state.users);

  const handleConfirmModal = useCallback(() => {
    dispatch(deleteSelectedUserTrack(selectedUserTrack)).then(() => {
      const skipToSend = usertracks.length - 1 > 0 ? skip : skip > 0 ? skip - 1 : skip;
      dispatch(fetchUserTracks(selectedUser.myportalUserId, skipToSend, take));
    });
  }, [dispatch, selectedUserTrack, usertracks.length, skip, selectedUser.myportalUserId, take]);

  return (
    <Modal
      id={id}
      icon="pe-7s-help1"
      iconGradient="bg-green-blue-top"
      title="Delete Usertrack"
      confirmText="Delete Usertrack"
      confirmHandler={() => handleConfirmModal()}>
      <div>
        <span>
          Please confirm for deleting the <strong>{selectedUserTrack.careerTrackName}</strong> usertrack
        </span>
      </div>
    </Modal>
  );
}

DeleteSelectedUserTrackModal.propTypes = {
  id: PropTypes.string.isRequired,
  selectedUserTrack: PropTypes.object.isRequired,
};

export default memo(DeleteSelectedUserTrackModal);
