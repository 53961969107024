import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCourseDocuments, getCourseEnrollmentsFromCourse, getCourseReviewsFromCourse, getUserInfo } from '../../api/coursesApi';
import Card from '../common/Card';
import SkeletonLoading from './loading/Skeleton'; // Import the SkeletonLoading component
import { downloadDocument, getSasUriForBlob } from '../../api/documentsApi';
import fileService from '../../service/fileService';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../redux/system/actions';
import ViewLinkCourseModal from '../common/modals/ViewLinkCourseModal';

const CREATE_TS_VIEW_LINK_MODAL_ID = 'createTSViewCourseLink';

export default function CourseDetailPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { course } = location.state;
  const { category } = location.state;

  const [reviews, setReviews] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [editorContent] = useState(course.description);

  const [loading, setLoading] = useState(true); // State to manage loading indicator
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  const [hasAccess, setHasAccess] = useState(false);

  const handleShowModal = useCallback((modalId, course) => dispatch(showModal(modalId, course)), [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewsResponse = await getCourseReviewsFromCourse(course.id);

        const sortedReviews = reviewsResponse.data.sort((a, b) => {
          const dateA = new Date(a.reviewDate);
          const dateB = new Date(b.reviewDate);
          return dateB - dateA;
        });

        const enrollmentsResponse = await getCourseEnrollmentsFromCourse(course.id);
        const enrollments = enrollmentsResponse.data;
        const courseDocumentsResponse = await getCourseDocuments(course.id);
        const documents = courseDocumentsResponse.data;

        const userEnrollment = enrollments.find((enrollment) => enrollment.userId === currentLoggedUserId && enrollment.status === 'Active');
        if (userEnrollment || course.userId === currentLoggedUserId) {
          setHasAccess(true);
        }

        for (const review of sortedReviews) {
          await fetchUserInfo(review.user.id, review);
        }

        setDocuments(documents);
        setReviews(sortedReviews);
        setLoading(false);
      } catch (error) {
        throw new Error('Error fetching course reviews');
      }
    };

    fetchData();
  }, [course.id, course.attachment]); // Include course.attachment in dependency array

  const firstCardRef = useRef(null);

  useEffect(() => {
    // Set the height of other summary cards to match the height of the first summary card
    if (firstCardRef.current && !loading) {
      const height = firstCardRef.current.clientHeight + 100;

      document.querySelectorAll('.grid-item').forEach((card) => {
        card.style.height = `${height}px`;
      });
    }
  }, [loading]); // Update height whenever loading state changes

  const fetchUserInfo = async (userId, review) => {
    try {
      const userInfo = await getUserInfo(userId);
      const fullName = userInfo.data.userGeneralInfo.firstName + ' ' + userInfo.data.userGeneralInfo.lastName;
      review.userFullName = fullName;
    } catch (error) {
      throw new Error('Error fetching user info');
    }
  };

  const downloadAttachement = async (documentId, documentName) => {
    try {
      const response = await getSasUriForBlob(documentId);
      const document = await downloadDocument(response.data);
      fileService.handleFileDownload(document, documentName);
    } catch (error) {
      throw new Error('Error downloading document');
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function navigateToCourses() {
    history.goBack();
  }

  const handleLinkAdded = (linkData) => {
    // setCourse({ ...course, link: linkData });
  };

  return (
    <div className="container-fluid" style={{ marginBottom: '10px' }}>
      <div className="custom-flex-4">
        <div className="color-test" style={{ display: 'flex', alignItems: 'center', marginBottom: 10, maxWidth: '80%' }}>
          <i className="pe-7s-info" style={{ margin: 0, padding: 0 }}></i>
          <h3 style={{ margin: 0, padding: 0 }}>{course.name}</h3>
        </div>

        <button className="btn btn-primary documents-row-button" onClick={() => navigateToCourses()}>
          <i className="fas fa-arrow-left"></i>
          Go back
        </button>
      </div>

      <div className="grid-container">
        <div className="grid-item">
          <Card title="SUMMARY" icon="pe-7s-note2" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
            <div ref={firstCardRef}>
              <div className="sum-card">
                <label>Name</label>
                <div style={{ userSelect: 'none', width: '700px' }} className="card-input html-render" dangerouslySetInnerHTML={{ __html: course.name }} />
                <textarea rows={2} style={{ display: 'none' }} value={course.name} readOnly />
              </div>
              <div className="sum-card">
                <div className="sum-card">
                  <label>Company</label>
                  <input className="card-input" disabled placeholder={course.company} />
                </div>
              </div>
              <div className="card-input-container">
                <div className="sum-card">
                  <label>Category</label>
                  <input className="card-input" disabled placeholder={course.category} />
                </div>
                <div className="sum-card">
                  <label>Duration</label>
                  <input className="card-input" disabled placeholder={course.duration} />
                </div>
                <div className="sum-card">
                  <label>Type of course</label>
                  <input className="card-input" disabled placeholder={course.typeOfCourse} />
                </div>
                <div className="sum-card">
                  <label>Price</label>
                  <input className="card-input" disabled placeholder={'€ ' + course.price} />
                </div>
                <div className="sum-card">
                  <label>Place</label>
                  <input className="card-input" disabled placeholder={course.place} />
                </div>
                <div className="sum-card">
                  <label>Certificate</label>
                  <input className="card-input" disabled placeholder={course.certificate ? 'Yes' : 'No'} />
                </div>

                <div className="sum-card" style={{ position: 'relative' }}>
                  <label>Link(s)</label>
                  <div style={{ position: 'relative' }}>
                    <input className="card-input" disabled placeholder={course.link} />
                    {course.link === 'Yes' && (
                      <i
                        onClick={(event) => {
                          event.stopPropagation();
                          handleShowModal(CREATE_TS_VIEW_LINK_MODAL_ID);
                        }}
                        style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', fontSize: '24px', cursor: 'pointer' }}
                        className="pe-7s-paperclip"></i>
                    )}
                  </div>
                </div>

                <div className="sum-card">
                  <label>Attachment(s)</label>
                  <input className="card-input" disabled placeholder={course.attachment} />
                </div>
              </div>
              <div className="sum-card">
                <label>Description</label>
                <div
                  style={{ userSelect: 'none', overflowWrap: 'break-word', width: '700px' }}
                  className="card-input html-render"
                  dangerouslySetInnerHTML={{ __html: editorContent }}
                />
                <textarea rows={4} style={{ display: 'none' }} value={course.description} readOnly />
              </div>
            </div>
          </Card>
        </div>

        <div className="grid-item">
          <Card title="REVIEWS" icon="pe-7s-comment" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
            {loading ? (
              <>
                <SkeletonLoading />
                <SkeletonLoading />
                <SkeletonLoading />
                <SkeletonLoading />
              </>
            ) : reviews.length === 0 ? (
              <div>
                <p className="no-reviews">No reviews available for this course</p>
              </div>
            ) : (
              <div style={{ paddingBottom: '10px' }}>
                {reviews.map((review, index) => (
                  <div key={index} className="custom-border">
                    <div className="review-flex">
                      <p>{review.userFullName}</p>
                      <p>{formatDate(review.reviewDate)}</p>
                    </div>
                    <p style={{ color: '#999' }}>{review.description}</p>
                    <div className="review2-flex">
                      {Array.from({ length: 5 }, (_, index) => (
                        <span
                          key={index}
                          className={`review-star ${index < review.starRating ? 'filled' : ''}`}
                          style={{
                            fontSize: '25px',
                            color: index < review.starRating ? '#ffc107' : '#e4e5e9',
                          }}>
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Card>
        </div>

        <div className="grid-item">
          <Card
            title="ATTACHMENTS"
            icon="pe-7s-file"
            iconGradient="bg-green-blue-top"
            contentClass={'card-table-content'}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {loading ? (
              <>
                <SkeletonLoading />
                <SkeletonLoading />
                <SkeletonLoading />
                <SkeletonLoading />
              </>
            ) : hasAccess ? (
              documents.length === 0 ? (
                <div>
                  <p className="no-reviews">No attachments available for this course</p>
                </div>
              ) : (
                <div className="ui-attachment-card">
                  {documents.map((document, index) => (
                    <div key={index} className="attachment-item">
                      <div className="attachment-info">
                        <div className="attachment-icon">
                          {document.document.contentType === 'pdf' ? (
                            <img src={require(`../../assets/images/files/pdf_image.png`)} alt="PDF Icon" />
                          ) : document.document.contentType === 'docx' ? (
                            <img src={require(`../../assets/images/files/word_image.png`)} alt="WORD Icon" />
                          ) : (
                            document.document.contentType === 'xlsx' && <img src={require(`../../assets/images/files/excel_image.png`)} alt="Excel Icon" />
                          )}
                        </div>

                        <div className="attachment-details">
                          <p className="attachment-name" style={{ color: 'white' }}>
                            {document.document.name.split('.')[0]}
                          </p>
                          <p className="attachment-upload">Uploaded on {formatDate(document.document.uploadedOn)}</p>
                        </div>
                      </div>

                      <button onClick={() => downloadAttachement(document.document.id, document.document.name)} className="btn-primary download-button">
                        <i className="pe-7s-download"></i>
                      </button>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <i style={{ fontSize: 90, marginBottom: 10 }} className="fas fa-lock"></i>
                <p style={{ textAlign: 'center' }}>Sorry, you dont have access to this resource. You need to be enrolled in this course!</p>
              </div>
            )}
          </Card>
        </div>
      </div>

      <ViewLinkCourseModal id={CREATE_TS_VIEW_LINK_MODAL_ID} course={course} onLinkAdded={handleLinkAdded} />
    </div>
  );
}
