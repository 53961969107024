import { FETCH_USERS_SUCCESS, FETCH_USER_TYPES_SUCCESS, SELECT_USER, SET_FILTER_VALUE } from './actions';
import { SET_SELECTED_USER } from '../profile/actions';

export default function usersReducer(state = { ...USERS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.users,
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
      };
    }

    case FETCH_USER_TYPES_SUCCESS: {
      return {
        ...state,
        userTypes: action.userTypes,
      };
    }

    case SELECT_USER: {
      if (state.selectedUser.myportalUserId === action.selectedUser.myportalUserId) {
        return {
          ...state,
          selectedUser: {
            managingOtherUser: false,
            myportalUserId: action.profile.user && action.profile.user.id ? action.profile.user.id : null,
          },
          nameToDisplay: 'Your',
        };
      } else {
        return {
          ...state,
          selectedUser: { managingOtherUser: true, ...action.selectedUser },
          nameToDisplay: `${action.selectedUser.firstName} ${action.selectedUser.lastName} - `,
        };
      }
    }

    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: { ...state.selectedUser, myportalUserId: action.user.id },
      };

    case SET_FILTER_VALUE:
      return {
        ...state,
        filterValue: action.filter,
      };

    default:
      return state;
  }
}

export const USERS_BASE_STATE = {
  usersList: [],
  selectedUser: { managingOtherUser: false },
  nameToDisplay: 'Your',
  skip: null,
  take: null,
  totalCount: null,
  filterValue: null,
  userTypes: null,
};
