import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '../common/Card';
import Loading from '../common/Loading';
import EditTimesheetActionsButton from './EditTimesheetActionsButton';
import TimesheetEditDetailsCard from './TimesheetEditDetailsCard';
import TimesheetEditRow from './TimesheetEditRow';
import TimesheetDocumentState from '../../enums/timesheetDocumentState';
import TimesheetState from '../../enums/timesheetState';
import { cancelTimesheetEditing, fetchTimesheet } from '../../redux/timesheets/actions';
import { fetchProjectsByUserId } from '../../redux/projects/actions';
import LoadingCard from '../common/LoadingCard';

function EditTimesheet() {
    const dispatch = useDispatch();
    const { timesheetId, contractId, company } = useParams();
    const { nameToDisplay, selectedUser } = useSelector((state) => state.users);
    const { holidayList } = useSelector((state) => state.timesheets);
    const timesheet = useSelector(
        (state) =>
            state.timesheets.timesheetList != null &&
            state.timesheets.timesheetList.find(
                (ts) => ts.timesheetId === timesheetId && ts.contractId === contractId && ts.company === company
            )
    );
    const contract = useSelector((state) =>
        !timesheet ? null : state.contracts.contractsList.find((c) => c.contractId === timesheet.contractId)
    );
    const projects = useSelector((state) => state.projects.projectsList);

    // const minProjectStartDate & const maxProjectEndDate
    // can be used in TimesheetEditRow to allow for me specific renderings.
    // e.g., if day < || >  minProjectStartDate/maxProjectEndDate don't show projectsSelection
    // const minProjectStartDate = useSelector((state) =>
    //   state.projects.minProjectStartDate
    // );
    // const maxProjectEndDate = useSelector((state) =>
    //   state.projects.maxProjectEndDate
    // );
    const {
        isFetchingTimesheets,
        isFetchingTimesheet,
        triedFetchingTimesheet,
        isFetchingContracts,
        triedFetchingTimesheets,
    } = useSelector((state) => state.system);
    const pristineTimesheet = useSelector((state) => state.pristineTimesheet);

    const timesheetApprover = useCallback((contract, timesheet) => {
        return contract.timesheetAuthorisation !== 1
            ? timesheet.timelineEvents[0].username
            : contract.client.timesheetSigner || 'the timesheet signer';
    }, []);

    const timesheetDetailNotYetFetched = useMemo(
        () =>
            !isFetchingTimesheet &&
            (timesheet == null || timesheet.days == null || timesheet.days.length === 0) &&
            !triedFetchingTimesheet &&
            triedFetchingTimesheets,
        [isFetchingTimesheet, timesheet, triedFetchingTimesheet, triedFetchingTimesheets]
    );

    const cantMapEntriesYet = useMemo(
        () =>
            timesheetDetailNotYetFetched ||
            isFetchingTimesheets ||
            contract == null ||
            timesheet == null ||
            holidayList == null,
        [contract, holidayList, isFetchingTimesheets, timesheet, timesheetDetailNotYetFetched]
    );

    const foundTimesheetErrors = useMemo(() => {
        if (cantMapEntriesYet) {
            return {};
        }

        const timesheetErrors = {};

        timesheet.days.map((day, i) => {
            const dayErrors = {};
            day.entries.map((entry, j) => {
                const entryErrors = {};
                if (
                    entry.amount !== 0 &&
                    entry.amount != null &&
                    (entry.type === '' || entry.type.key === '' || entry.type.key == null)
                ) {
                    // Add error to both props to show error in both fields
                    const errorText = 'You can only fill in hours if you select/enter a description';
                    entryErrors['type'] = errorText;
                    entryErrors['amount'] = errorText;
                }

                if (entry.amount > 24 || entry.amount < 0) {
                    entryErrors['amount'] = 'Please enter a valid amount for hours (0-24)';
                }

                if (Object.keys(entryErrors).length > 0) {
                    dayErrors[j] = entryErrors;
                }
            });

            if (Object.keys(dayErrors).length > 0) {
                timesheetErrors[i] = dayErrors;
            }
        });

        return timesheetErrors;
    }, [cantMapEntriesYet, timesheet]);

    const mappedTimesheetEntries = useMemo(() => {
        if (cantMapEntriesYet) {
            return [];
        }

        let entries = [];
        const holidays = holidayList.find((holidays) => holidays.year == moment(timesheet.start).year());
        timesheet.days.map((day, i) => {
            const holiday = holidays
                ? holidays.holidays.find(
                      (holiday) => moment(holiday.date).format('LL') == moment(day.date).format('LL')
                  )
                : undefined;
            const entry = (
                <TimesheetEditRow
                    key={`day-${i}-row`}
                    day={day}
                    contractStartDate={contract.startDate}
                    contractEndDate={contract.endDate}
                    timesheet={timesheet}
                    dayIndex={i}
                    rates={contract.rates}
                    isFreeTextAllowed={contract.isFreeTextAllowedInEntries}
                    holiday={holiday}
                    projects={projects}
                    standardHoursPerDay={contract.job.standardHoursPerDay}
                    inputErrorsByEntry={foundTimesheetErrors[i] || {}}
                />
            );
            entries.push(entry);
        });

        return entries;
    }, [cantMapEntriesYet, contract, foundTimesheetErrors, holidayList, projects, timesheet]);

    const isLoading = useMemo(
        () =>
            isFetchingTimesheets ||
            isFetchingTimesheet ||
            isFetchingContracts ||
            !timesheet ||
            !contract ||
            !mappedTimesheetEntries,
        [contract, isFetchingContracts, isFetchingTimesheet, isFetchingTimesheets, mappedTimesheetEntries, timesheet]
    );

    const timesheetContent = useMemo(() => {
        if (contract && timesheet) {
            const pendingApproval = timesheet.state === TimesheetState.PENDINGAPPROVAL && (
                <div className="alert alert-warning" role="alert">
                    <strong>Note:</strong>{' '}
                    {contract.timesheetAuthorisation === 1 &&
                        'this timesheet is not editable because it is pending approval.'}
                    {contract.timesheetAuthorisation !== 1 &&
                        'this timesheet is not editable because it has been submitted.'}
                </div>
            );

            const hasUploadedDocument = timesheet.documentState === TimesheetDocumentState.HAS_UPLOADED_DOCUMENT && (
                <div className="alert alert-success" role="alert">
                    <strong>Note:</strong> you already uploaded a version of this month&apos;s timesheet.
                </div>
            );

            const approved = timesheet.state === TimesheetState.APPROVED && (
                <div className="alert alert-success" role="alert">
                    <strong>Note:</strong> This timesheet has been approved by {timesheetApprover(contract, timesheet)}{' '}
                    on {moment(timesheet.timelineEvents[0].date).format('dddd, MMMM Do YYYY, kk:mm:ss')}
                </div>
            );

            return (
                <>
                    {pendingApproval}
                    {hasUploadedDocument}
                    {approved}
                    <table className="table">
                        <thead>
                            <tr>
                                <th colSpan={12} className="month-header">
                                    {moment(timesheet.start).format('MMMM YYYY')}
                                </th>
                            </tr>
                            <tr>
                                <th>Day</th>
                                <th>Description</th>
                                <th>Remarks</th>
                                <th>Hours|€|Km</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{mappedTimesheetEntries}</tbody>
                    </table>
                </>
            );
        }
    }, [contract, timesheet, timesheetApprover, mappedTimesheetEntries]);

    useEffect(() => {
        if (
            timesheet != null &&
            (timesheet.days == null || timesheet.days.length === 0) &&
            !isFetchingTimesheet &&
            triedFetchingTimesheets
        ) {
            dispatch(fetchTimesheet(timesheetId, contractId, company));
        }
    }, [
        company,
        contractId,
        dispatch,
        isFetchingTimesheet,
        timesheet,
        timesheetId,
        holidayList,
        triedFetchingTimesheets,
    ]);

    useEffect(() => {
        if (
            timesheet != null &&
            (timesheet.days == null || timesheet.days.length === 0) &&
            !isFetchingTimesheet &&
            triedFetchingTimesheets &&
            selectedUser.myportalUserId
        ) {
            dispatch(fetchProjectsByUserId(selectedUser.myportalUserId));
        }
    }, [
        company,
        contractId,
        dispatch,
        isFetchingTimesheet,
        timesheet,
        timesheetId,
        holidayList,
        triedFetchingTimesheets,
        selectedUser.myportalUserId,
    ]);

    const actionButtons = useMemo(() => {
        return (
            !isLoading && (
                <>
                    <EditTimesheetActionsButton
                        timesheet={timesheet}
                        contract={contract}
                        hasErrors={Object.keys(foundTimesheetErrors).length > 0 ? true : false}
                    />
                    {pristineTimesheet && (
                        <a
                            style={{ margin: 'auto 0 auto 10px' }}
                            onClick={() => dispatch(cancelTimesheetEditing(pristineTimesheet))}>
                            Cancel
                        </a>
                    )}
                </>
            )
        );
    }, [contract, dispatch, foundTimesheetErrors, isLoading, pristineTimesheet, timesheet]);

    return (
        <div className="container-fluid">
            <div className="row row-dashboard">
                <div className="col-lg-8">
                    <Card
                        title={`${nameToDisplay} Timesheet ${
                            !isLoading ? `for ${moment(timesheet.start).format('MMMM YYYY')}` : ''
                        }`}
                        icon="pe-7s-copy-file"
                        iconGradient="bg-green-blue-top"
                        contentClass={isLoading ? 'no-current-card' : 'card-table-content'}
                        cardClass="timesheet"
                        button={actionButtons ? actionButtons : null}>
                        {isLoading ? <Loading message="Hang on, we're loading your timesheet." /> : timesheetContent}
                    </Card>
                </div>
                <div className="col-lg-4">
                    {isLoading ? (
                        <LoadingCard
                            title="Timesheet Details"
                            icon="pe-7s-copy-file"
                            iconGradient="bg-green-blue-top"
                            message="Hang on, we're loading your timesheet."
                        />
                    ) : (
                        <TimesheetEditDetailsCard timesheet={timesheet} contract={contract} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default memo(EditTimesheet);
