import moment from 'moment';
import TimesheetState from '../../enums/timesheetState';
import {
  ADD_ENTRY,
  ADD_EXPENSE,
  AUTOCOMPLETE_TIMESHEET,
  CANCEL_TIMESHEET_EDITING,
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_FAILED,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_TIMESHEET_SUCCESS,
  FETCH_TIMESHEETS_SUCCESS,
  REMOVE_ENTRY,
  REMOVE_EXPENSE,
  SAVE_TIMESHEET,
  SAVE_TIMESHEET_FAILED,
  SAVE_TIMESHEET_SUCCESS,
  SET_ENTRY_AMOUNT,
  SET_ENTRY_HOMEWORK,
  SET_ENTRY_KEY,
  SET_ENTRY_PROJECTID,
  SET_ENTRY_REMARKS,
  SET_EXPENSE_AMOUNT,
  SET_EXPENSE_KEY,
  SET_EXPENSE_REMARK,
  SUBMIT_TIMESHEET_SUCCESS,
  APPROVE_OWN_TIMESHEET_SUCCESS,
  SET_TEMP_TIMESHEET_SIGNER_SUCCESS,
} from './actions';

export default function timesheetsReducer(state = { ...TIMESHEETS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_TIMESHEETS_SUCCESS: {
      const timesheets = action.timesheets.timesheets ? action.timesheets.timesheets : action.timesheets;
      return {
        ...state,
        timesheetList: [...timesheets],
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
        contractsHaveTimesheetForCurrentMonth: action.contractsHaveTimesheetForCurrentMonth
      };
    }

    case FETCH_TIMESHEET_SUCCESS: {
      const timesheet = { ...action.timesheet };
      if (timesheet != null) {
        if (timesheet.timelineEvents.length === 0) {
          let timesheetCreatedEvent = {
            date: new Date(),
            title: 'Timesheet Created',
            description: '',
            username: action.currentUser.profile.preferred_username
          };
          timesheet.timelineEvents = [timesheetCreatedEvent];
        }
        timesheet.timelineEvents = timesheet.timelineEvents.map((event) => {
          return {
            ...event,
            // Timeline events are in utc time
            date: moment.utc(event.date).local()
          };
        });
        timesheet.timelineEvents.sort((a, b) => {
          return b.date - a.date;
        });

        const existingTimesheet =
          state.timesheetList &&
          state.timesheetList.find(
            (ts) =>
              ts.timesheetId === timesheet.timesheetId &&
              ts.contractId === timesheet.contractId &&
              ts.company === timesheet.company
          );

        if (!existingTimesheet) {
          const newTimesheets = [...(state.timesheetList == null ? [] : state.timesheetList), timesheet];
          newTimesheets.sort((a, b) => new Date(b.start) - new Date(a.start));

          return {
            ...state,
            timesheetList: newTimesheets,
            totalCount: state.totalCount + 1
          };
        } else {
          const newState = {
            ...state,
            timesheetList: [...state.timesheetList]
          };
          const idx = state.timesheetList.indexOf(existingTimesheet);
          newState.timesheetList.splice(idx, 1, timesheet);

          return newState;
        }
      }

      return state;
    }

    case ADD_ENTRY:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return { ...day, entries: [...day.entries, { type: '', amount: 0, expenses: [] }] };
                }
                return day;
              })
            };
          }

          return ts;
        })
      };

    case REMOVE_ENTRY:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  const entriesCopy = [...day.entries];
                  // splice() modifies the existing array, hence entriesCopy
                  entriesCopy.splice(action.entryIndex, 1);

                  return { ...day, entries: entriesCopy };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case CANCEL_TIMESHEET_EDITING:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.pristineTimesheet.timesheetId &&
            ts.contractId === action.pristineTimesheet.contractId &&
            ts.company === action.pristineTimesheet.company
          ) {
            return action.pristineTimesheet;
          }

          return ts;
        })
      };

    case SET_ENTRY_KEY:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return action.isFreeText
                          ? { ...entry, type: action.key }
                          : { ...entry, type: { key: action.key } };
                      }

                      return entry;
                    }),
                    isEdited: true
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_ENTRY_AMOUNT:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return { ...entry, amount: action.amount !== '' ? action.amount : 0 };
                      }

                      return entry;
                    }),
                    isEdited: true
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_ENTRY_REMARKS:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return { ...entry, remarks: action.remarks };
                      }

                      return entry;
                    }),
                    isEdited: true
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_ENTRY_PROJECTID:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return { ...entry, projectId: action.projectId };
                      }

                      return entry;
                    }),
                    isEdited: true
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case AUTOCOMPLETE_TIMESHEET:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day) => {
                const timeSheetEntryDate = moment(day.date);
                const contractStartDate = action.contractStartDate ? moment.utc(action.contractStartDate) : null;
                const contractEndDate = action.contractEndDate ? moment.utc(action.contractEndDate) : null;
                const isWeekend = timeSheetEntryDate.day() === 0 || timeSheetEntryDate.day() == 6;
                const isOutOfContractDate =
                  timeSheetEntryDate.isBefore(contractStartDate) || timeSheetEntryDate.isAfter(contractEndDate);

                return {
                  ...day,
                  entries:
                    isWeekend || isOutOfContractDate
                      ? day.entries
                      : day.entries.map((entry) => {
                        if (action.entryType) {
                          if (action.isFreeTextAllowed) {
                            return entry.type == null || entry.type.trim() == ''
                              ? {
                                ...entry,
                                type: action.entryType,
                                amount: action.amount,
                                remarks: action.remarks,
                                isHomework: action.isHomework
                              }
                              : entry;
                          } else {
                            return entry.type == null || (entry.type != null && entry.type.key == null) || (entry.type != null && entry.type.key.trim() == '')
                              ? {
                                ...entry,
                                type: { key: action.entryType },
                                amount: action.amount,
                                remarks: action.remarks,
                                isHomework: action.isHomework
                              }
                              : entry;
                          }
                        } else {
                          return entry;
                        }
                      }),
                  isEdited: true
                };
              })
            };
          }

          return ts;
        })
      };
    case SAVE_TIMESHEET:
      return { ...state, errorMessage: undefined };
    case SAVE_TIMESHEET_SUCCESS: {
      let timesheetSavedEvent = {
        date: new Date(),
        title: 'Timesheet Saved',
        description: '',
        username: action.currentUser.profile.preferred_username
      };

      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...action.timesheet,
              state: TimesheetState.DRAFT,
              timelineEvents: [timesheetSavedEvent, ...action.timesheet.timelineEvents],
              version: action.timesheet.version + 1,
              totalHours: ts.days
                .flatMap((day) =>
                  day.entries.flatMap((entry) => [
                    {
                      key: entry.type ? entry.type.key : '',
                      amount: entry.amount
                    }
                  ])
                )
                .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                .toFixed(2),
              totalExpenses: ts.days
                .flatMap((day) =>
                  day.entries.flatMap((entry) =>
                    entry.expenses.flatMap((expense) => [
                      {
                        type: expense.type,
                        amount: expense.amount
                      }
                    ])
                  )
                )
                .filter((expense) => expense.type === 'Expense')
                .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0),
              totalKm: ts.days
                .flatMap((day) =>
                  day.entries.flatMap((entry) =>
                    entry.expenses.flatMap((expense) => [
                      {
                        type: expense.type,
                        amount: expense.amount
                      }
                    ])
                  )
                )
                .filter((expense) => expense.type === 'Transport')
                .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
            };
          }
          return ts;
        })
      };
    }

    case SAVE_TIMESHEET_FAILED:
    case FETCH_HOLIDAYS:
    case FETCH_HOLIDAYS_FAILED:
      return { ...state, errorMessage: undefined };

    case SUBMIT_TIMESHEET_SUCCESS: {
      let timesheetSubmittedEvent = {
        date: moment(),
        title: 'Timesheet Submitted',
        description: '',
        username: action.currentUser.profile.preferred_username
      };

      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...action.timesheet,
              state: TimesheetState.PENDINGAPPROVAL,
              timelineEvents: [timesheetSubmittedEvent, ...action.timesheet.timelineEvents]
            };
          }

          return ts;
        })
      };
    }

    case APPROVE_OWN_TIMESHEET_SUCCESS: {
      let timesheetApprovedEvent = {
        date: moment(),
        title: 'Timesheet Approved',
        description: '',
        username: action.currentUser.profile.preferred_username
      };

      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...action.timesheet,
              state: TimesheetState.APPROVED,
              timelineEvents: [timesheetApprovedEvent, ...action.timesheet.timelineEvents]
            };
          }

          return ts;
        })
      };
    }

    case ADD_EXPENSE:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return { ...entry, expenses: [...entry.expenses, { type: 'Expense', amount: 0 }] };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case REMOVE_EXPENSE:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        const expensesCopy = [...entry.expenses];
                        expensesCopy.splice(action.expenseIndex, 1);
                        return { ...entry, expenses: expensesCopy };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_EXPENSE_KEY:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return {
                          ...entry,
                          expenses: entry.expenses.map((expense, i) => {
                            if (i == action.expenseId) {
                              return { ...expense, type: action.key.type };
                            }
                            return expense;
                          })
                        };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_EXPENSE_AMOUNT:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,

              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return {
                          ...entry,
                          expenses: entry.expenses.map((expense, i) => {
                            if (i == action.expenseId) {
                              let newExpense = { ...expense, amount: action.key.amount };

                              if (
                                parseFloat(action.key.amount) > action.max ||
                                parseFloat(action.key.amount) < action.min
                              ) {
                                newExpense.hasError = true;
                              } else {
                                delete newExpense.hasError;
                              }

                              return newExpense;
                            }
                            return expense;
                          })
                        };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_EXPENSE_REMARK:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return {
                          ...entry,
                          expenses: entry.expenses.map((expense, i) => {
                            if (i == action.expenseId) {
                              return { ...expense, remarks: action.key.remarks };
                            }
                            return expense;
                          })
                        };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case SET_ENTRY_HOMEWORK:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              days: ts.days.map((day, i) => {
                if (i == action.dayIndex) {
                  return {
                    ...day,
                    entries: day.entries.map((entry, iEntry) => {
                      if (iEntry == action.entryIndex) {
                        return { ...entry, isHomework: action.isHomework };
                      }

                      return entry;
                    })
                  };
                }

                return day;
              })
            };
          }

          return ts;
        })
      };

    case FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidayList: action.holidays
      };

    case SET_TEMP_TIMESHEET_SIGNER_SUCCESS:
      return {
        ...state,
        timesheetList: state.timesheetList.map((ts) => {
          if (
            ts.timesheetId === action.timesheet.timesheetId &&
            ts.contractId === action.timesheet.contractId &&
            ts.company === action.timesheet.company
          ) {
            return {
              ...ts,
              tempTimesheetSignerEmail: action.email
            };
          }
          return ts;
        })
      };

    default:
      return state;
  }
}

export const TIMESHEETS_BASE_STATE = {
  timesheetList: [],
  holidayList: [],
  errorMessage: undefined,
  skip: null,
  take: null,
  totalCount: null,
  timeSheetForCurrentMonthCreated: null
};
