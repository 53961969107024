import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';

function ViewLinkCourseModal({ id, course }) {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (course && course.courseLinks) {
      setLinks(course.courseLinks);
    } else {
      setLinks([]);
    }
  }, [course]);

  const isConfirmButtonDisabled = () => {
    return true;
  };

  const handleAddLink = async () => {
    try {
      toast.success('You have successfully added your link', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const redirectToUrl = (url) => {
    window.open(url, '_blank'); // Open URL in a new tab
  };

  return (
    <Modal
      id={id}
      icon="pe-7s-paperclip"
      iconGradient="bg-green-blue-top"
      title="Links"
      contentClass="modal-body-center-h"
      confirmText="SAVE"
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleAddLink}>
      <div style={{ width: '100%' }}>
        {links.map((link, index) => (
          <div key={index} className="d-flex justify-content-between align-items-center" style={{ marginBottom: '10px', width: '100%' }}>
            <button
              className="btn btn-outline-secondary"
              style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              onClick={() => redirectToUrl(link.url)}>
              {link.url}
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
}

ViewLinkCourseModal.propTypes = {
  id: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
};

export default memo(ViewLinkCourseModal);
