import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../common/Table';
import Loading from '../common/Loading';
import '../../assets/css/courses/style.css';
import Tabs from '../common/Tabs';
import Tab from '../common/Tab';
import { setSelectedTab, fetchCourseData, setFilterValue } from '../../redux/courses/actions';
import Card from '../common/Card';
import { DebounceInput } from 'react-debounce-input';
import { addCourseEnrollemnt, getCourseEnrollmentsFromCourse } from '../../api/coursesApi';
import { toast } from 'react-toastify';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../service/toastService'; // Importing the toast messages
import { showModal } from '../../redux/system/actions';
import CreateCourseEnrollmentModal from '../common/modals/CreateCourseEnrollmentModal';
import RequestNewCourseModal from '../common/modals/RequestNewCourseModal';
import ViewLinkCourseModal from '../common/modals/ViewLinkCourseModal';

const CREATE_TS_MODAL_ID = 'createTS';
const CREATE_TS_NEW_COURSE_MODAL_ID = 'createTSNewCourse';
const CREATE_TS_VIEW_LINK_MODAL_ID = 'createTSViewCourseLink';

export default function CoursesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { category } = location.state;
  const { selectedTab, skip, take, totalCount, filterValue } = useSelector((state) => state.courses);

  const { isFetchingCourses, triedFetchingCourses } = useSelector((state) => state.system);
  const { courses } = useSelector((state) => state.courses);
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  const [selectedCourse, setSelectedCourse] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleShowModal = useCallback((modalId, course) => dispatch(showModal(modalId, course)), [dispatch]);

  const handleChangeTab = useCallback(
    (selectedTab) => {
      dispatch(setSelectedTab(selectedTab));
      dispatch(fetchCourseData(category.id, 0, take, selectedTab, filterValue, sortedColumn, sortDirection === 'asc' ? 0 : 1)); // Reset skip to 0 when changing tab
    },
    [dispatch, category.id, take]
  );

  const handleSort = useCallback(
    (columnName) => {
      const newSortDirection = sortedColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
      setSortedColumn(columnName);
      setSortDirection(newSortDirection);

      if (selectedTab !== 0) {
        dispatch(setSelectedTab(0));
      }

      dispatch(fetchCourseData(category.id, skip, take, 0, filterValue, columnName, newSortDirection === 'asc' ? 0 : 1));
    },
    [sortedColumn, sortDirection, dispatch, category.id, skip, take, selectedTab, filterValue]
  );

  const handleConfirm = (confirmed) => {
    setIsOpen(false);
    if (confirmed) {
      enrollCourse(selectedCourse);
    }
  };

  const handleChangeFilter = useCallback(
    (e) => {
      const filter = e.target.value;
      dispatch(setFilterValue(filter));
      dispatch(fetchCourseData(category.id, skip, take, selectedTab, filter.trim(), sortedColumn, sortDirection === 'asc' ? 0 : 1));
    },
    [dispatch, category.id, skip, take]
  );

  useEffect(() => {
    handleChangeTab(selectedTab);
  }, [handleChangeTab, selectedTab]);

  const handleChangePage = (pageIndex) => {
    const skip = pageIndex * take;
    dispatch(fetchCourseData(category.id, skip, take, selectedTab, filterValue, sortedColumn, sortDirection === 'asc' ? 0 : 1));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(fetchCourseData(category.id, 0, event.target.value, selectedTab, filterValue, sortedColumn, sortDirection === 'asc' ? 0 : 1));
  };

  const navigateToCourseCategories = () => {
    dispatch(setSelectedTab(0));
    history.push('/courses');
  };

  const navigateDetailPage = (course) => {
    history.push({
      pathname: `/courses/detail/${course.id}`,
      state: { course: course, category: category },
    });
  };

  const isUserEnrolled = (course) => {
    for (const enrollment of course.courseEnrollments) {
      if (currentLoggedUserId === enrollment.userId) {
        if (enrollment.status === 'Active') {
          return 'Enrolled';
        } else if (enrollment.status === 'Requested') {
          return 'Requested';
        } else if (enrollment.status === 'Denied') {
          return 'Denied';
        }
        return enrollment.status; // User is already enrolled
      }
    }
    return false; // User is not enrolled
  };

  const enrollCourse = (course) => {
    const result = getCourseEnrollmentsFromCourse(course.id);

    result
      .then((enrollment) => {
        for (const enroll of enrollment.data) {
          if (currentLoggedUserId === enroll.user.id) {
            toast.error('You already enrolled for this course', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
            return true; // User is already enrolled
          }
        }
        // User is not enrolled
        return false;
      })
      .then((isEnrolled) => {
        if (!isEnrolled) {
          // Proceed with the enrollment process
          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${currentDate.getFullYear()}`;

          const [day, month, year] = formattedDate.split('/');
          const dateOfEnrollment = new Date(`${year}-${month}-${day}`);

          const courseEnrollment = {
            dateOfEnrollment: dateOfEnrollment,
            motivationLetter: 'N.A.',
            reviewReminderEmail: '',
            status: 'Active',
            courseId: course.id,
            userId: currentLoggedUserId,
            tenant: '',
          };

          addCourseEnrollemnt(courseEnrollment)
            .then(() => {
              toast.success('You have successfully enrolled for this course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
              dispatch(fetchCourseData(category.id, skip, take, selectedTab));
            })
            .catch(() => {
              toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
            });
        }
      })
      .catch(() => {
        toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      });
  };

  const columns = (
    <>
      <th className="col-lg-2" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('name')}>
        Name <span>{sortedColumn === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-2" style={{ whiteSpace: 'nowrap' }}>
        StartDate <span>{sortedColumn === 'startDate' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-2" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('company')}>
        Company <span>{sortedColumn === 'company' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-1" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('place')}>
        Place <span>{sortedColumn === 'place' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-1" style={{ whiteSpace: 'nowrap' }}>
        Duration <span>{sortedColumn === 'duration' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-1" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('certificate')}>
        Certificate <span>{sortedColumn === 'certificate' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-1" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('price')}>
        Price <span>{sortedColumn === 'price' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-1" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('review')}>
        Review <span>{sortedColumn === 'review' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>

      <th className="col-lg-1" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => handleSort('link')}>
        Link <span>{sortedColumn === 'link' && (sortDirection === 'asc' ? '▲' : '▼')}</span>
      </th>
      <th className="col-lg-2" style={{ whiteSpace: 'nowrap' }}>
        Type of course
      </th>
      <th className="col-lg-1">Enrollment</th>
    </>
  );

  const sortedCourses = useMemo(() => {
    let sorted = [...courses];
    if (sortedColumn) {
      sorted = sorted.sort((a, b) => {
        if (sortedColumn === 'duration') {
          // Parse durations into a common unit (hours) for comparison
          const parseDuration = (duration) => {
            const [valueA, unitA] = duration.split(' ');

            if (unitA === 'hours') {
              return parseFloat(valueA);
            } else if (unitA === 'days') {
              return parseFloat(valueA) * 24; // Convert days to hours
            }

            return 0;
          };

          const durationA = parseDuration(a.duration);
          const durationB = parseDuration(b.duration);

          if (sortDirection === 'asc') {
            return durationA - durationB;
          } else {
            return durationB - durationA;
          }
        } else {
          // Standard sorting for other columns
          const valueA = a[sortedColumn];
          const valueB = b[sortedColumn];
          if (valueA < valueB) {
            return sortDirection === 'asc' ? 0 : 1;
          }
          if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : 0;
          }

          return -1;
        }
      });
    }

    return sorted;
  }, [courses, sortedColumn, sortDirection]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const mappedCourses = useMemo(
    () =>
      sortedCourses.map((course, index) => {
        return (
          <tr key={index} className="clickable" onClick={() => navigateDetailPage(course)}>
            <td style={{ verticalAlign: 'middle', maxWidth: '310px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{course.name}</td>
            <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
              {course.selfStudyCourse ? 'Not applicable (self study course)' : formatDate(course.startDate)}
            </td>
            <td style={{ verticalAlign: 'middle', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{course.company}</td>
            <td style={{ verticalAlign: 'middle' }}>{course.place}</td>
            <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{course.duration}</td>
            <td style={{ verticalAlign: 'middle' }}>{course.certificate ? 'Yes' : 'No'}</td>
            <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{course.price === 0 ? 'Free' : '€ ' + course.price}</td>
            <td style={{ verticalAlign: 'middle' }}>
              {!course.review || course.review === 0 ? (
                <p style={{ margin: 0 }}>None</p>
              ) : (
                <>
                  {Array.from({ length: 5 }, (_, index) => (
                    <span
                      key={index}
                      className={`review-star ${index < course.review ? 'filled' : ''}`}
                      style={{
                        fontSize: '20px',
                        margin: 0,
                        padding: 0,
                        color: index < course.review ? '#ffc107' : '#e4e5e9',
                      }}>
                      &#9733;
                    </span>
                  ))}

                  <span style={{ marginLeft: '5px', padding: 0 }}>({course.courseReviews.length})</span>
                </>
              )}
            </td>
            <td style={{ verticalAlign: 'middle', padding: '10px' }}>
              {course.link === 'Yes' ? (
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedCourse(course);
                    handleShowModal(CREATE_TS_VIEW_LINK_MODAL_ID);
                  }}>
                  <i style={{ fontSize: 30, cursor: 'pointer' }} className="pe-7s-paperclip"></i>
                </a>
              ) : (
                'None'
              )}
            </td>

            <td style={{ verticalAlign: 'middle' }}>{course.typeOfCourse}</td>
            <td style={{ verticalAlign: 'middle' }}>
              {isUserEnrolled(course) ? (
                <>
                  <button type="button" className="btn-primary btn width100" disabled>
                    {isUserEnrolled(course)}
                  </button>
                </>
              ) : course.typeOfCourse === 'Free to follow' ? (
                <>
                  <button
                    type="button"
                    className="btn-primary btn width100"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedCourse(course);
                      setIsOpen(true);
                    }}>
                    Enroll
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn-primary btn width100"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedCourse(course);
                    handleShowModal(CREATE_TS_MODAL_ID, course);
                  }}>
                  Request
                </button>
              )}
            </td>
          </tr>
        );
      }),
    [dispatch, courses, sortedCourses]
  );

  const refreshCourses = () => {
    dispatch(fetchCourseData(category.id, skip, take, selectedTab, filterValue, sortedColumn, sortDirection === 'asc' ? 1 : 0));
  };

  return (
    <div className="container-fluid" style={{ marginBottom: '10px' }}>
      <div className="custom-flex-2">
        <div className="custom-flex">
          <button type="button" className="btn btn-primary documents-row-button" onClick={() => handleShowModal(CREATE_TS_NEW_COURSE_MODAL_ID)}>
            <i className="fas fa-plus"></i> Request new course
          </button>

          <RequestNewCourseModal id={CREATE_TS_NEW_COURSE_MODAL_ID} courseCategory={category} />

          <Tabs value={selectedTab} onChange={(_, tabValue) => handleChangeTab(tabValue)} TabIndicatorProps={{ style: { background: 'var(--accent-color)' } }}>
            <Tab label="All" />
            <Tab label="Free to follow" />
            <Tab label="Other courses" />
          </Tabs>
        </div>
        <div>
          <button className="btn btn-primary documents-row-button" onClick={navigateToCourseCategories}>
            <i className="fas fa-arrow-left"></i>
            Go back
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="confirmation-overlay modal backdrop-modal">
          <div className="confirmation-popup">
            <p className="">Are you sure?</p>
            <div className="button-container">
              <button className="btn btn-secondary" onClick={() => handleConfirm(false)}>
                No
              </button>
              <button className="btn btn-primary" onClick={() => handleConfirm(true)}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .confirmation-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
          text-align: center;
        }

        .btn {
          margin: 0 5px;
        }

        .confirmation-popup {
          background-color: #252525;
          padding: 20px;
        }

        .button-container {
          margin-top: 10px;
        }
      `}</style>

      <Card title="Courses" icon="pe-7s-notebook" iconGradient="bg-green-blue-top" contentClass={'card-table-content'}>
        <DebounceInput
          type="text"
          value={filterValue}
          onChange={handleChangeFilter}
          className="form-control"
          placeholder="Search for courses"
          debounceTimeout="250"
          autoFocus={true}
        />

        {isFetchingCourses || !triedFetchingCourses ? (
          <Loading message="Hang on, we're loading courses." className="mt-5" />
        ) : mappedCourses.length > 0 ? (
          <>
            <CreateCourseEnrollmentModal id={CREATE_TS_MODAL_ID} course={selectedCourse} onCourseEnrollmentAdded={refreshCourses} />
            <Table
              columns={columns}
              data={mappedCourses}
              totalCount={totalCount}
              skip={skip}
              take={take}
              changePageHandler={(_, pageIndex) => handleChangePage(pageIndex)}
              changeRowsPerPageHandler={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <div className={courses.length === 0 && 'filter-no-data'}>
            <i className="pe-7s-help1 icon-gradient bg-green-blue-top mr-1" />
            No courses found.
          </div>
        )}
      </Card>

      <ViewLinkCourseModal id={CREATE_TS_VIEW_LINK_MODAL_ID} course={selectedCourse} />
    </div>
  );
}
