import React, { useEffect, useState } from 'react';
import { getCourseEnrollmentsFromUser } from '../../api/coursesApi';
import { useSelector } from 'react-redux';

export default function OverviewPage({ goToMyEnrollments }) {
  const currentLoggedUserId = useSelector((state) => state.users.selectedUser.myportalUserId);

  // Assuming these values are retrieved from some data source
  const [numberOfActiveEnrollments, setNumberOfActiveEnrollments] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCourseEnrollmentsFromUser(currentLoggedUserId);
      const filteredResult = result.data.filter((enrollment) => enrollment.status === 'Active');

      setNumberOfActiveEnrollments(filteredResult.length);
      setLoading(false);
    };
    fetchData();
  }, [currentLoggedUserId]);

  const handleHover = (event) => {
    event.currentTarget.style.transform = 'translateY(-5px)';
    event.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  };

  const handleHoverOut = (event) => {
    event.currentTarget.style.transform = 'translateY(0)';
    event.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  };

  return (
    <div className="container-fluid" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <div className="dashboard" style={{ display: 'grid', gap: '20px' }}>
        <div
          className="dashboard-item"
          style={{
            padding: '20px',
            borderRadius: '8px',
            marginBottom: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
          }}
          onClick={goToMyEnrollments}
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverOut}>
          <div style={{ flex: '1' }}>
            <i className="fas fa-book" style={{ fontSize: '64px', color: 'var(--accent-color)' }}></i>
          </div>
          <div style={{ flex: '3' }}>
            <h3 style={{ margin: '0', fontSize: '24px', fontWeight: 'bold' }}>Active Enrollments</h3>
            <p style={{ color: '#999', margin: '0', fontSize: '14px' }}>Number of active enrollments</p>
          </div>
          <div style={{ flex: '1', textAlign: 'right' }}>
            {loading ? <span className="dot-pulse"></span> : <p style={{ margin: '0', fontSize: '32px' }}>{numberOfActiveEnrollments}</p>}
          </div>
        </div>
        {/* Additional cards can be added here */}
      </div>
    </div>
  );
}
