import { fromAddress } from '../../api/geoCodeApi';
export const GEOCODE_ADDRESS = 'GEOCODE_ADDRESS';
export const GEOCODE_ADDRESS_SUCCESS = 'GEOCODE_ADDRESS_SUCCESS';
export const GEOCODE_ADDRESS_FAILED = 'GEOCODE_ADDRESS_FAILED';

export function fetchGeoCodeAddress(address, contractId) {
    return async function (dispatch) {
      dispatch({ type: GEOCODE_ADDRESS });
      try {
        const response = await fromAddress(address);
        return dispatch({ type: GEOCODE_ADDRESS_SUCCESS, location: { ...response, contractId } });
      } catch (err) {
        return dispatch({ type: GEOCODE_ADDRESS_FAILED, err, contractId, address });
      }
    };
  }