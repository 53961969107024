import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

function UploadTimesheetModal({ id, confirmHandler }) {
  const [file, setFile] = useState(null);
  const handleFileChange = useCallback((event) => setFile(event.target.files[0]), []);

  return (
    <Modal
      id={id}
      icon="pe-7s-paint-bucket"
      iconGradient="bg-green-blue-top"
      title="Upload Your Timesheet"
      confirmHandler={() => confirmHandler(file)}>
      <div className="form-group files">
        <input
          type="file"
          className="form-control"
          multiple=""
          onChange={handleFileChange}
          style={{ height: 'auto' }}
        />
      </div>
    </Modal>
  );
}

UploadTimesheetModal.propTypes = {
  id: PropTypes.any.isRequired,
  confirmHandler: PropTypes.func.isRequired,
};

export default memo(UploadTimesheetModal);
