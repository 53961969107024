import React from 'react';
import { useSelector } from 'react-redux';
import ContractsListCard from '../components/dashboard/contracts/ContractsListCard';
import CurrentCreditsCard from '../components/dashboard/credits/CurrentCreditsCard';
import CurrentTimesheetCard from '../components/dashboard/currenttimesheet/CurrentTimesheetCard';
import NeedHelpCard from '../components/dashboard/needhelp/NeedHelpCard';
import { shouldBeAbleToSeeCreditsSelector, shouldBeAbleToSeeTimesheets } from '../redux/helperSelectors';
import Card from '../components/common/Card';

function HomePage() {
  const shouldBeAbleToSeeCredits = useSelector((state) => shouldBeAbleToSeeCreditsSelector(state));
  const shouldBeAbleToSeeTimesheetsCard = useSelector((state) => shouldBeAbleToSeeTimesheets(state));

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        {shouldBeAbleToSeeTimesheetsCard ? (
          <div className="col-lg-6">
            <CurrentTimesheetCard />
          </div>) :
          (
            <div className="col-lg-6">
              <Card title="Timesheets" icon="pe-7s-copy-file" iconGradient="bg-green-blue-top" contentClass="no-current-card">
                  <div>You cannot view any timesheets since you are not assigned to a valid tenant.</div>
              </Card>
            </div>
          )
        }
        {shouldBeAbleToSeeCredits ? (
          <>
            <div className="col-lg-2">
              <CurrentCreditsCard />
            </div>
            <div className="col-lg-4">
              <NeedHelpCard />
            </div>
          </>
        ) : (
          <div className="col-lg-6">
            <NeedHelpCard />
          </div>
        )}
      </div>
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <ContractsListCard onlyActive={true} collapseRows={true} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
