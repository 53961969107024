import {
  FETCH_CREDITS,
  FETCH_CREDITS_FAILED,
  FETCH_CREDITS_SUCCESS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_FAILED,
  FETCH_TRANSACTIONS_SUCCESS,
} from './actions';

export default function transactionsReducer(state = { ...TRANSACTIONS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_TRANSACTIONS:
    case FETCH_CREDITS:
    case FETCH_TRANSACTIONS_FAILED:
    case FETCH_CREDITS_FAILED:
      return state;

    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsList: [...action.transactions],
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
      };

    case FETCH_CREDITS_SUCCESS:
      return {
        ...state,
        credits: action.credits,
      };

    default:
      return state;
  }
}

export const TRANSACTIONS_BASE_STATE = {
  transactionsList: [],
  credits: null,
  skip: null,
  take: null,
  totalCount: null,
};
