import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs as MuiTabs } from '@mui/material';

const font = 'Campton-Book, Helvetica Neue, Helvetica, Arial, sans-serif !important';

const CustomTabs = styled(MuiTabs)(() => ({
  root: {
    fontFamily: font,
  },
}));

export default function Tabs(props) {
  return <CustomTabs {...props} />;
}
