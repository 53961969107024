import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

function VerticalTimelineItem({ icon, iconClass, title, date, by }) {
  const iconClassName = useMemo(() => `${icon} ${iconClass ? `icon-gradient ${iconClass}` : ''}`, [icon, iconClass]);

  return (
    <div className="vertical-timeline-item">
      <div className="vertical-timeline-item--icon">
        <div className="timeline-icon">
          <i className={iconClassName} />
        </div>
      </div>
      <div className="vertical-timeline-item--content">
        <div className="timeline-title">{title}</div>
        <small className="dark-shade" style={{ position: 'absolute' }}>
          {`${date} by ${by}`}
        </small>
      </div>
    </div>
  );
}

VerticalTimelineItem.propTypes = {
  icon: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  by: PropTypes.string.isRequired,
};

export default memo(VerticalTimelineItem);
