import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Button({ text, title, clickHandler, disabled, ...props }) {
  return (
    <button className={`btn btn-sm ${props.class}`} title={title} onClick={() => clickHandler()} disabled={disabled}>
      {text}
    </button>
  );
}

Button.propTypes = {
  class: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Button);
