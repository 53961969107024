import { getAzureBlobUrl, getNews, getNewsCount, getNewsItem } from '../../api/newsApi';
import { showRequestFailedToast } from '../../service/toastService';

export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILED = 'FETCH_NEWS_FAILED';

export const FETCH_NEWS_ITEM = 'FETCH_NEWS_ITEM';
export const FETCH_NEWS_ITEM_SUCCESS = 'FETCH_NEWS_ITEM_SUCCESS';
export const FETCH_NEWS_ITEM_FAILED = 'FETCH_NEWS_ITEM_FAILED';

export const FETCH_NEWS_COUNT = 'FETCH_NEWS_COUNT';
export const FETCH_NEWS_COUNT_SUCCESS = 'FETCH_NEWS_COUNT_SUCCESS';
export const FETCH_NEWS_COUNT_FAILED = 'FETCH_NEWS_COUNT_FAILED';

export const FETCH_UZURE_BLOB_URL = 'FETCH_UZURE_BLOB_URL';
export const FETCH_UZURE_BLOB_URL_SUCCESS = 'FETCH_UZURE_BLOB_URL_SUCCESS';
export const FETCH_UZURE_BLOB_URL_FAILED = 'FETCH_UZURE_BLOB_URL_FAILED';

export function fetchNewsCount(tenantId, userType) {
  return async function (dispatch) {
    dispatch({ type: FETCH_NEWS_COUNT });
    try {
      const lastTimeNewsFetch = localStorage.getItem("LastTimeNewsFetch");
      const response = await getNewsCount({ tenantId: tenantId, userType: userType, aftherDate: lastTimeNewsFetch ? new Date(lastTimeNewsFetch) : new Date() });
      localStorage.setItem("LastTimeNewsFetch", new Date().toISOString());
      return dispatch({
        type: FETCH_NEWS_COUNT_SUCCESS,
        newsCount: response.data
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_NEWS_COUNT_FAILED, error });
    }
  };
}

export function fetchNews(tenantId, userType, skip, take) {
  return async function (dispatch) {
    dispatch({ type: FETCH_NEWS });
    try {
      const response = await getNews({ tenantId: tenantId, userType: userType, skip: skip, take: take});
      return dispatch({
        type: FETCH_NEWS_SUCCESS,
        news: response.data
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_NEWS_FAILED, error });
    }
  };
}

export function fetchNewsItem(id) {
  return async function (dispatch) {
    dispatch({ type: FETCH_NEWS_ITEM });
    try {
      const response = await getNewsItem(id);
      return dispatch({
        type: FETCH_NEWS_ITEM_SUCCESS,
        newsItem: response.data
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_NEWS_ITEM_FAILED, error });
    }
  };
}

export function fetchAzureBlobUrl() {
  return async function (dispatch) {
    dispatch({ type: FETCH_UZURE_BLOB_URL });
    try {
      const response = await getAzureBlobUrl();
      return dispatch({
        type: FETCH_UZURE_BLOB_URL_SUCCESS,
        url: response.data
      });
    } catch (error) {
      showRequestFailedToast();
      return dispatch({ type: FETCH_UZURE_BLOB_URL_FAILED, error });
    }
  };
}
