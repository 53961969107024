import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../redux/system/actions';
import Card from '../common/Card';
import CreateTimesheetModal from '../common/modals/CreateTimesheetModal';
import SelectContractModal from '../common/modals/SelectContractModal';
import Loading from '../common/Loading';
import NoContractsCard from '../dashboard/contracts/NoContractsCard';
import NoTimesheetsCard from './NoTimesheetsCard';
import TimesheetsList from './TimesheetsList';
import { stopLoadingSelector } from '../../redux/helperSelectors';
import TimesheetService from '../../service/timesheetService';

const MODAL_ID = 'selectContractsList';
const CREATE_TS_MODAL_ID = 'createTS';

function TimesheetsListCard() {
  const dispatch = useDispatch();
  const { handleCreateTimesheet } = TimesheetService();
  const contracts = useSelector((state) => state.contracts.contractsList);
  const timesheets = useSelector((state) => state.timesheets.timesheetList);
  const { nameToDisplay } = useSelector((state) => state.users);
  const { isFetchingContracts, isFetchingTimesheets, isSavingTimesheet, isFetchingProfile } = useSelector(
    (state) => state.system
  );
  const stopLoading = useSelector((state) => stopLoadingSelector(state));
  const isLoading = (isFetchingTimesheets || isFetchingContracts || isFetchingProfile) && !stopLoading;

  const handleShowModal = useCallback((modalId) => dispatch(showModal(modalId)), [dispatch]);

  return !isLoading && !isSavingTimesheet ? (
    !contracts || !contracts.length ? (
      <NoContractsCard title={`${nameToDisplay} Timesheets`} />
    ) : !timesheets || !timesheets.length ? (
      <NoTimesheetsCard title={`${nameToDisplay} Timesheets`} />
    ) : (
      <>
        <Card
          title={`${nameToDisplay} Timesheets`}
          icon="pe-7s-copy-file"
          iconGradient="bg-green-blue-top"
          contentClass="card-table-content"
          button={
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleShowModal(CREATE_TS_MODAL_ID)}
              disabled={contracts.length === 0}>
              Create a new timesheet
            </button>
          }>
          <TimesheetsList />
        </Card>
        {contracts.length && (
          <>
            <SelectContractModal
              id={MODAL_ID}
              showCurrentMonthInMessage={true}
              confirmHandler={(contract) => handleCreateTimesheet(contract)}
            />
            <CreateTimesheetModal id={CREATE_TS_MODAL_ID} />
          </>
        )}
      </>
    )
  ) : (
    <>
      <Card
        title={`${nameToDisplay} Timesheets`}
        icon="pe-7s-copy-file"
        iconGradient="bg-green-blue-top"
        contentClass="no-current-card"
        button={
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleShowModal(CREATE_TS_MODAL_ID)}
            disabled={contracts.length === 0}>
            Create a new timesheet
          </button>
        }>
        <Loading message={`Hang on, we're ${isLoading ? 'loading' : 'still saving'} your timesheets.`} />
      </Card>
    </>
  );
}

export default TimesheetsListCard;
