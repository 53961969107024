import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tab as MuiTab } from '@mui/material';

const CustomTab = styled(MuiTab)(() => ({
  '&.MuiTab-root': {
    fontWeight: '600 !important',
    color: 'var(--text-color)',
    '&:hover': {
      color: 'var(--accent-color)',
    },
  },
  '&.MuiTab-root.Mui-selected': {
    color: 'var(--accent-color)',
  },
}));

export default function Tab(props) {
  return <CustomTab {...props} />;
}
