import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { showModal } from '../../redux/system/actions';
import { downloadValidatedTimesheet } from '../../redux/timesheets/actions';
import { approveTimesheet, rejectTimesheet } from '../../redux/validateTimesheets/actions';
import Button from '../common/Button';
import Card from '../common/Card';
import Modal from '../common/Modal';
import RejectTimesheetModal from '../common/modals/RejectTimesheetModal';
import ExpensesPerDescription from '../timesheets/ExpensesPerDescription';
import ValidateHeader from './ValidateHeader';
import { getValidationErrorToastPropsBy } from '../../service/toastService';
import TimePerDescription from '../timesheets/TimePerDescription';

const rejectModalId = 'rejectModal';
const downloadModalId = 'downloadModalId';

function ValidateTimesheetPage({ timesheet, contract, publicLink }) {
  const dispatch = useDispatch();
  const { isSubmitting, isApproved, isRejected } = useSelector((state) => state.validateTimesheets);
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(timesheet.state !== 2);
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(timesheet.state === 2);

  const timesheetMonth = useMemo(() => new Date(timesheet.start).toLocaleString('default', { month: 'long' }), [timesheet.start]);

  const timesheetYear = useMemo(() => new Date(timesheet.start).getFullYear(), [timesheet.start]);

  const timesheetStartDate = useMemo(() => new Date(timesheet.start).toLocaleString('nl-BE').split(' ')[0], [timesheet.start]);

  const timesheetEndDate = useMemo(() => new Date(timesheet.end).toLocaleString('nl-BE').split(' ')[0], [timesheet.end]);

  const formatDate = (date) => date.trim().replace(/,$/, '');
  const title = useMemo(() => {
    const startDate = formatDate(timesheetStartDate);
    const endDate = formatDate(timesheetEndDate);

    return isApproved || timesheet.state === 2
      ? `The timesheet for ${startDate} - ${endDate} has been approved.`
      : isRejected
      ? `The timesheet for ${startDate} - ${endDate} has been rejected.`
      : `Please verify the timesheet for ${contract.user.firstName} ${contract.user.lastName} (${startDate} - ${endDate})`;
  }, [contract.user.firstName, contract.user.lastName, isApproved, isRejected, timesheet.state, timesheetEndDate, timesheetStartDate]);

  const mappedTimesheetRows = useMemo(
    () =>
      timesheet.days.map((day) =>
        day.entries.map((entry, dayIndex) => {
          const date = moment(day.date);
          const isWeekend = date.day() === 0 || date.day() == 6;
          const isSaturday = date.day() == 6;
          const isSunday = date.day() === 0;

          return (
            <React.Fragment key={`${date}-${dayIndex}`}>
              <tr
                className={`${isWeekend ? 'weekend' : ''} ${isSaturday ? 'border-bottom-0' : isSunday ? 'border-top-0' : ''} ${
                  dayIndex === 0 ? 'border-top' : 'border-top-0'
                }`}>
                <td className="col-2">{dayIndex == 0 && date.format('ddd, MMM Do')}</td>
                <td className="col-0">{entry.isHomework && <i className="pe-7s-home icon-gradient bg-green-fade" />}</td>
                <td className="col-3"> {typeof entry.type == 'string' ? entry.type : entry.type != null && entry.type.name != null ? entry.type.name : ''} </td>
                <td className="col-5">
                  {entry.projectName && <b>{entry.projectName}</b>}
                  {entry.remarks}
                </td>
                <td className="col-2">{entry.amount}</td>
              </tr>
              {entry.expenses.length > 0 &&
                entry.expenses.map((expense, entryIndex) => {
                  return (
                    <tr key={`expense-${entryIndex}`} className={`${isWeekend ? 'weekend' : ''} ${isSaturday ? 'border-bottom-0' : isSunday ? 'border-top-0' : ''}`}>
                      <td></td>
                      <td></td>
                      <td className={`${isWeekend && 'weekend'}`}>
                        <span className={`t4t-badge ${expense.type == 'Expense' ? 'bg-purple-fade' : 'bg-orange-fade'}`}>{expense.type}</span>
                      </td>
                      <td className={`${isWeekend && 'weekend'}`}>
                        {<b></b>}
                        {expense.remarks}
                      </td>
                      <td className={`${isWeekend && 'weekend'}`}>
                        {expense.type == 'Expense' ? `€ ${expense.amount ? expense.amount.toFixed(2) : '0'}` : `${expense.amount ? expense.amount.toFixed(2) : '0'} km`}
                      </td>
                    </tr>
                  );
                })}
            </React.Fragment>
          );
        })
      ),
    [timesheet.days]
  );

  const handleApproveTimesheet = useCallback(() => {
    dispatch(approveTimesheet(timesheet, publicLink.publicLinkHash));
  }, [dispatch, publicLink.publicLinkHash, timesheet]);

  const handleShowRejectTimesheetModal = useCallback(() => dispatch(showModal(rejectModalId)), [dispatch]);

  const handleRejectTimesheet = useCallback(
    (reason) => {
      if (reason == null || reason.trim() === '') {
        const { toastBody, toastOptions } = getValidationErrorToastPropsBy('Please enter a valid reason.');
        toast.error(toastBody, toastOptions);
      } else {
        dispatch(rejectTimesheet(timesheet, publicLink.publicLinkHash, { Reason: reason }));
      }
    },
    [dispatch, publicLink.publicLinkHash, timesheet]
  );

  const handleShowDownloadModal = useCallback(() => dispatch(showModal(downloadModalId)), [dispatch]);

  const handleDownloadTimesheet = useCallback(() => {
    dispatch(downloadValidatedTimesheet(timesheet, publicLink.publicLinkHash));
  }, [dispatch, publicLink.publicLinkHash, timesheet]);

  useEffect(() => {
    if (!isSubmitting && timesheet.state !== 2 && !isRejected && !isApproved) {
      setDownloadButtonDisabled(true);
      setSubmitButtonsDisabled(false);
    } else if (timesheet.state === 2 || isRejected) {
      setSubmitButtonsDisabled(true);
    } else if (isApproved) {
      setDownloadButtonDisabled(false);
    } else {
      setDownloadButtonDisabled(true);
      setSubmitButtonsDisabled(true);
    }
  }, [isApproved, isRejected, isSubmitting, timesheet.state]);

  return (
    <>
      <div>
        <ValidateHeader month={timesheetMonth} year={timesheetYear} />
        <div className="wrapper wrapper-stretch d-flex">
          <div className="validation-content">
            <div className="container-fluid">
              <div className="row row-dashboard gx-3 gy-3">
                <div className="container-fluid">
                  <div className="row row-dashboard">
                    <div className="col-lg-8">
                      <Card
                        title={title}
                        icon="pe-7s-copy-file"
                        iconGradient="bg-green-blue-top"
                        cardClass="rounded-15 mb-2"
                        headerClass="font-weight-bold"
                        button={
                          <Button
                            class="btn btn-sm btn-primary"
                            key=""
                            text="Download Timesheet"
                            title="Download Your Timesheet"
                            clickHandler={handleShowDownloadModal}
                            disabled={downloadButtonDisabled}
                          />
                        }>
                        <>
                          <table className="table validation-table">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th></th>
                                <th>Description</th>
                                <th>Remarks</th>
                                <th>Hours|€|Km</th>
                              </tr>
                            </thead>
                            <tbody>{mappedTimesheetRows}</tbody>
                          </table>
                        </>
                      </Card>
                    </div>
                    <div className="col-lg-4">
                      <div className="t4t-profile-card rounded-15 bg-white">
                        <div className="t4t-profile-card--header bg-fade rounded-t-15" />
                        <div className="t4t-profile-card--header-inner">
                          <div className="d-flex" style={{ flexDirection: 'column', marginLeft: '15px', flex: 1 }}>
                            <div>
                              <strong>{contract.accountManager.name}</strong>
                            </div>
                            <div> is the Account Manager for this contract</div>
                          </div>
                        </div>
                        <div className="t4t-profile-card--content">
                          <label className="section-title">Contract Details</label>

                          <div className="row">
                            <div className="col-sm-5">Contract ID:</div>
                            <div className="col-sm-7 dark-shade">{contract.contractId}</div>
                          </div>

                          <div className="row">
                            <div className="col-sm-5">Consultant:</div>
                            <div className="col-sm-7 dark-shade">
                              {contract.user.firstName} {contract.user.lastName}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-5">Client Name:</div>
                            <div className="col-sm-7 dark-shade">{contract.client.name}</div>
                          </div>

                          <div className="row">
                            <div className="col-sm-5">Contact Person:</div>
                            <div className="col-sm-7 dark-shade">{contract.client.contactPerson}</div>
                          </div>

                          <div className="row">
                            <div className="col-sm-5">Signer:</div>
                            <div className="col-sm-7 dark-shade">{timesheet.tempTimesheetSignerEmail || contract.client.timesheetSigner}</div>
                          </div>

                          <hr />

                          <label className="section-title">Job Details</label>

                          <div className="row">
                            <div className="col-sm-5">Name:</div>
                            <div className="col-sm-7 dark-shade">{contract.job.name}</div>
                          </div>

                          {/* <div className="row">
                            <div className="col-sm-5">Description:</div>
                            <div className="col-sm-7 dark-shade">{contract.job.description}</div>
                          </div> */}

                          <div className="row">
                            <div className="col-sm-5">Hours per Day:</div>
                            <div className="col-sm-7 dark-shade">{contract.job.standardHoursPerDay} h</div>
                          </div>

                          <hr />

                          <TimePerDescription
                            rates={contract.rates}
                            days={timesheet.days}
                            standardHoursPerDay={contract.job.standardHoursPerDay}
                            className="section-title"
                          />
                          <ExpensesPerDescription days={timesheet.days} className="section-title" />
                        </div>
                      </div>

                      {!submitButtonsDisabled && (
                        <div className="d-flex justify-content-center mt-2">
                          <Button
                            class="btn-primary w-60 rounded-15 py-2 mr-1 fs-m"
                            clickHandler={handleApproveTimesheet}
                            text="Approve Timesheet"
                            disabled={submitButtonsDisabled}
                          />
                          <Button
                            class="btn-danger w-40 rounded-15 py-2"
                            clickHandler={handleShowRejectTimesheetModal}
                            text="Reject Timesheet"
                            disabled={submitButtonsDisabled}
                          />
                        </div>
                      )}
                      {((submitButtonsDisabled && timesheet.state === 2) || isApproved) && (
                        <div className="d-flex justify-content-center approved-container background-primary rounded-15 mt-2">This timesheet is approved!</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RejectTimesheetModal id={rejectModalId} rejectionReasonEntered={handleRejectTimesheet} />
      <Modal
        id={downloadModalId}
        icon="pe-7s-help1"
        iconGradient="bg-green-blue-top"
        title="Before you start downloading..."
        confirmText="Download"
        confirmHandler={handleDownloadTimesheet}
        backdrop={true}>
        <p>You are now going to download the validated timesheet!</p>
      </Modal>
    </>
  );
}

ValidateTimesheetPage.propTypes = {
  timesheet: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  publicLink: PropTypes.object.isRequired,
};

export default ValidateTimesheetPage;
