import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';
import { updateCourseEnrollment } from '../../../api/coursesApi';
import { toast } from 'react-toastify';

function EditRequestedEnrollmentModal({ id, courseEnrollment, onSubmit }) {
  const [updatedCourseEnrollment, setUpdatedCourseEnrollment] = useState(courseEnrollment);

  useEffect(() => {
    setUpdatedCourseEnrollment(courseEnrollment);
  }, [courseEnrollment]);

  const closeModal = () => {
    setUpdatedCourseEnrollment(courseEnrollment);
  };

  const handleUpdateCourseEnrollment = async () => {
    try {
      await updateCourseEnrollment(updatedCourseEnrollment);
      toast.success('You have successfully updated the requested enrollment', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const isConfirmButtonDisabled = () => {
    return !updatedCourseEnrollment.motivationLetter;
  };

  return (
    <Modal
      isLarge={true}
      id={id}
      icon="pe-7s-pen"
      iconGradient="bg-green-blue-top"
      title="Edit requested enrollment"
      contentClass="modal-body-center-h"
      confirmText="UPDATE REQUEST"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleUpdateCourseEnrollment}>
      <div style={{ width: '100%' }}>
        <label>Request</label>
        <textarea
          onChange={(e) => setUpdatedCourseEnrollment({ ...updatedCourseEnrollment, motivationLetter: e.target.value })}
          placeholder="Tell us why you want to participate in this course..."
          rows={5}
          className="form-control"
          value={updatedCourseEnrollment.motivationLetter}></textarea>
      </div>
    </Modal>
  );
}

EditRequestedEnrollmentModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(EditRequestedEnrollmentModal);
