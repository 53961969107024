import { getCoursesFromCourseCategory } from '../../api/coursesApi';
import { showRequestFailedToast } from '../../service/toastService';

export const FREE_TO_FOLLOW = 1;
export const TO_REQUEST = 2;

export const FETCH_COURSE_DATA = 'FETCH_COURSE_DATA';
export const FETCH_COURSE_DATA_SUCCES = 'FETCH_COURSE_DATA_SUCCES';

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SELECT_COURSE = 'SELECT_COURSE';

export function fetchCourseData(categoryId, skip, take, historyType, filter, columns, sortOrders) {
  return async function (dispatch) {
    dispatch({ type: FETCH_COURSE_DATA });
    try {
      const response = await getCoursesFromCourseCategory(categoryId, { skip, take, historyType, filter, columns, sortOrders });
      const totalCount = response.data.totalCount;

      let updatedSkip = skip;

      if (updatedSkip !== 0) {
        updatedSkip = Math.floor(skip / take);
      }

      dispatch({
        type: FETCH_COURSE_DATA_SUCCES,
        courses: response.data.data,
        historyType,
        skip: updatedSkip,
        take,
        filter,
        columns,
        sortOrders,
        totalCount,
      });
    } catch (error) {
      showRequestFailedToast();
    }
  };
}

export function setFilterValue(filter) {
  return { type: SET_FILTER_VALUE, filter };
}

export function setSelectedTab(selectedTab) {
  return { type: SET_SELECTED_TAB, selectedTab };
}
