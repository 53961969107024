import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import Modal from '../Modal';
import Select from '../Select';
import { DebounceInput } from 'react-debounce-input';
import * as inputValidation from '../../../service/inputValidationService';

function AutoCompleteModal({ id, defaultAmount, isFreeTextAllowed, rates, confirmHandler }) {
  const [type, setType] = useState(rates && rates.length ? rates[0].key : null);
  const [amount, setAmount] = useState(defaultAmount || 8);
  const [remarks, setRemarks] = useState(null);
  const [isHomework, setIsHomework] = useState(false);
  const rateOptions = useMemo(
    () =>
      rates.map((rate) => {
        return { value: rate.key, text: rate.name };
      }),
    [rates]
  );

  const setHoursAmount = useCallback((e) => {
    inputValidation.enforceDecimal(e, e.target);
    setAmount(e.target.value);
  }, []);

  const typeInputComponent = isFreeTextAllowed ? (
    <input type="text" maxLength={20} placeholder='Max 20 characters' className="form-control" value={type || ''} onChange={(e) => setType(e.target.value)} />
  ) : (
    <Select
      options={rateOptions}
      selected={type}
      changeHandler={(value) => setType(() => value)}
      defaultEmpty={false}
    />
  );

  return (
    <Modal
      id={id}
      icon="pe-7s-paint-bucket"
      iconGradient="bg-green-blue-top"
      title="Autocomplete"
      confirmHandler={() => confirmHandler({ type, amount, remarks, isHomework })}
      confirmButtonDisabled={amount < 0 || amount > 24}
      disabledText="Please verify all inputs">
      <p>
        You can autocomplete this timesheet by {isFreeTextAllowed ? 'entering' : 'selecting'} the description and amount
        of the entries below. This will not override entries.
        <br />
      </p>
      <p>Make sure you check the entries before submitting.</p>
      <div className="row">
        <div className="col-md-6">
          <label className="dark-shade">Description:</label>
          {typeInputComponent}
          <input
            type="text"
            className="form-control"
            placeholder="Remarks (max 40 characters)"
            style={{ marginTop: '10px' }}
            value={remarks || ''}
            maxLength={40}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label className="dark-shade">Amount:</label>
          <DebounceInput
            className="form-control"
            type="number"
            data-decimal={2}
            value={amount}
            min={0}
            max={24}
            step="any"
            onInput={(e) => setHoursAmount(e)}
            onChange={() => { }}
            debounceTimeout="100"
          />
          {
            (amount < 0 || amount > 24) &&
            <p className="error-text">Value must be between 0 and 24</p>
          }
          <div className="row-md-8 mt-2">
            <input
              id="homeworkCheckbox"
              type="checkbox"
              defaultChecked={isHomework}
              onChange={() => setIsHomework(prevValue => !prevValue)}
            />
            <label className="dark-shade ml-1" htmlFor="homeworkCheckbox">
              Set days to Homework?
            </label>

          </div>
        </div>
      </div>
    </Modal>
  );
}

AutoCompleteModal.propTypes = {
  id: PropTypes.any.isRequired,
  rates: PropTypes.array,
  hours: PropTypes.number,
  isFreeTextAllowed: PropTypes.bool,
  confirmHandler: PropTypes.func,
  defaultAmount: PropTypes.number,
};

export default memo(AutoCompleteModal);
