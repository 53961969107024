import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactionsByUserId } from '../redux/transactions/actions';
import TransactionsListCard from '../components/transactions/TransactionsListCard';

function Transactions() {
  const dispatch = useDispatch();
  const { isFetchingTransactions, triedFetchingTransactions } = useSelector((state) => state.system);
  const { selectedUser } = useSelector((state) => state.users);

  useEffect(() => {
    if (!isFetchingTransactions && !triedFetchingTransactions && selectedUser.myportalUserId) {
      dispatch(fetchTransactionsByUserId(selectedUser.myportalUserId, 0, 20));
    }
  }, [dispatch, isFetchingTransactions, triedFetchingTransactions, selectedUser.myportalUserId]);

  return (
    <div className="container-fluid">
      <div className="row row-dashboard">
        <div className="col-lg-12">
          <TransactionsListCard />
        </div>
      </div>
    </div>
  );
}

export default Transactions;
