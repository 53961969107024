import api from './baseApi';

export function getCourseCategories() {
  return api.get('/coursecategories');
}

export function getCoursesFromCourseCategory(categoryId, params) {
  return api.get('/courses/category/' + categoryId + '?IsPublic=true&OnlySeeUpcomingCourses=true', params);
}

export function addCourseReview(courseReview) {
  return api.post('/coursereviews', courseReview);
}

export function getCourseReviewsFromCourse(courseId) {
  return api.get('/coursereviews/course/' + courseId);
}

export function getUserInfo(userId) {
  return api.get('/users/' + userId);
}

export function getCourseEnrollmentsFromUser(userId) {
  return api.get('/courseEnrollments/user/' + userId);
}

export function getCourseEnrollmentsFromCourse(courseId) {
  return api.get('/courseEnrollments/course/' + courseId);
}

export function addCourseEnrollemnt(courseEnrollment) {
  return api.post('/courseEnrollments', courseEnrollment);
}

export function deleteCourseEnrollment(courseEnrollmentId) {
  return api.delete('/courseEnrollments?courseEnrollmentId=' + courseEnrollmentId);
}

export function deleteCourse(courseId) {
  return api.delete('/courses?courseId=' + courseId);
}

export function addCourse(course) {
  return api.post('/courses', course);
}

export function addCourseDocument(courseDocument) {
  return api.post('/courseDocuments', courseDocument);
}

export function updateCourse(course) {
  return api.put('/courses?courseId=' + course.id, course);
}

export function updateCourseEnrollment(courseEnrollment) {
  return api.put('/courseEnrollments?courseEnrollmentId=' + courseEnrollment.id, courseEnrollment);
}

export function getCoursesFromUser(userId) {
  return api.get('/courses/user/' + userId);
}

export function getCourseDocuments(courseId) {
  return api.get('/courseDocuments/course/' + courseId);
}

export function addCourseLink(courseLink) {
  return api.post('/courseLinks', courseLink);
}
