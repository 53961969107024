import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../common/Loading';
import NewsItemCard from './NewsItemCard';
import { fetchAzureBlobUrl, fetchNews } from '../../redux/news/actions';
import Button from '../common/Button';
import Card from '../common/Card';

const NewsListCard = () => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const news = useSelector((state) => state.news.newsList);
  const { azureBlobUrl, moreToFetch } = useSelector((state) => state.news);
  const { isFetchingNews, triedFetchingNews, isFetchingAzureBlobUrl, triedFetchingAzureBlobUrl } = useSelector((state) => state.system);
  const { tenant_id: tenantIdLoggedInUser, candidate_type: userTypeLoggedInUser } = useSelector((state) => state.auth.user.profile);
  const { nameToDisplay } = useSelector((state) => state.users);  


  useEffect(() => {
    if (
      !azureBlobUrl &&
      !isFetchingAzureBlobUrl &&
      !triedFetchingAzureBlobUrl
    ) {
      dispatch(fetchAzureBlobUrl());
    }
  }, [
    triedFetchingAzureBlobUrl,
    azureBlobUrl,
    dispatch,
    isFetchingAzureBlobUrl,
  ]);

  const fetchData = useCallback((skip) => {
    dispatch(fetchNews(tenantIdLoggedInUser, userTypeLoggedInUser, skip, 10));
  }, [userTypeLoggedInUser, dispatch, tenantIdLoggedInUser]);

  useEffect(() => {
    if (!isFetchingNews && !triedFetchingNews) {
      fetchData(skip);
    }
  }, [dispatch, isFetchingNews, triedFetchingNews, tenantIdLoggedInUser, userTypeLoggedInUser, fetchData, skip]);

  const loadMore = () => {
    fetchData(skip + 10);
    setSkip(prevSkip => prevSkip + 10);
  }

  return (
    <>
      {isFetchingNews || !triedFetchingNews ? (
        <Card
          title={`${nameToDisplay} News`}
          icon="pe-7s-news-paper"
          iconGradient="bg-green-blue-top"
          contentClass="no-current-card">
          <Loading message="Hang on, we're loading your news." />
        </Card>
      ) : news.length > 0 ? (
        <div className="news-items">
          <h3>The latest news for you to consume.</h3>
          {news.map(newsItem => <NewsItemCard newsItem={newsItem} azureBlobUrl={azureBlobUrl} key={newsItem.id} />)}
          {!moreToFetch ? <Button class="btn btn-sm btn-primary mb-3" clickHandler={loadMore} text="Load More"></Button> : <p>No more items</p>}
        </div>
      ) : (
        <Card
          title={`${nameToDisplay} News`}
          icon="pe-7s-news-paper"
          iconGradient="bg-green-blue-top"
          contentClass="no-current-card">
          <div>You have no news yet.</div>
        </Card>
      )}
    </>
  );
};

export default memo(NewsListCard);
