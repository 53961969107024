import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimesheetState from '../../enums/timesheetState';
import { showModal } from '../../redux/system/actions';
import { autocompleteTimesheet, downloadTimesheet, saveTimesheet, submitTimesheet, uploadTimesheet, approveTimesheet } from '../../redux/timesheets/actions';
import Button from '../common/Button';
import Modal from '../common/Modal';
import AutoCompleteModal from '../common/modals/AutoCompleteModal';
import UploadTimesheetModal from '../common/modals/UploadTimesheetModal';

const SAVE_MODAL_ID = 'saveTimesheet';
const UPLOAD_MODAL_ID = 'uploadTimesheet';
const AUTO_COMPLETE_MODAL_ID = 'autoCompleteModal';
const DOWNLOAD_MODAL_ID = 'downloadModal';
const APPROVE_MODAL_ID = 'approveModal';

function EditTimesheetActionsButton({ timesheet, contract, hasErrors }) {
    const dispatch = useDispatch();
    const { isSavingTimesheet, isDownloadingTimesheet, isUploadingTimesheet, isSubmittingTimesheet } = useSelector((state) => state.system);
    const pristineTimesheet = useSelector((state) => state.pristineTimesheet);

    const isBusy = useMemo(
        () => isSavingTimesheet || isDownloadingTimesheet || isUploadingTimesheet || isSubmittingTimesheet,
        [isDownloadingTimesheet, isSavingTimesheet, isSubmittingTimesheet, isUploadingTimesheet]
    );

    const handleDownloadTimesheet = useCallback(() => dispatch(downloadTimesheet(timesheet)), [dispatch, timesheet]);
    const handleSaveTimesheet = useCallback(() => dispatch(saveTimesheet(timesheet)), [dispatch, timesheet]);
    const handleSubmitTimesheet = useCallback(() => dispatch(submitTimesheet(timesheet)), [dispatch, timesheet]);
    const handleShowModal = useCallback((modalId) => dispatch(showModal(modalId)), [dispatch]);
    const handleApproveTimesheet = useCallback(() => dispatch(approveTimesheet(timesheet)), [dispatch, timesheet]);

    const handleShowDownload = useCallback(() => handleShowModal(DOWNLOAD_MODAL_ID), [handleShowModal]);
    const handleShowUpload = useCallback(() => handleShowModal(UPLOAD_MODAL_ID), [handleShowModal]);
    const handleShowAutoComplete = useCallback(() => handleShowModal(AUTO_COMPLETE_MODAL_ID), [handleShowModal]);
    const handleShowSaveTimesheet = useCallback(() => {
        if (timesheet.state === TimesheetState.PENDINGAPPROVAL) {
            return handleShowModal(SAVE_MODAL_ID);
        }
        return handleSaveTimesheet();
    }, [handleSaveTimesheet, handleShowModal, timesheet.state]);
    const handleShowApprove = useCallback(() => handleShowModal(APPROVE_MODAL_ID), [handleShowModal]);

    const availableActions = useMemo(
        () => ({
            autocompleteAction: {
                text: '',
                title: 'Autocomplete',
                action: handleShowAutoComplete,
                class: 'pe-7s-paint-bucket',
            },
            uploadAction: { text: '', title: 'Upload Your Timesheet', action: handleShowUpload, class: 'pe-7s-upload' },
            downloadAction: {
                text: '',
                title: 'Download Your Timesheet',
                action: handleShowDownload,
                class: 'pe-7s-download',
            },
            submitAction: {
                text: '',
                title: 'Submit Your Timesheet',
                action: handleSubmitTimesheet,
                class: 'pe-7s-paper-plane',
                disabled: hasErrors,
            },
            approveAction: {
                text: '',
                title: 'Approve Your Timesheet',
                action: handleShowApprove,
                class: 'pe-7s-check',
                disabled: hasErrors,
            },
            editAction: {
                text: 'Edit',
                title: 'Edit Your Timesheet',
                action: handleShowSaveTimesheet,
                class: 'btn-primary',
            },
            saveAction: {
                text: 'Save',
                title: 'Save Your Timesheet',
                action: handleShowSaveTimesheet,
                class: 'btn-primary',
                disabled: !pristineTimesheet || hasErrors,
            },
        }),
        [handleShowDownload, handleShowSaveTimesheet, pristineTimesheet, hasErrors, handleShowUpload, handleShowAutoComplete, handleSubmitTimesheet, handleShowApprove]
    );

    const actions = useMemo(() => {
        switch (timesheet.state) {
            case TimesheetState.DRAFT:
                return [
                    availableActions.autocompleteAction,
                    availableActions.uploadAction,
                    availableActions.downloadAction,
                    contract.timesheetAuthorisation === 1 ? availableActions.submitAction : availableActions.approveAction,
                    availableActions.saveAction,
                ];
            case TimesheetState.PENDINGAPPROVAL:
                return [availableActions.autocompleteAction, availableActions.uploadAction, availableActions.downloadAction, availableActions.editAction];
            case TimesheetState.APPROVED:
            case TimesheetState.EXPORTED:
                return [availableActions.downloadAction];
        }
    }, [
        availableActions.approveAction,
        availableActions.autocompleteAction,
        availableActions.downloadAction,
        availableActions.editAction,
        availableActions.saveAction,
        availableActions.submitAction,
        availableActions.uploadAction,
        contract.timesheetAuthorisation,
        timesheet.state,
    ]);

    const adminEmail = useMemo(() => (contract.company === 'Team4Talent' ? process.env.T4T_ADMIN_MAIL : process.env.TALENT_IT_ADMIN_MAIL), [contract.company]);

    const ActionButtons = useCallback(
        () =>
            actions.map((action, index) => {
                const shouldDisable =
                    timesheet.state === TimesheetState.PENDINGAPPROVAL &&
                    ![availableActions.downloadAction.title, availableActions.editAction.title].includes(action.title);

                return (
                    <Button
                        class={action.class}
                        key={`btn-${index}-${action.text}`}
                        text={action.text}
                        title={action.title}
                        clickHandler={() => action.action()}
                        disabled={isBusy || shouldDisable || action.disabled}
                    />
                );
            }),
        [actions, availableActions.downloadAction.title, availableActions.editAction.title, isBusy, timesheet.state]
    );

    return (
        <>
            <ActionButtons />
            <Modal id={SAVE_MODAL_ID} icon="pe-7s-help1" iconGradient="bg-green-blue-top" title="Are you sure?" confirmText="Edit" confirmHandler={handleSaveTimesheet}>
                <p>This timesheet is currently pending approval. If you apply changes, you will have to submit this timesheet again.</p>
            </Modal>
            <AutoCompleteModal
                id={AUTO_COMPLETE_MODAL_ID}
                rates={contract.rates}
                hours={contract.job.standardHoursPerDay}
                isFreeTextAllowed={contract.isFreeTextAllowedInEntries}
                confirmHandler={({ type, amount, remarks, isHomework }) =>
                    dispatch(
                        autocompleteTimesheet(timesheet, type, amount, remarks, contract.isFreeTextAllowedInEntries, contract.startDate, contract.endDate, isHomework)
                    )
                }
                defaultAmount={contract.job.standardHoursPerDay}
            />
            <UploadTimesheetModal id={UPLOAD_MODAL_ID} confirmHandler={(file) => dispatch(uploadTimesheet(file, timesheet))} />
            <Modal
                id={DOWNLOAD_MODAL_ID}
                icon="pe-7s-help1"
                iconGradient="bg-green-blue-top"
                title="Before you start downloading..."
                confirmText="Download"
                confirmHandler={handleDownloadTimesheet}>
                <p>You are now going to download the last submitted timesheet!</p>
            </Modal>
            <Modal
                id={APPROVE_MODAL_ID}
                icon="pe-7s-help1"
                iconGradient="bg-green-blue-top"
                title="Before you approve..."
                confirmText="Approve"
                confirmHandler={handleApproveTimesheet}>
                <p>
                    Please make sure your timesheet is signed by your client and sent to <a href={`mailto:${adminEmail}`}>administration.</a>
                </p>
            </Modal>
        </>
    );
}

EditTimesheetActionsButton.propTypes = {
    timesheet: PropTypes.object.isRequired,
    contract: PropTypes.object.isRequired,
    hasErrors: PropTypes.bool.isRequired,
};

export default memo(EditTimesheetActionsButton);
