import {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT_FAILED,
  DOWNLOAD_DOCUMENT_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
} from './actions';

export default function documentsReducer(state = { ...DOCUMENTS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documentsList: [...action.documents],
        skip: action.skip,
        take: action.take,
        totalCount: action.totalCount,
      };
    }

    case DOWNLOAD_DOCUMENT: {
      return {
        ...state,
        documentsList: state.documentsList.map((d) => {
          if (d.documentId === action.documentId) {
            return { ...d, isDownloading: true };
          }
          return d;
        }),
      };
    }

    case DOWNLOAD_DOCUMENT_SUCCESS:
    case DOWNLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        documentsList: state.documentsList.map((d) => {
          if (d.documentId === action.documentId) {
            return { ...d, isDownloading: false };
          }
          return d;
        }),
      };

    default:
      return state;
  }
}

export const DOCUMENTS_BASE_STATE = {
  documentsList: [],
  skip: null,
  take: null,
  totalCount: null,
};
