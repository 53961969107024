import { FETCH_ASSET_HISTORY_SUCCESS, SET_SELECTED_TAB, LOANED, RETURNED } from './actions';

export default function assetsReducer(state = { ASSETS_BASE_STATE }, action) {
  switch (action.type) {
    case FETCH_ASSET_HISTORY_SUCCESS: {
      switch (action.historyType) {
        case LOANED: {
          return {
            ...state,
            loanedAssetHistory: action.assetHistory,
            skipLoaned: action.skip,
            takeLoaned: action.take,
            totalCountLoaned: action.totalCount,
            loanedAssetHistoryLoaded: true,
          };
        }
        case RETURNED:
          return {
            ...state,
            returnedAssetHistory: action.assetHistory,
            skipReturned: action.skip,
            takeReturned: action.take,
            totalCountReturned: action.totalCount,
            returnedAssetHistoryLoaded: true,
          };
        default:
          return state;
      }
    }

    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    default:
      return state;
  }
}

export const ASSETS_BASE_STATE = {
  loanedAssetHistory: [],
  returnedAssetHistory: [],
  skipLoaned: null,
  takeLoaned: null,
  totalCountLoaned: null,
  loanedAssetHistoryLoaded: false,
  skipReturned: null,
  takeReturned: null,
  totalCountReturned: null,
  returnedAssetHistoryLoaded: false,
  selectedTab: 0,
};
