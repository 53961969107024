import { toast } from 'react-toastify';
import {
  approveTimesheet as approve,
  getPublicLink as fetchPublicLink,
  rejectTimesheet as reject,
} from '../../api/publicLinkApi';
import {
  APPROVE_TIMESHEET_TOAST_MESSAGES,
  REJECT_TIMESHEET_TOAST_MESSAGES,
  showRequestFailedToast,
} from '../../service/toastService';

export const APPROVE_TIMESHEET = 'APPROVE_TIMESHEET';
export const APPROVE_TIMESHEET_SUCCESS = 'APPROVE_TIMESHEET_SUCCESS';
export const APPROVE_TIMESHEET_FAILED = 'APPROVE_TIMESHEET_FAILED';
export const REJECT_TIMESHEET = 'REJECT_TIMESHEET';
export const REJECT_TIMESHEET_SUCCESS = 'REJECT_TIMESHEET_SUCCESS';
export const REJECT_TIMESHEET_FAILED = 'REJECT_TIMESHEET_FAILED';
export const GET_PUBLIC_LINK = 'GET_PUBLIC_LINK';
export const GET_PUBLIC_LINK_SUCCESS = 'GET_PUBLIC_LINK_SUCCESS';
export const GET_PUBLIC_LINK_FAILED = 'GET_PUBLIC_LINK_FAILED';

let currentToast = null;

export function approveTimesheet(timesheet, publicLink) {
  return (dispatch) => {
    dispatch({ type: APPROVE_TIMESHEET });
    currentToast = toast.loading(
      APPROVE_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      APPROVE_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );
    return approve(timesheet, publicLink)
      .then((response) => {
        toast.update(currentToast, APPROVE_TIMESHEET_TOAST_MESSAGES.success);
        return dispatch({ type: APPROVE_TIMESHEET_SUCCESS, response: { ...response, timesheet } });
      })
      .catch((err) => {
        toast.update(currentToast, APPROVE_TIMESHEET_TOAST_MESSAGES.error);
        return dispatch({ type: APPROVE_TIMESHEET_FAILED, error: { message: err, timesheet } });
      });
  };
}

export function rejectTimesheet(timesheet, publicLink, rejectionReason) {
  return (dispatch) => {
    dispatch({ type: REJECT_TIMESHEET });
    currentToast = toast.loading(
      REJECT_TIMESHEET_TOAST_MESSAGES.pending.toastBody,
      REJECT_TIMESHEET_TOAST_MESSAGES.pending.toastOptions
    );

    return reject(timesheet, publicLink, rejectionReason)
      .then((response) => {
        toast.update(currentToast, REJECT_TIMESHEET_TOAST_MESSAGES.success);
        return dispatch({ type: REJECT_TIMESHEET_SUCCESS, response: { ...response, timesheet } });
      })
      .catch((err) => {
        toast.update(currentToast, REJECT_TIMESHEET_TOAST_MESSAGES.error);
        return dispatch({ type: REJECT_TIMESHEET_FAILED, error: { message: err, timesheet } });
      });
  };
}

export function getPublicLink(publicLink) {
  return (dispatch) => {
    dispatch({ type: GET_PUBLIC_LINK });

    return fetchPublicLink(publicLink)
      .then((response) => dispatch({ type: GET_PUBLIC_LINK_SUCCESS, response: { ...response } }))
      .catch((err) => {
        showRequestFailedToast();
        return dispatch({ type: GET_PUBLIC_LINK_FAILED, error: { message: err } });
      });
  };
}
