import React from 'react';
import ContactInfoCard from '../components/contact/ContactInfoCard';
function ContactsPage(){

return(
    <div className="container-fluid">
        <div className="row row-dashboard">
            <div className="col-lg-12">
                <div className='row-fluid'>
                    <div className='col-lg-12'>
                    <ContactInfoCard />
                    </div>
            </div>
        </div>
    </div>
    </div>
)
}

export default ContactsPage;