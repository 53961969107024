import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../common/LoadingIcon';

function Loading({ message, className }) {
  return (
      <div className="row-fluid">
        <div className="col-lg-12">
          <div className={className}>
            <LoadingIcon message={message} />
          </div>
        </div>
      </div>
  );
}

Loading.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default memo(Loading);
