import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../Modal';
import { uploadDocument } from '../../../api/documentsApi';
import { updateCourseEnrollment } from '../../../api/coursesApi';
import { COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES, getErrorUpdateToastPropsBy, getSuccesToastPropsBy } from '../../../service/toastService';
import { toast } from 'react-toastify';

function ProofOfCompletionModal({ id, courseEnrollment, onSubmit }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileKey, setFileKey] = useState(0); // Add state for a unique key for the input element

  useEffect(() => {
    // When the selectedFile changes, update the key to allow selecting the same file again
    setFileKey((prevKey) => prevKey + 1);
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const allowedFormats = ['pdf', 'docx', 'doc', 'docm', 'xlsx'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension && allowedFormats.includes(fileExtension)) {
      setSelectedFile(event.target.files[0]);
    } else {
      toast.error(`Unsupported file format: ${file.name}`, getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const getContentType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return extension;
  };

  const handleUploadProofOfCompletition = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const result = await uploadDocument({
        name: selectedFile.name,
        contentType: getContentType(selectedFile.name),
        formData: formData,
      });

      const updatedEnrollment = { ...courseEnrollment, status: 'Pending', proofOfCompletition: result.data.id, forwardCertificate: false };
      await updateCourseEnrollment(updatedEnrollment);
      toast.success('You have successfully sent your proof of completion for this course', getSuccesToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));

      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong! Try again later', getErrorUpdateToastPropsBy(COMPLETE_COURSE_ENROLLMENT_TOAST_MESSAGES));
    }
  };

  const closeModal = () => {
    setSelectedFile(null);
  };

  const isConfirmButtonDisabled = () => {
    return !selectedFile;
  };

  return (
    <Modal
      isLarge={true}
      id={id}
      icon="pe-7s-file"
      iconGradient="bg-green-blue-top"
      title="Proof of completion"
      contentClass="modal-body-center-h"
      confirmText="SEND"
      closeHandler={() => closeModal()}
      confirmButtonDisabled={isConfirmButtonDisabled()}
      confirmHandler={handleUploadProofOfCompletition}>
      <div className="" style={{ width: '100%' }}>
        <label
          htmlFor="fileInput"
          className="form-control"
          style={{ height: '200px', width: '100%', position: 'relative', overflow: 'hidden', display: 'block', cursor: 'pointer' }}>
          <input
            key={fileKey} // Set key to allow selecting the same file again
            type="file"
            id="fileInput"
            className="form-control"
            onChange={handleFileChange}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer',
            }}
            accept=".pdf, .docx, .doc, .docm, .xlsx"
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <i className="fas fa-cloud-upload-alt" style={{ fontSize: '2rem' }}></i>
            <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Click here to upload a file</p>
            <p style={{ fontSize: '0.8rem', color: '#999' }}>Supported formats: PDF, DOCX, DOC, DOCM, XLSX</p>
          </div>
        </label>

        {selectedFile ? (
          <div
            style={{
              marginTop: '15px',
              marginBottom: '10px',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
            }}>
            <i className="fa fa-file" style={{ marginRight: '5px' }} />
            <p style={{ margin: 0 }}>{selectedFile.name}</p>
          </div>
        ) : (
          <div
            style={{
              marginTop: '15px',
              marginBottom: '10px',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
            }}>
            <i className="fa fa-file" style={{ marginRight: '5px' }} />
            <p style={{ margin: 0 }}>No file selected</p>
          </div>
        )}
      </div>
    </Modal>
  );
}

ProofOfCompletionModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(ProofOfCompletionModal);
