import { USERTRACKS_BASE_STATE } from './usertracks/reducers';
import { CONTRACTS_BASE_STATE } from './contracts/reducers';
import { DOCUMENTS_BASE_STATE } from './documents/reducers';
import { PRISTINETIMESHEET_BASE_STATE } from './pristineTimesheet/reducers';
import { PROFILE_BASE_STATE } from './profile/reducers';
import { SYSTEM_BASE_STATE } from './system/reducers';
import { TIMESHEETS_BASE_STATE } from './timesheets/reducers';
import { PROJECTS_BASE_STATE } from './projects/reducers';
import { USERS_BASE_STATE } from './users/reducers';
import { VALIDATE_TIMESHEETS_BASE_STATE } from './validateTimesheets/reducers';
import { TRANSACTIONS_BASE_STATE } from './transactions/reducers';
import { ASSETS_BASE_STATE } from './assets/reducers';
import { GEOCODE_BASE_STATE } from './geoCode/reducers';
import { NEWS_BASE_STATE } from './news/reducers';
import { TENANTS_BASE_STATE } from './tenants/reducers';
import { AUTH_BASE_STATE } from './auth/reducers';
import { COURSES_BASE_STATE } from './courses/reducers';

export default {
  usertracks: { ...USERTRACKS_BASE_STATE },
  contracts: { ...CONTRACTS_BASE_STATE },
  documents: { ...DOCUMENTS_BASE_STATE },
  news: { ...NEWS_BASE_STATE },
  pristineTimesheet: PRISTINETIMESHEET_BASE_STATE,
  profile: { ...PROFILE_BASE_STATE },
  system: { ...SYSTEM_BASE_STATE },
  timesheets: { ...TIMESHEETS_BASE_STATE },
  users: { ...USERS_BASE_STATE },
  validateTimesheets: { ...VALIDATE_TIMESHEETS_BASE_STATE },
  transactions: { ...TRANSACTIONS_BASE_STATE },
  assets: { ...ASSETS_BASE_STATE },
  geoCode: { ...GEOCODE_BASE_STATE },
  projects: { ...PROJECTS_BASE_STATE },
  tenants: { ...TENANTS_BASE_STATE },
  auth: { ...AUTH_BASE_STATE },
  courses: { ...COURSES_BASE_STATE },
};
